import React from "react";
// toast notifications
import Notifications from "notifications/component";
// uuid function
import { create_UUID } from "utils/uuid";

class NotificationsManager extends React.Component {
  constructor() {
    super();
    this.state = { notifications: [] };
    this.showNotification = this.showNotification.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
  }

  showNotification(notification) {
    notification.id = create_UUID();
    this.setState(
      (state) => {
        state.notifications = [...state.notifications, notification];
        return state;
      },
      () => {
        if (notification.autoclose) {
          setTimeout(() => {
            this.hideNotification(notification.id);
          }, 3000);
        }
      }
    );
  }

  hideNotification(notificationId) {
    this.setState({
      notifications: this.state.notifications.filter(
        (notif) => notif.id !== notificationId
      ),
    });
  }

  render() {
    return (
      <>
        <Notifications
          isGlobalContext={false}
          notifications={this.state.notifications}
          hideNotification={this.hideNotification}
        />
        {this.props.children}
      </>
    );
  }
}

export default NotificationsManager;
