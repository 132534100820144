import React, { useContext } from "react";
// create intervention context
import { CreateContext } from "interventions/createProvider";
// react-bootstrap components
import { Card, InputGroup, FormControl } from "react-bootstrap";

const PrixDeVente = ({ isEditable }) => {
  const { intervention, handleInputChange } = useContext(CreateContext);
  const { PrixDeVente } = intervention;
  return (
    <Card.Body>
      <InputGroup size="sm">
        <FormControl
          type="number"
          name="prixDeVente"
          placeholder="Prix de vente"
          step={1}
          min={0}
          value={PrixDeVente}
          onChange={handleInputChange}
          disabled={!isEditable}
        />
        <InputGroup.Append>
          <InputGroup.Text>€ / t</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Card.Body>
  );
};

export default PrixDeVente;
