import React from "react";
// router
import { withRouter } from "react-router-dom";
// global context (this.props.data)
import { WithContext } from "../store/DataProvider";
// contexte parcelles (this.props.ctx)
import { WithParcellaire } from "../parcellaire/provider";
// custom components
import Switcher from "switcher/component";
import Filters from "../filters/component2";
import Parcelle2 from "../parcellaire/parcelle2";
import ScrollButtons from "scroll/buttons";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  ListGroup,
  Modal,
} from "react-bootstrap";
// react-icons
import { MdModeEdit } from "react-icons/md";

class ListeParcelles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        show: false,
      },
    };
  }

  openAssolement() {
    this.props.history.push("/parametrable?requete=boutons&ecran=assolement");
  }

  openCreate() {
    this.props.history.push("/createParcelle", {});
  }

  openEdit(id) {
    this.props.history.push("/createParcelle", {
      id: id,
    });
    if (id < 0) {
      this.props.data.showNotification({
        type: "warning",
        title: `Cette parcelle n'est pas encore synchronisée.
          Vous pouvez la visualiser.
          Vous ne pouvez pas la modifier, ni la supprimer tant qu'elle n'est pas synchronisée.`,
      });
    }
  }

  async getParcellesN_1() {
    const isSuccess = await this.props.data.sendRequest("getParcellesN_1");
    if (isSuccess) {
      await this.props.data.sync(false);
      this.props.history.replace("/parcellaire");
    } else {
      this.props.data.showNotification({
        type: "danger",
        title: "Impossible de récupérer les parcelles N-1.",
      });
    }
    this.hideModal.bind(this);
  }

  openOutilsDessin() {
    this.props.history.push("/outilsDessin");
  }

  toggleParcellesExploitation(value) {
    this.props.data.toggleParcellesExploitation(value);
  }

  buttons = (
    <>
      <ButtonToolbar>
        {this.props.data.exploitations.length > 1 && (
          <ToggleButtonGroup
            type="radio"
            name="vue"
            defaultValue={this.props.data.parcellesMode}
            onChange={this.toggleParcellesExploitation.bind(this)}
          >
            <ToggleButton
              value={1}
              variant={
                this.props.data.parcellesMode === 1 ? "success" : "light"
              }
            >
              Toutes les parcelles
            </ToggleButton>
            <ToggleButton
              value={2}
              variant={
                this.props.data.parcellesMode === 2 ? "success" : "light"
              }
            >
              Mes parcelles
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </ButtonToolbar>
      {this.props.data.parametres.parcellaireModifiable && (
        <>
          <Button
            onClick={this.showModal.bind(this)}
            variant="warning"
            className="ml-3"
          >
            Récupérer N-1
          </Button>
          <Button
            onClick={this.openOutilsDessin.bind(this)}
            variant="warning"
            className="ml-3"
          >
            Fusion / Découpe
          </Button>
          <Button
            onClick={this.openAssolement.bind(this)}
            variant="warning"
            className="ml-3"
          >
            Assolement
          </Button>
          <Button
            onClick={this.openCreate.bind(this)}
            variant="warning"
            className="ml-3"
          >
            Nouvelle parcelle
            <MdModeEdit size={25} className="ml-2" />
          </Button>
        </>
      )}
    </>
  );

  showModal() {
    this.setState({ modal: { show: true } });
  }

  hideModal() {
    this.setState({ modal: { show: false } });
  }

  render() {
    const { parcelles, cultures, surfaces } = this.props.data;
    const surfacesTotales = [
      {
        label: "Total cultures principales",
        surfaceTotale: surfaces.culturesPrincipales,
      },
      {
        label: "Total cultures dérobées",
        surfaceTotale: surfaces.culturesDerobees,
      },
      {
        label: "Surface totale",
        surfaceTotale: surfaces.totale,
      },
    ];
    return (
      <Switcher
        config={[
          {
            nom: "parcelles",
            liste: parcelles,
            sizeColumnsToFit: true,
            // height: 500,
          },
          // { nom: "cultures", liste: cultures, height: 500 },
          // { nom: "surfacesTotales", liste: surfacesTotales, height: 500 },
          { nom: "cultures", liste: cultures },
          { nom: "surfacesTotales", liste: surfacesTotales },
        ]}
        rowClickCallback={(id) => this.openEdit(id)}
        buttons={this.buttons}
      >
        <Container fluid className="mt-3">
          <Row>
            <Col md={8} className="mb-3">
              <Filters
                pathName="parcelles"
                listName="parcelles"
                list={parcelles}
                deleteButton
                handleClick={(id) => this.openEdit(id)}
                saveFilters
              >
                <Parcelle2 isTabActive={this.props.isTabActive} />
              </Filters>
            </Col>
            <Col md={4} className="mb-3">
              <ListGroup.Item>
                <h4>Cultures ({cultures.length})</h4>
              </ListGroup.Item>
              <ListGroup>
                {cultures.map((culture) => (
                  <ListGroup.Item key={culture.id}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="mb-0">{culture.label.toLowerCase()}</h6>
                      <h6 className="mb-0">{culture.surfaceTotale}ha</h6>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <ListGroup.Item className="mt-3">
                <h4>Surfaces Totales</h4>
              </ListGroup.Item>
              <ListGroup>
                {surfacesTotales.map((surface, index) => (
                  <ListGroup.Item key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="mb-0">{surface.label.toLowerCase()}</h6>
                      <h6 className="mb-0">{surface.surfaceTotale}ha</h6>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
          <Modal
            centered
            show={this.state.modal.show}
            onHide={this.hideModal.bind(this)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir récupérer vos parcelles N-1 ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.hideModal.bind(this)}>
                Annuler
              </Button>
              <Button
                variant="success"
                onClick={this.getParcellesN_1.bind(this)}
              >
                Valider
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
        <ScrollButtons />
      </Switcher>
    );
  }
}

export default withRouter(WithContext(WithParcellaire(ListeParcelles)));
