import React from "react";
// react-bootstrap components
import Spinner from "react-bootstrap/Spinner";
// momentjs
import "moment/locale/fr";
import moment from "moment";
// react icons
import { MdCheckCircle, MdError } from "react-icons/md";

class Toolbar extends React.Component {
  // Helpers
  isSyncInProgress() {
    return this.props.syncStatus === "in progress";
  }
  isSyncCompleted() {
    return this.props.syncStatus === "completed";
  }
  isCacheResponse() {
    return moment(this.props.date).isBefore(moment().subtract(30, "seconds"));
  }
  isQueuedRequests() {
    return this.props.queuedRequests > 0;
  }
  isSyncSucceed() {
    return (
      this.props.syncStatus === "completed" &&
      !this.isCacheResponse() &&
      !this.isQueuedRequests()
    );
  }
  isSyncFailed() {
    return (
      this.props.syncStatus === "completed" &&
      (this.isCacheResponse() || this.isQueuedRequests())
    );
  }
  getVariant() {
    let className = "d-flex justify-content-between";
    if (this.isSyncInProgress()) {
      className += " bg-danger";
    } else if (this.isSyncSucceed()) {
      className += " bg-success";
    } else if (this.isSyncFailed()) {
      if (this.isQueuedRequests()) {
        className += " bg-danger";
      } else if (this.isCacheResponse()) {
        className += " bg-warning";
      }
    }
    return className;
  }
  getTextColor() {
    let className = "d-flex flex-column ";
    if (this.isSyncInProgress()) {
      className += " text-white";
    } else if (this.isSyncSucceed()) {
      className += " text-white";
    } else if (this.isSyncFailed()) {
      if (this.isQueuedRequests()) {
        className += " text-white";
      } else if (this.isCacheResponse()) {
        className += " text-body";
      }
    }
    return className;
  }
  getSyncFailedClassName() {
    let className = "rounded-circle";
    if (this.isQueuedRequests()) {
      className += " text-light bg-danger";
    } else if (this.isCacheResponse()) {
      className += " text-body bg-warning";
    }
    return className;
  }
  getSyncStatus() {
    if (this.isSyncInProgress()) {
      return "Synchronisation en cours";
    } else {
      return "Synchronisation terminée";
    }
  }
  getSyncSuceedMessage() {
    return `Vos données sont synchronisées (${moment(this.props.date)
      .calendar()
      .toLocaleLowerCase()})`;
  }
  getQueuedRequestsMessage() {
    if (this.props.queuedRequests > 1) {
      return `${this.props.queuedRequests} actions en attente de synchro`;
    } else {
      return `${this.props.queuedRequests} action en attente de synchro`;
    }
  }
  getCacheResponseMessage() {
    return `
    Impossible d'actualiser vos données pour le moment.
    Dernière synchro : ${moment(this.props.date).calendar().toLocaleLowerCase()}
    `;
  }

  render() {
    return this.props.showToolBar ? (
      <div
        style={{
          position: "fixed",
          width: "100%",
          padding: 15,
          bottom: 0,
          zIndex: 1000,
        }}
        className={this.getVariant()}
      >
        <div className={this.getTextColor()}>
          <span className="small">{this.getSyncStatus()}</span>
          {this.isSyncSucceed() && (
            <span className="small">{this.getSyncSuceedMessage()}</span>
          )}
          {this.isSyncFailed() && (
            <span className="small">
              {this.isQueuedRequests()
                ? this.getQueuedRequestsMessage()
                : this.getCacheResponseMessage()}
            </span>
          )}
        </div>
        <div className="d-flex align-items-center">
          {this.isSyncInProgress() && (
            <Spinner animation="border" variant="light" />
          )}
          {this.isSyncSucceed() && (
            <div className="text-light bg-success rounded-circle">
              <MdCheckCircle size={38} />
            </div>
          )}
          {this.isSyncFailed() && (
            <div className={this.getSyncFailedClassName()}>
              <MdError size={38} />
            </div>
          )}
        </div>
      </div>
    ) : null;
  }
}

export default Toolbar;
