import React from "react";
import chroma from "chroma-js";

export const selectGreenStyle = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#28a745" : "#28a745",
    "&:hover": {
      borderColor: state.isFocused ? "#28a745" : "#28a745",
    },
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "#28a745",
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: "#28a745",
    // backgroundColor: "#28a745"
  }),
  menu: (base) => ({ ...base, zIndex: 9999 }),
};

export const selectGreenBordersStyle = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#28a745" : "#28a745",
    "&:hover": {
      borderColor: state.isFocused ? "#28a745" : "#28a745",
    },
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "#28a745",
  }),
  menu: (base) => ({ ...base, zIndex: 9999 }),
};

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color ? chroma(data.color) : null;
    return {
      ...styles,
      backgroundColor: isSelected
        ? data.color
          ? data.color
          : "#2196F3"
        : isFocused
        ? data.color
          ? color.alpha(0.1).css()
          : "#BBDEFB"
        : null,
      color: isSelected ? "white" : data.color ? data.color : "black",
    };
  },
  multiValue: (styles, { data }) => {
    const color = data.color ? chroma(data.color) : null;
    return {
      ...styles,
      backgroundColor: data.color
        ? color.alpha(0.1).css()
        : "rgb(230, 230, 230)",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color ? data.color : "black",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color ? data.color : "black",
  }),
};

export const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
