// momentjs
import moment from "moment";

export const getDurationInMinutes = timeString => {
  const hours = moment.duration(timeString).hours();
  const minutes = moment.duration(timeString).minutes();
  const durationInMinutes = hours * 60 + minutes;
  return durationInMinutes;
};

export const getTimeString = minutes => {
  const duration = moment.duration(minutes, "minutes");
  const durationString = moment
    .utc(duration.as("milliseconds"))
    .format("HH:mm");
  return durationString;
};
