// Fonction qui filtre une liste en fonction de ces filtres
export const filter = (liste, filters) => {
  const listeFiltree = liste.filter(function(item) {
    // Pour chaque filtre
    return filters.every(filter => {
      // Si filtre sur la date
      if (filter.propertyName === "date") {
        const startDate = filter.data[0].start;
        const endDate = filter.data[1].end;
        // Gérer les différents cas possibles
        if (!startDate && !endDate) {
          return true;
        } else {
          // ne prendre en compte que la date (pas le temps)
          const itemDate = item[filter.propertyName].substring(0, 10);
          if (startDate && !endDate) {
            return itemDate === startDate;
          } else if (!startDate && endDate) {
            return itemDate <= endDate;
          } else if (startDate && endDate) {
            return itemDate >= startDate && itemDate <= endDate;
          }
        }
        // Si autre filtre
      } else {
        // Doit retourner au moins un élément du filtre (some = OU)
        return filter.data.some(function(filterData) {
          let property;
          // seulement pour les parcelles
          if (item.properties) {
            property = item.properties[filter.propertyName];
            // pour toutes les autres listes
          } else {
            property = item[filter.propertyName];
          }
          // Si plusieurs filtres sur la même propriété
          if (Array.isArray(property)) {
            return property.some(function(item) {
              if (item.id) {
                return item.id === (filterData.id || filterData.value);
              } else if (item.value) {
                return item.value === (filterData.id || filterData.value);
              }
              return false;
            });
            // Si un seul filtre sur la propriété
          } else {
            return property === (filterData.id || filterData.value);
          }
        });
      }
      return false;
    });
  });
  // console.log("listeFiltree : ");
  // console.log(listeFiltree);
  return listeFiltree;
};

// Fonction qui trie une liste en fonction d'une propriété et d'une valeur (asc / desc)
export const sort = (liste, sortProperty, sortValue) => {
  if (sortProperty && sortValue) {
    // Trier la liste
    return liste.sort(function(a, b) {
      let expression1 = a[sortProperty];
      let expression2 = b[sortProperty];
      // si propriété imbriqué
      if (a.properties && b.properties) {
        expression1 = a["properties"][sortProperty];
        expression2 = b["properties"][sortProperty];
      }
      // Desc
      if (sortValue === 1) {
        if (expression1 > expression2) return 1;
        if (expression2 > expression1) return -1;
        return 0;
        // Asc
      } else if (sortValue === 2) {
        if (expression1 > expression2) return -1;
        if (expression2 > expression1) return 1;
        return 0;
      }
      return 0;
    });
  } else {
    // console.log("listeTriee : ");
    // console.log(liste);
    return liste;
  }
};

// Fonction qui génére des filtres en fonction des propriété à filtrer
export const getFilters = (properties, state) => {
  // Réinitialiser les filtres
  const filters = [];
  // Générer les filtres
  properties.forEach(propertyName => {
    const filterData = state[propertyName];
    // Si le filtre contient des données
    if (
      filterData &&
      filterData.length &&
      propertyName !== "groupesParcelles2"
    ) {
      // Ajouter le filtre
      filters.push({
        propertyName: propertyName,
        data: filterData
      });
    }
  });
  // console.log("getFilters : ");
  // console.log(JSON.stringify(filters, 0, 2));
  return filters;
};
