// API HTTP Client
import { API } from "api/api";
// intervention datas
import { getCategorie, getCategories } from "interventions/datas.js";

export const getCulturesParcelles = (listes, property) => {
  const { produits, culturepac } = listes;
  // produits de semence (id 76 = semis)
  let culturesParcelles = getCategories(produits, 76);
  // retirer le 1er element du tableau (toutes les catégories)
  // retirer les 6 premiers elements du tableau
  // ce sont des produits intervention semis, pas des cultures
  culturesParcelles = culturesParcelles.slice(
    property === "cultures" ? 7 : 0,
    culturesParcelles.length
  );
  // rajouter les cultures spécifiques (telepac)
  culturesParcelles = culturesParcelles.concat(culturepac);
  return culturesParcelles;
};

export const getVarietesParcelles = (produits, cultureId) => {
  let varietesParcelles;
  if (cultureId === -1) {
    varietesParcelles = [];
  } else {
    // valeurs = variétés des produits de semence (id 76 = semis, cultureId sélectionné)
    varietesParcelles = getCategorie(produits, 76, cultureId);
  }
  return varietesParcelles;
};

export const getAutocompleteOptions = async (inputName, inputValue) => {
  if (inputValue.length >= 3) {
    try {
      if (inputName === "culture" || inputName === "cultures") {
        return await API("autocomplete", {
          listName: "produits",
          typeId: 76,
          inputValue,
        });
      } else if (inputName === "varietes" || inputName === "variétés") {
        return await API("autocomplete", {
          listName: "produits",
          typeId: 76,
          inputValue,
        });
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  } else {
    return [];
  }
};

export const getGroupes = ({ parcelles, cultures, exploitations }) => {
  let groupes = [];
  let groupeTous = {
    id: 0,
    label: "Toutes",
    options: [],
    surfaceTotale: 0,
    checked: false,
  };

  // pour chaque parcelle
  parcelles.forEach((parcelle) => {
    groupeTous.options.push(parcelle);
    // pour chaque groupe auquel appartient la parcelle
    parcelle.properties.groupes.forEach((groupeParcelle) => {
      const index = groupes.findIndex(
        (groupe) => groupe.id === groupeParcelle.id
      );
      // le groupe existe déjà
      if (index !== -1) {
        // rajouter la parcelle dans le groupe
        groupes[index].options.push(parcelle);
        // le groupe n'existe pas
      } else {
        // rajouter le groupe entier, avec la parcelle
        groupes.push({
          id: groupeParcelle.id,
          label: groupeParcelle.label,
          options: [parcelle],
          surfaceTotale: 0,
          checked: false,
          color: "#ffeeba",
        });
      }
    });
  });

  if (groupes.length > 0) {
    groupes.unshift(groupeTous);
  }

  // les id des groupes générés automatiquement ont un préfixe "auto" suivi de l'index
  let groupeId = "auto";
  // pour chaque culture
  (cultures || []).forEach((culture, i) => {
    // rajouter le groupe correspondant à cette culture
    groupes.push({
      id: `${groupeId}-culture-${i}`,
      label: `Auto - ${culture.label}`,
      options: parcelles.filter(
        (parcelle) => parcelle.properties.culture[0].id === culture.id
      ),
      surfaceTotale: 0,
      checked: false,
      color: "#c3e6cb",
    });
  });
  // pour chaque exploitation
  if (exploitations.length > 1) {
    (exploitations || []).forEach((exploitation, i) => {
      const options = parcelles.filter(
        (parcelle) => parcelle.properties.exploitation[0].id === exploitation.id
      );
      // rajouter le groupe correspondant à cette exploitation
      if (options.length > 0) {
        groupes.push({
          id: `${groupeId}-exploitation-${i}`,
          label: `Auto - Parcelles ${exploitation.label}`,
          options: options,
          surfaceTotale: 0,
          checked: false,
          color: "#c3e6cb",
        });
      }
    });
  }

  // pour chaque groupe, ajouter la surface totale
  groupes.forEach((groupe) => {
    groupe.options.forEach((parcelle) => {
      groupe.surfaceTotale =
        Math.round((groupe.surfaceTotale + parcelle.properties.surface) * 100) /
        100;
    });
  });

  // console.log("groupes : ");
  // console.log(groupes);
  return groupes;
};
