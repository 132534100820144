import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// other generic components
import Generique from "generique/component";

class Parametrable extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
      isDataLoaded: false,
    };
  }

  async componentDidMount() {
    // récupérer le nom de la requête dans les params d'url
    const requete = new URLSearchParams(this.props.location.search).get(
      "requete"
    );
    // récupérer la propriété écran dans les params d'url
    const ecran = new URLSearchParams(this.props.location.search).get("ecran");
    // récupérer l'objet json contenant les données à afficher
    const data = await this.props.data.sendRequest(requete, { ecran });
    // console.log("data");
    // console.log(JSON.stringify(data, 0, 2));

    this.setState({ data: data, isDataLoaded: true });
  }

  render() {
    return this.state.isDataLoaded ? (
      <Generique data={this.state.data} />
    ) : null;
  }
}

export default WithContext(Parametrable);
