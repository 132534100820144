import React, { useState, useContext } from "react";
// global context
import { DataContext } from "store/DataProvider";
// router
import { withRouter } from "react-router-dom";
// bootstrap components
import { Container, Row, Col, Button, Form } from "react-bootstrap";
// react-select component
import Select from "react-select";
import {
  selectGreenStyle,
  selectGreenBordersStyle,
  colourStyles,
} from "styles/react-select";

const Filtres = ({ data, history }) => {
  const dataContext = useContext(DataContext);
  const { titre, sousTitre, enfants, boutons } = data;
  let options = {};
  let initialValues = {};
  enfants.forEach((enfant) => {
    if (enfant.type === "react-select") {
      // 1 - définir et modifier les options des listes déroulantes
      // faire le lien entre le nom de la propriété et la liste correspondante (ex: "parcelles" => data.parcelles)
      options[enfant.name] = JSON.parse(
        JSON.stringify(dataContext[enfant.name] || [])
      );
      // pour les parcelles, récupérer properties, sinon objet tel quel
      options[enfant.name] = options[enfant.name].map((item) =>
        item.properties ? item.properties : item
      );
      // pour toutes les listes autres que groupes de parcelles
      if (!options.groupesParcelles) {
        // ajouter un item "Tous"
        options[enfant.name].unshift({
          id: -1, // id: 0 déjà utilisé
          label: "Tous",
        });
      }
      // 2 - définir et modifier les options séléctionnées par défaut des listes déroulantes
      initialValues[enfant.name] = enfant.selected;
    }
  });
  // pour la liste de parcelles
  if (options.parcelles) {
    options.parcelles.forEach((parcelle) => {
      if (Array.isArray(parcelle.culture) && parcelle.culture[0]) {
        // concaténer le nom de la parcelle avec la culture
        parcelle.label = `${parcelle.label} - ${parcelle.culture[0].label}`;
      }
    });
  }
  // si groupes de parcelles
  if (options.groupesParcelles) {
    // pour chaque groupe de parcelle
    options.groupesParcelles.forEach((groupeParcelle) => {
      // ajouter les props des parcelles de ce groupe
      groupeParcelle.parcelles = groupeParcelle.options.map((parcelle) => {
        if (
          Array.isArray(parcelle.properties.culture) &&
          parcelle.properties.culture[0]
        ) {
          // concaténer le nom de la parcelle avec la culture
          parcelle.properties.label = `${parcelle.properties.label} - ${parcelle.properties.culture[0].label}`;
        }
        return parcelle.properties;
      });
      // TODO : le laisser pour gérer les couleurs ?
      // supprimer les parcelles imbriquées (sinon react-select créé un composant imbriqué)
      delete groupeParcelle.options;
    });
  }
  // getter et setter des options des listes déroulantes
  const [values, setValues] = useState(initialValues);
  // getter et setter du bouton radio "cout"
  const [cout, setCout] = useState(1);

  const handleChange = (name, selectedOptions) => {
    const isAll = selectedOptions.find((item) => item.id === -1);
    // si option "tous" séléctionné
    if (isAll) {
      const allList = options[name];
      let newValue = {};
      newValue[name] = allList;
      // retirer "tous" de la liste (sinon on ne peut plus supprimer les items 1 par 1)
      allList.shift();
      // ajouter toute la liste d'option
      setValues({ ...values, ...newValue });
    } else {
      let newValue = {};
      newValue[name] = selectedOptions;
      if (name === "parcelles") {
        // reset les groupes de parcelles
        newValue["groupesParcelles"] = [];
      }
      if (name === "groupesParcelles") {
        let parcelles = [];
        selectedOptions.forEach((groupe) => {
          parcelles = [...parcelles, ...groupe.parcelles];
        });
        // rajouter les parcelles correspondantes à ces groupes
        newValue["parcelles"] = parcelles;
      }
      // sinon ajouter les options séléctionnées
      setValues({ ...values, ...newValue });
    }
  };

  const handleRadioChange = (e) => {
    setCout(Number(e.target.value));
  };

  const handleDateChange = (e, name) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const buttonClick = async (bouton) => {
    if (isValid()) {
      // bug iOS : ouvrir la window d'abord
      let newWindow = window.open();
      const response = await dataContext.sendRequest(bouton.url, {
        data: { ...values, cout: cout },
      });
      if (response.success) {
        // puis modifier l'url de la window ensuite
        newWindow.location = response.url;
      }
    }
  };

  const isValid = () => {
    const requete = new URLSearchParams(history.location.search).get("requete");
    const ecran = new URLSearchParams(history.location.search).get("ecran");
    const isFichesParcellaire =
      requete === "synthesesParametrables" && ecran === "fichesParcellaire";
    if (isFichesParcellaire) {
      if (values.parcelles.length) {
        return true;
      } else {
        dataContext.showNotification({
          type: "warning",
          title:
            "Vous devez sélectionner au moins une parcelle pour générer une fiche parcellaire",
        });
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <h4>{titre}</h4>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h6>{sousTitre}</h6>
          {enfants.map((enfant, index) => {
            if (enfant.type === "react-select") {
              return (
                <Select
                  key={index}
                  isMulti
                  placeholder={enfant.placeholder}
                  getOptionValue={(opt) => (opt.id ? opt.id : opt.value)}
                  options={options[enfant.name]}
                  value={values[enfant.name]}
                  onChange={(selectedOptions) =>
                    handleChange(enfant.name, selectedOptions)
                  }
                  className="mb-2"
                  styles={
                    enfant.colors
                      ? { ...colourStyles, ...selectGreenBordersStyle }
                      : selectGreenStyle
                  }
                />
              );
            } else if (enfant.type === "radio") {
              return enfant.options.map((option) => (
                <Form.Check
                  inline
                  key={option.id}
                  type="radio"
                  name={enfant.name}
                  label={option.label}
                  value={option.id}
                  checked={cout === option.id}
                  onChange={handleRadioChange}
                />
              ));
            } else if (enfant.type === "date") {
              return (
                <Form.Control
                  key={index}
                  name={enfant.name}
                  type="date"
                  placeholder="jj/mm/aaaa"
                  onChange={(date) => handleDateChange(date, enfant.name)}
                  style={{ color: "#28a745" }}
                  className="border-success mb-2"
                />
              );
            }
            return null;
          })}
        </Col>
      </Row>
      <Row>
        <Col className="mt-3 d-flex justify-content-end">
          {boutons.map((bouton, index) => (
            <Button
              key={index}
              variant="warning"
              onClick={() => buttonClick(bouton)}
            >
              {bouton.titre}
            </Button>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Filtres);
