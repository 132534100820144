import React from "react";
// react-bootstrap components
import { Container, Row, Col, Alert, Button } from "react-bootstrap";

const ErrorScreen = props => {
  const backToHome = () => {
    window.location.replace(window.location.origin);
  };

  return (
    <Container fluid style={{ marginTop: "-55px" }}>
      <Row>
        <Col>
          <Alert show variant="info">
            <Alert.Heading>Oups ... une erreur s'est produite !</Alert.Heading>
            <div>Aucune action de votre part n'est requise.</div>
            <div>
              Votre rapport d'erreur nous est transmis de manière automatique.
            </div>
            <div>
              Nos équipes se chargent de résoudre le problème dans les plus
              brefs délais.
            </div>
            <div>
              Merci de bien vouloir nous excuser pour la gêne occasionnée.
            </div>
            <hr />
            <div className="d-flex justify-content-start">
              <Button onClick={() => backToHome()} variant="success" size="lg">
                Retour à l'accueil
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert show variant="danger">
            <Alert.Heading>Informations concernant l'erreur :</Alert.Heading>
            <div> url : {props.errorData.url} </div>
            <div>
              utilisateur : {JSON.stringify(props.errorData.utilisateur)}
            </div>
            <div> campagne : {JSON.stringify(props.errorData.campagne)} </div>
            <div> erreur : {props.errorData.error.toString()} </div>
            stacktrace :
            {(props.errorData.stacktrace || []).map((error, index) => (
              <div key={index}> {error} </div>
            ))}
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorScreen;
