import React from "react";
// custom components
import MiniMap from "../map/miniMap.js";
// react-bootstrap components
import { ListGroup, Badge } from "react-bootstrap";

const ilot = ({ list, isTabActive, handleClick }) => {
  return list.map((ilot, index) => {
    // TODO : récupérer telepac par origine ?
    const telepac = ilot.properties.telepac[0];
    return (
      <ListGroup.Item
        data-id={ilot.properties.id}
        key={ilot.properties.id}
        as="li"
        action
        onClick={handleClick}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row flex-wrap">
              <h6> {ilot.properties.label} </h6>
              <h6 className="mr-1">
                <Badge variant="secondary" className="ml-1">
                  {ilot.properties.surface}ha
                </Badge>
              </h6>
              {telepac && (
                <h6>
                  <Badge variant="secondary">{telepac.ilot}</Badge>
                </h6>
              )}
            </div>
          </div>
          <div className="d-flex flex-nowrap">
            <MiniMap isTabActive={isTabActive} parcelles={[ilot]} />
          </div>
        </div>
      </ListGroup.Item>
    );
  });
};

export default ilot;
