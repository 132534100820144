import React from "react";
// react-bootstrap components
import { Container, Alert, Button } from "react-bootstrap";

const Assistance = () => {
  return (
    <Container className="mt-3">
      <Alert show variant="danger">
        <Alert.Heading>Assistance</Alert.Heading>
        <p>
          Pour toute question sur votre compte ou pour vous aider dans
          l’utilisation de Céré d’Oc, vous pouvez nous contacter :
          <br />
          Par téléphone : 05 62 07 33 94
          <br />
          Par mail : ceredoc@agrodoc.fr
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button variant="danger" href="tel:+33562073394">
            Téléphoner
          </Button>
          <Button
            variant="danger"
            href="mailto:ceredoc@agrodoc.fr"
            className="ml-3"
          >
            Écrire un mail
          </Button>
        </div>
      </Alert>
      <Alert show variant="success">
        <Alert.Heading>Résolution de problèmes web & mobile</Alert.Heading>
        <p>
          À effectuer dans l'ordre. <br />
          Si l'une des solutions fonctionne, s'arrêter à cette solution (pas
          besoin de tester la solution suivante).
        </p>
        <p>
          Solutions applicables sur toutes les plateformes (ordinateur, pc
          portable, mobile, tablette)
        </p>
        <p>
          solution 1 - recharger les données :
          <br />
          - cliquez sur le logo Céré d’Oc pour revenir à l'accueil
          <br />
          - sur mobile / tablette : appuyez et tirez vers le bas pour recharger
          les données (pull-to-refresh)
          <br />
          - sur ordinateur / pc portable : actualisez la page web via le bouton
          du navigateur (flèche ronde) pour recharger les données
          <br />- vérifiez si le problème est résolu
        </p>
        <p>
          solution 2 - déconnexion / reconnexion :<br />
          - déconnectez vous (dernière icône "on / off" dans le menu Céré d'Oc)
          <br />
          - reconnectez vous à Céré d'Oc : depuis votre espace www.agrodoc.fr ou
          bien depuis www.smartceredoc.fr pour ceux qui disposent d’un accès
          direct via identifiant & mot de passe
          <br />- vérifiez si le problème est résolu
        </p>
      </Alert>
      <Alert show variant="success">
        <Alert.Heading>
          Résolution de problèmes mobile - application installée
        </Alert.Heading>
        <p>
          À effectuer dans l'ordre. <br />
          Si l'une des solutions fonctionne, s'arrêter à cette solution (pas
          besoin de tester la solution suivante).
        </p>
        <p>
          Solutions applicables sur mobile et tablette uniquement (application
          installée)
        </p>
        <p>
          solution 3 - fermer / rouvrir l'application :
          <br />
          - fermez l'application Céré d'Oc
          <br />
          - ouvrez l'application Céré d’Oc
          <br />- vérifiez si le problème est résolu
        </p>
        <p>
          solution 4 - désinstallation / réinstallation de l'application :<br />
          - désinstallez l'application Céré d'Oc : appuyez longuement sur
          l'icône de l'application, puis choisissez désinstaller ou supprimer de
          l'écran d'accueil
          <br />
          - accédez à Céré d'Oc depuis votre navigateur
          <br />
          - réinstallez l'application Céré d'Oc : depuis votre navigateur, sur
          Céré d'Oc, cliquez sur le bouton options du navigateur (icône trois
          petits points horizontaux) puis choisissez installer ou ajouter sur
          l'écran d'accueil
          <br />- vérifiez si le problème est résolu
        </p>
      </Alert>
    </Container>
  );
};

export default Assistance;
