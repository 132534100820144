import React from "react";
// react context api HOC component (propagate datas to components using context)
import { WithContext } from "../store/DataProvider";
// pull-to-refresh
import Pullable from "../pull-to-refresh/component";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
// react-bootstrap custom components
// import AlertBig from "../alerts/AlertBig.js";
import AlertSmall from "../alerts/AlertSmall.js";
// leaflet Map
import MapDraw from "../map/mapDraw.js";

class Home extends React.Component {
  toggleParcellesExploitation(value) {
    this.props.data.toggleParcellesExploitation(value);
  }
  render() {
    const isActualites =
      this.props.data.actualites && this.props.data.actualites.length;
    const campagneLabel = this.props.data.campagneActive
      ? this.props.data.campagneActive.label
      : "";
    const isParcellaire = this.props.data.menu.left.find(
      (menu) => menu.id === 100000000
    );
    return (
      <Pullable onRefresh={() => this.props.data.sync(true)}>
        <Container fluid>
          <Row>
            <Col lg={isActualites ? 9 : 12}>
              <div className="d-flex justify-content-between mt-3 mb-3">
                <h4>Assolement {campagneLabel} </h4>
                {this.props.data.exploitations.length > 1 && (
                  <ButtonToolbar>
                    <ToggleButtonGroup
                      type="radio"
                      name="vue"
                      defaultValue={this.props.data.parcellesMode}
                      onChange={this.toggleParcellesExploitation.bind(this)}
                    >
                      <ToggleButton
                        value={1}
                        variant={
                          this.props.data.parcellesMode === 1
                            ? "success"
                            : "light"
                        }
                      >
                        Toutes les parcelles
                      </ToggleButton>
                      <ToggleButton
                        value={2}
                        variant={
                          this.props.data.parcellesMode === 2
                            ? "success"
                            : "light"
                        }
                      >
                        Mes parcelles
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                )}
              </div>
              <MapDraw
                history={this.props.history}
                isLegende
                isGeolocation
                isModal
                dragging={true}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                isParcellaire={isParcellaire}
              />
            </Col>
            {isActualites && (
              <Col lg={3} className="mt-3">
                <h4>
                  Actualités du logiciel V{`${process.env.REACT_APP_VERSION}`}
                </h4>
                {this.props.data.actualites.map((actualite, index) => (
                  <AlertSmall
                    key={index}
                    dismissible={false}
                    title={actualite.titre}
                    message={actualite.message}
                    variant={actualite.couleur || "info"}
                  />
                ))}
              </Col>
            )}
          </Row>
        </Container>
      </Pullable>
    );
  }
}

export default WithContext(Home);
