import React from "react";
// react-bootstrap components
import { Card, Form } from "react-bootstrap";

const Details = ({ mouvement, handleChange }) => {
  return (
    <Card.Body>
      <Form>
        <Form.Group>
          <Form.Label>Fournisseur (confidentiel)</Form.Label>
          <Form.Control
            name="fournisseur"
            value={mouvement.fournisseur}
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Numéro de facture</Form.Label>
          <Form.Control
            name="numeroFacture"
            value={mouvement.numeroFacture}
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
      </Form>
    </Card.Body>
  );
};

export default Details;
