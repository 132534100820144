import React, { useContext, useState, useEffect, useCallback } from "react";
// create intervention context
import { CreateContext } from "interventions/createProvider";
import { ParcellesContext } from "parcelles/provider";
// global context
import { DataContext } from "store/DataProvider";
// router
import { withRouter } from "react-router-dom";
// API HTTP Client
import { API } from "api/api";
// custom components
import { FormDoubleInputs, FormSemis } from "interventions/forms.js";
// react-bootstrap components
import {
  Card,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Form,
} from "react-bootstrap";
// react-select component
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { formatGroupLabel, colourStyles } from "styles/react-select";
// intervention datas
import { getCategories, getCategorie } from "interventions/datas.js";

const Produits = ({ history, isEditable }) => {
  const {
    listeProduits,
    categorieProduitsId,
    categorieProduits,
    intervention,
    typeId,
    updateCategory,
    filtrerProduits,
    updateList,
    updateItem,
    getQuantityPerSurface,
    getQuantityGrainesPerSurface,
  } = useContext(CreateContext);
  const { parcellesActives, getSurfaceTotale } = useContext(ParcellesContext);
  const { sendRequest, exploitations, parametres } = useContext(DataContext);
  const isMultiExploitations = exploitations.length > 1;
  const categories = getCategories(listeProduits, typeId);
  const categorie = getCategorie(
    listeProduits,
    typeId,
    categorieProduitsId,
    isMultiExploitations,
    parametres.stockPrestataire
  );
  const { produits } = intervention;
  const [vueProduits, setVueProduits] = useState(1);

  const testConformitePhyto = useCallback(async () => {
    // uniquement pour les interventions de type traitement phyto
    if (typeId === 60) {
      const produitsConformitePhyto = await sendRequest("conformitePhyto", {
        data: { ...intervention, parcelles: parcellesActives },
      });
      // deep copy des produits
      let produitsIntervention = JSON.parse(
        JSON.stringify(intervention.produits)
      );
      // le serveur renvoi souvent un message d'erreur php ...
      if (Array.isArray(produitsConformitePhyto)) {
        // pour chaque produit de l'intervention
        produitsIntervention.forEach((produit) => {
          // trouver le produit renvoyé par le serveur
          let produitConformitePhyto = produitsConformitePhyto.find(
            (p) => p.id === produit.id
          );
          // si trouvé ...
          if (produitConformitePhyto) {
            // ajouter les props de conformité (dosage / mélange)
            produit.statut = produitConformitePhyto.statut;
            produit.messages = produitConformitePhyto.messages;
          }
        });
        const produitsListe = produitsIntervention.filter((p) => !p.catalogue);
        const produitsCatalogue = produitsIntervention.filter(
          (p) => p.catalogue
        );
        // mettre à jour les produits de l'intervention avec ces nouvelles props
        updateList("produits", produitsListe);
        updateList("produitsManquants", produitsCatalogue);
      }
    }
  }, [intervention, parcellesActives, sendRequest, typeId, updateList]);

  // au montage du composant
  useEffect(() => {
    if (produits.length > 0) {
      testConformitePhyto();
    }
  }, [intervention, produits.length, testConformitePhyto]);
  const handleRadioChange = (value) => {
    if (value !== 3) {
      setVueProduits(value);
    } else {
      history.push("/parametres", { tab: "produit" });
    }
  };
  const loadOptions = async (inputValue) => {
    if (inputValue.length >= 3) {
      try {
        let data = await API("autocomplete", {
          listName: "produits",
          typeId,
          inputValue,
        });
        // rajouter les propriétés
        data.forEach((option) => {
          // tous les produits ont un champ quantité
          option.quantite = "";
          // tous les produits qui n'appartiennent pas à la catégorie "traitement de semence" ont un champ quantité surfacique
          if (option.idprg !== 6) {
            option.quantitePerSurface = "";
          }
          // les produits de type variété ont un champ "type de semence" et "pmg"
          if (option.variete) {
            option.typeSemence = 1;
            option.pmg = "";
          }
          if (isMultiExploitations) {
            option.origineStock = parametres.stockPrestataire ? 1 : 2;
          } else {
            option.origineStock = 2;
          }
        });
        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <Card.Body>
      <ButtonToolbar className="mb-2">
        <ToggleButtonGroup
          type="radio"
          name="vueProduits"
          defaultValue={vueProduits}
          onChange={handleRadioChange}
        >
          <ToggleButton value={1} variant="light">
            Ma liste
          </ToggleButton>
          <ToggleButton value={2} variant="light">
            Catalogue
          </ToggleButton>
          <ToggleButton value={3} variant="light">
            Création produit
          </ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar>
      {vueProduits === 1 && (
        <div>
          <Select
            isSearchable={false}
            getOptionValue={(option) => option.id}
            options={categories}
            value={categorieProduits}
            onChange={updateCategory.bind(this, "categorieProduits")}
            className="mb-2"
            styles={colourStyles}
            isDisabled={!isEditable}
          />
          <Select
            isMulti
            placeholder="Saisir un produit de ma liste"
            getOptionValue={(option) => option.id}
            options={categorie}
            formatGroupLabel={formatGroupLabel}
            value={filtrerProduits("liste")}
            onChange={updateList.bind(this, "produits")}
            className="mb-2"
            styles={colourStyles}
            isDisabled={!isEditable}
          />
        </div>
      )}
      {vueProduits === 2 && (
        <div>
          <h6 className="text-muted">
            Tapez au moins 3 lettres pour lancer la recherche
          </h6>
          <AsyncSelect
            isMulti
            placeholder="Saisir un produit du catalogue"
            getOptionValue={(option) => option.id}
            cacheOptions
            defaultOptions={[]}
            loadOptions={loadOptions}
            value={filtrerProduits("catalogue")}
            onChange={updateList.bind(this, "produitsManquants")}
            className="mb-2"
            isDisabled={!isEditable}
          />
        </div>
      )}
      {produits.length > 0 && (
        <h6 className="mb-4 text-success">
          Vous pouvez ajouter d'autres produits grâce au champ ci-dessus
        </h6>
      )}
      <Form>
        {typeId !== 76 && (
          <FormDoubleInputs
            step={".001"}
            getQuantityPerSurface={getQuantityPerSurface.bind(
              this,
              "produits",
              getSurfaceTotale
            )}
            selectedOptions={produits}
            updateItem={updateItem.bind(this, "produits", getSurfaceTotale)}
            testConformitePhyto={testConformitePhyto}
            isMultiExploitations={isMultiExploitations}
            isEditable={isEditable}
          />
        )}
        {typeId === 76 && (
          <FormSemis
            step={".001"}
            getQuantityPerSurface={getQuantityPerSurface.bind(
              this,
              "produits",
              getSurfaceTotale
            )}
            getQuantityGrainesPerSurface={getQuantityGrainesPerSurface.bind(
              this,
              getSurfaceTotale
            )}
            selectedOptions={produits}
            updateItem={updateItem.bind(this, "produits", getSurfaceTotale)}
            isMultiExploitations={isMultiExploitations}
            isEditable={isEditable}
          />
        )}
      </Form>
    </Card.Body>
  );
};

export default withRouter(Produits);
