import React from "react";
// react-bootstrap components
import { Tab, Tabs } from "react-bootstrap";
// custom component
import FicheExploitation from "parametres/ficheExploitation.js";
import PrecidOc from "parametres/precidOc.js";
import Assistance from "parametres/assistance.js";
import CreationProduit from "parametres/creationProduit.js";
// import Engrais from "parametres/engrais.js";
// import Preferences from "parametres/preferences.js";

class Parametres extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // onglet par défaut : fiche d'exploitation
      tab: "ficheExploitation",
    };
  }

  componentDidMount() {
    const historyState = this.props.history.state;
    const pathname = this.props.location.pathname;
    if (historyState && historyState[pathname]) {
      // rétablir le dernier onglet sélectionné
      this.setState({ tab: historyState[pathname].tab });
    }
    if (this.props.location.state && this.props.location.state.tab) {
      // afficher l'onglet envoyé en paramètre
      this.setState({ tab: this.props.location.state.tab });
    }
  }

  // sauvegarder le dernier onglet sélectionné
  componentWillUnmount() {
    if (!this.props.history.state) {
      this.props.history.state = {};
    }
    const pathname = this.props.location.pathname;
    this.props.history.state[pathname] = {
      tab: this.state.tab,
    };
  }

  selectTab(tab) {
    // TODO : engrais parametrable
    // if (tab === "engrais") {
    //   // requete parametrable
    //   this.props.history.push("/parametrable?requete=???&ecran=engrais");
    // } else {
    if (tab === "aideSaisie") {
      // requete parametrable
      this.props.history.push("/parametrable?requete=ecran&ecran=aideSaisie");
    } else {
      this.setState({ tab: tab });
    }
  }

  render() {
    return (
      <Tabs activeKey={this.state.tab} onSelect={this.selectTab.bind(this)}>
        <Tab eventKey="ficheExploitation" title="Fiche exploitation">
          <FicheExploitation
            isTabActive={this.state.tab === "ficheExploitation"}
          />
        </Tab>
        <Tab eventKey="precidOc" title="Préci d’Oc">
          <PrecidOc isTabActive={this.state.tab === "precidOc"} />
        </Tab>
        <Tab eventKey="produit" title="Création produit">
          <CreationProduit isTabActive={this.state.tab === "produit"} />
        </Tab>
        <Tab eventKey="assistance" title="Assistance">
          <Assistance isTabActive={this.state.tab === "assistance"} />
        </Tab>
        <Tab eventKey="aideSaisie" title="Documents aide saisie" />
        {/* <Tab eventKey="engrais" title="Création engrais">
          TODO : ce fichier doit etre parametrable
          <Engrais isTabActive={this.state.tab === "engrais"} />
        </Tab> */}
        {/* <Tab eventKey="preferences" title="Mes préférences">
          <Preferences />
        </Tab> */}
      </Tabs>
    );
  }
}

export default Parametres;
