import "bootstrap/dist/css/bootstrap.css";
import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";
// errors logger
import * as Sentry from "@sentry/browser";
// ag-grid entreprise
import * as Enterprise from "ag-grid-enterprise";
// react context api HOC component (propagate datas to components using context)
import DataProvider from "./store/DataProvider";
// UI React components
import App from "./App";
import Login from "./login/login";

// Sentry logs init
if (process.env.NODE_ENV === "production" && process.env.REACT_APP_VERSION) {
  console.log("sentry logs enabled");
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    release: process.env.REACT_APP_VERSION,
  });
}

// Ag-grid entreprise license init
Enterprise.LicenseManager.setLicenseKey(
  "CompanyName=AGRO D OC,LicensedGroup=AGRO D OC,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-023471,ExpiryDate=14_December_2022_[v2]_MTY3MDk3NjAwMDAwMA==81fba95ec0be5bf3e52b31abbd2ac94f"
);

// If browser has scrollRestoration feature, disable it (avoid to open screen scrolled to bottom)
let canControlScrollRestoration = "scrollRestoration" in window.history;
if (canControlScrollRestoration) {
  window.history.scrollRestoration = "manual";
}

// Declare empty data object
let data = {
  utilisateur: {},
  campagneActive: {},
  exploitationActive: {},
};

// Retrieve data in url parameters
const url = new URL(window.location.href);
const id_utilisateur = url.searchParams.get("id_utilisateur");
const id_exploitation = url.searchParams.get("id_exploitation");
const id_campagne = url.searchParams.get("id_campagne");

// Retrieve local data
const localData = JSON.parse(localStorage.getItem("data") || "[]");

// Try to use url parameters first
if (id_utilisateur && id_exploitation) {
  console.log("id_utilisateur from url parameters : " + id_utilisateur);
  data.utilisateur.id = Number(id_utilisateur);
  console.log("id_exploitation from url parameters : " + id_exploitation);
  data.exploitationActive.id = Number(id_exploitation);
  if (id_campagne) {
    console.log("id_campagne from url parameters : " + id_campagne);
    data.campagneActive.id = Number(id_campagne);
  }
  // stocker en cache les id provenant des paramètres d'url
  localStorage.setItem("data", JSON.stringify(data));
  // supprimer les paramètres d'url dans l'url
  window.location.href = window.location.href.split("?")[0];

  // else, try to use local data
} else {
  if (localData.utilisateur && localData.utilisateur.id) {
    console.log("id_utilisateur from local data : " + localData.utilisateur.id);
    data.utilisateur.id = localData.utilisateur.id;
  }
  if (localData.campagneActive) {
    console.log("id_campagne from local data : " + localData.campagneActive.id);
    data.campagneActive.id = localData.campagneActive.id;
  }
  if (localData.exploitationActive) {
    console.log(
      "id_exploitation from local data : " + localData.exploitationActive.id
    );
    data.exploitationActive.id = localData.exploitationActive.id;
  }
}

console.log("data used to launch app : ");
console.log(data);

const container = document.getElementById("root");
const root = createRoot(container);

// if data
if (data.utilisateur.id) {
  // go to home
  root.render(
    <DataProvider data={data}>
      <App />
    </DataProvider>
  );
  // no data
} else {
  // go to login
  root.render(
    <DataProvider>
      <Login />
    </DataProvider>
  );
}
