export const baseConfig = {
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    // minWidth: 75,
    maxWidth: 200,
    // width: 200,
    wrapText: true,
    autoHeight: true,
    initialWidth: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    suppressMenu: true,
    // rowSelection: "multiple",
    // rowMultiSelectWithClick: true,
    // headerCheckboxSelection: true, // Ajoute une case à cocher dans l'en-tête pour sélectionner/désélectionner toutes les lignes
    // checkboxSelection: true, // Ajoute une case à cocher pour chaque ligne
    // headerName: "Select",
    cellClassRules: {
      lightGreyBackground: function (params) {
        return params.node.rowIndex % 2 !== 0;
      },
    },
    // fonction qui arrondi au nombre de décimales souhaité
    valueFormatter: (params) => {
      const { decimales } = params.colDef;
      // si nombre de décimales précisé dans la config de la colonne
      if (typeof decimales === "number") {
        // pour les cellules simples
        if (!params.node.group) {
          // si la valeur de la cellule est un number
          if (typeof params.data[params.colDef.field] === "number") {
            return params.data[params.colDef.field].toFixed(decimales);
          }
          // pour les cellules regroupées
        } else if (params.node.group && Number(params.value)) {
          // on peut avoir un string
          if (typeof params.value === "string") {
            return Number(params.value).toFixed(decimales);
            // on peut avoir une fonction toString (définie dans un valueGetter)
          } else if (params.value.toString) {
            return Number(params.value.toString()).toFixed(decimales);
          }
        }
        return 0;
      }
    },
  },
  excelStyles: [
    {
      id: "header",
      interior: {
        color: "#ffc107",
        pattern: "Solid",
      },
      font: {
        bold: true,
        size: 14,
      },
      alignment: {
        vertical: "Center",
        horizontal: "Center",
      },
    },
    {
      id: "cell",
      borders: {
        borderTop: {
          color: "#d9dcde",
          lineStyle: "Continuous",
          weight: 1,
        },
        borderBottom: {
          color: "#d9dcde",
          lineStyle: "Continuous",
          weight: 1,
        },
      },
      alignment: {
        vertical: "Center",
      },
    },
    {
      id: "lightGreyBackground",
      interior: {
        color: "#f8f9fa",
        pattern: "Solid",
      },
    },
  ],
  sideBar: {
    toolPanels: [
      {
        id: "filters",
        labelDefault: "Filtres",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
      {
        id: "columns",
        labelDefault: "Colonnes",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  },
  // table height = rows number
  // domLayout: "autoHeight",
  // Bootstrap like
  headerHeight: "40",
  // rowHeight: "40",
  // striped rows
  getRowStyle: function (params) {
    if (params.node.rowIndex % 2 !== 0) {
      return { background: "#f8f9fa" };
    }
  },
  // sort & filter animations
  animateRows: true,
  // filter popup on top of everything (avoid to be clipped)
  popupParent: document.querySelector("body"),
  enableRangeSelection: true,
  // i18n FR Strings
  localeText: {
    // for number filter and text filter
    filterOoo: "valeur à filtrer",
    applyFilter: "appliquer",
    resetColumns: "daBlanc",
    equals: "égal",
    notEqual: "différent",

    // for number filter
    lessThan: "inférieur",
    greaterThan: "supérieur",
    lessThanOrEqual: "inférieur ou égal",
    greaterThanOrEqual: "supérieur ou égal",
    inRange: "compris entre",

    // for text filter
    contains: "contient",
    notContains: "ne contient pas",
    startsWith: "commence par",
    endsWith: "termine par",

    // filter conditions
    andCondition: "ET",
    orCondition: "OU",

    // no data
    noRowsToShow: "Liste vide",
  },
  // function that hides overflowed columns (small screens)
  // onGridSizeChanged: params => {
  //   const gridWidth = document.getElementById("grid-wrapper").offsetWidth;
  //   let columnsToShow = [];
  //   let columnsToHide = [];
  //   let totalColsWidth = 0;
  //   const allColumns = params.columnApi.getColumns();
  //   for (let i = 0; i < allColumns.length; i++) {
  //     let column = allColumns[i];
  //     totalColsWidth += column.getMinWidth();
  //     if (totalColsWidth > gridWidth) {
  //       columnsToHide.push(column.colId);
  //     } else {
  //       columnsToShow.push(column.colId);
  //     }
  //   }
  //   params.columnApi.setColumnsVisible(columnsToShow, true);
  //   params.columnApi.setColumnsVisible(columnsToHide, false);
  //   params.api.sizeColumnsToFit();
  // }
};
