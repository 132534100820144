import React, { createContext } from "react";

export const DataContext = createContext({
  ilots: [],
  parcelles: [],
  groupesParcelles: [],
  cultures: [],
  preference: {}
});

class ParcellaireProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.data
    };
  }

  render() {
    return (
      <DataContext.Provider value={this.state}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

export default ParcellaireProvider;

export const WithParcellaire = Component => {
  return React.forwardRef((props, ref) => {
    return (
      <DataContext.Consumer>
        {value => <Component {...props} ref={ref} ctx={value} />}
      </DataContext.Consumer>
    );
  });
};
