import React, { createContext } from "react";

export const ParcellesContext = createContext({
  groupesParcelles: [],
  parcelles: [],
  parcellesActives: []
});

class ParcellesActivesProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.data,
      parcellesActives: [],
      updateSurface: event => {
        const target = event.currentTarget;
        const id = Number(target.getAttribute("data-id"));
        let surface = +Number(target.value).toFixed(2);
        const max = Number(target.getAttribute("max"));
        const min = Number(target.getAttribute("min"));

        if (surface < min) {
          surface = min;
        } else if (surface > max) {
          surface = max;
        }

        this.setState(state => {
          state.parcellesActives.find(
            parcelle => parcelle.id === id
          ).surface = surface;
          return state;
        });
      },
      getSurfaceTotale: () => {
        return this.state.parcellesActives.reduce((total, currentValue) => {
          let surfaceTotale = Number(total) + Number(currentValue.surface);
          return surfaceTotale.toFixed(2);
        }, 0);
      },
      isActive: parcelleId => {
        return this.state.parcellesActives.some(
          parcelle => parcelle.id === parcelleId
        );
      },
      setParcellesActives: parcellesActives => {
        this.setState({ parcellesActives: parcellesActives });
      },
      toggle: toggledParcelle => {
        let parcelles = JSON.parse(JSON.stringify(this.state.parcellesActives));
        const isActive = parcelles.some(
          (parcelle) => parcelle.id === toggledParcelle.id
        );

        if (isActive) {
          parcelles = parcelles.filter(
            parcelle => parcelle.id !== toggledParcelle.id
          );
        } else {
          parcelles = [...parcelles, toggledParcelle];
        }

        this.setState({
          parcellesActives: parcelles
        });
      },
      toggleAllParcelles: (parcelles, isActive) => {
        let parcellesActives = [];
        if (isActive) {
          parcellesActives = [];
        } else {
          parcellesActives = parcelles;
        }
        this.setState({
          parcellesActives: parcellesActives.map((p) => p.properties),
        });
      },
      groupToggle: (index, e) => {
        e.stopPropagation();
        const isChecked = !this.state.groupesParcelles[index].checked;
        const groupe = this.state.groupesParcelles[index];
        const toggledGroup = groupe.options.map(opt => {
          return {
            id: opt.properties.id,
            label: opt.properties.label,
            surface: opt.properties.surface,
            culture: opt.properties.culture
          };
        });

        let parcellesActives;
        if (isChecked) {
          parcellesActives = [...this.state.parcellesActives, ...toggledGroup];
          parcellesActives = Array.from(
            new Set(parcellesActives.map(a => a.id))
          ).map(id => {
            return parcellesActives.find(a => a.id === id);
          });
        } else {
          const parcellesIds = toggledGroup.map(group => group.id);
          parcellesActives = this.state.parcellesActives.filter(
            parcelleActive => {
              return !parcellesIds.includes(parcelleActive.id);
            }
          );
        }

        this.setState(state => {
          state.groupesParcelles[index].checked = !state.groupesParcelles[index]
            .checked;
          state.parcellesActives = parcellesActives;
          return state;
        });
      }
    };
  }

  render() {
    return (
      <ParcellesContext.Provider value={this.state}>
        {this.props.children}
      </ParcellesContext.Provider>
    );
  }
}

export default ParcellesActivesProvider;

export const WithParcellesActives = Component => {
  return React.forwardRef((props, ref) => {
    return (
      <ParcellesContext.Consumer>
        {value => <Component {...props} ref={ref} ctx={value} />}
      </ParcellesContext.Consumer>
    );
  });
};
