import React from "react";
// leaflet
import L from "leaflet";
// react-leaflet
import { MapContainer as LeafletMap, TileLayer, GeoJSON } from "react-leaflet";
// custom style
import "./miniMap.css";
// couleurs des cultures
import { couleursCultures } from "../parcellaire/couleurs.js";

class MiniMap extends React.Component {
  getCouleurCulture = cultureId => {
    const correspondance = couleursCultures.find(item =>
      item.culturesIds.includes(cultureId)
    );
    if (correspondance) {
      return correspondance.couleur;
    } else {
      return "#B0BEC5";
    }
  };

  getStyle = layer => {
    let cultureId;
    if (layer.properties && layer.properties.culture) {
      cultureId = layer.properties.culture[0].id;
    }
    const couleurCulture = this.getCouleurCulture(cultureId);

    return {
      color: couleurCulture,
      fillColor: couleurCulture
    };
  };

  render() {
    let bounds;
    const isValidContours = this.props.parcelles.every(
      (p) => p.geometry.coordinates && p.geometry.coordinates.length
    );
    if (
      isValidContours
    ) {
      bounds = L.geoJson(this.props.parcelles).getBounds();
    }
    return (
      <LeafletMap
        bounds={this.props.isTabActive ? bounds : undefined}
        attributionControl={false}
        zoomControl={false}
        dragging={false}
        touchZoom={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        boxZoom={false}
        keyboard={false}
        tap={false}
        style={{
          width: "50px",
          height: "50px"
        }}
      >
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <GeoJSON data={this.props.parcelles} style={this.getStyle} />
      </LeafletMap>
    );
  }
}

export default MiniMap;
