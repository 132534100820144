import React, { useContext } from "react";
// global context
import { DataContext } from "store/DataProvider";
// react-bootstrap components
import { Container, Alert, Button } from "react-bootstrap";

const PrecidOc = () => {
  const data = useContext(DataContext);
  const exportPreciDoc = () => {
    const id_utilisateur = data.utilisateur.id;
    const id_campagne = data.campagneActive.id;
    const id_exploitation = data.exploitationActive.id;
    window.open(
      `https://json.ceredoc.fr/ajax/lienPrecidoc.php?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`,
      "_blank"
    );
  };
  return (
    <Container className="mt-3">
      <Alert show variant="warning">
        <Alert.Heading>Lien vers Préci d’Oc</Alert.Heading>
        <p>
          Pour activer la possibilité de transférer votre parcellaire CERE D’OC
          vers PRECI D’OC, contacter l’équipe d’AGRO D’OC
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button variant="warning" onClick={() => exportPreciDoc()}>
            Lien Préci d’Oc
          </Button>
        </div>
      </Alert>
    </Container>
  );
};

export default PrecidOc;
