import React from "react";
// router
import { withRouter } from "react-router-dom";
// react-bootstrap components
import { Container, Row, Col, Card } from "react-bootstrap";
// react-icons
import Icon from "../icons/icon.js";

const Boutons = ({ data, history }) => {
  const handleClick = (bouton) => {
    let url;
    let params = {};
    // privilégier l'url attribuée au bouton
    if (bouton.url) {
      url = bouton.url;
      // sinon url de la config
    } else if (data.url) {
      url = data.url;
      // définir les params pour les cas particuliers
      if (data.url === "/assolement") {
        params = { id: bouton.id };
      } else if (data.url === "/createIntervention") {
        const parcelles = history.location.state
          ? history.location.state.parcelles
          : undefined;
        params = {
          interventionTypeId: bouton.id,
          parcelles: parcelles,
        };
      } else if (data.url.includes("/parametrable")) {
        url = `/parametrable?requete=${bouton.requete}`;
        if (bouton.ecran) {
          url += `&ecran=${bouton.ecran}`;
        }
      }
    }
    if (url) {
      history.push(url, params);
    }
  };

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col className="text-center">
          <h4> {data.title} </h4>
        </Col>
      </Row>
      <Row className="mt-3">
        {data.boutons.map((bouton) => (
          <Col key={bouton.id} lg={3}>
            <Card
              onClick={() => handleClick(bouton)}
              className="mb-3"
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-center text-secondary">
                {bouton.icon && <Icon iconName={bouton.icon} size={64} />}
              </div>
              <Card.Body>
                <Card.Text className="text-center">{bouton.title}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default withRouter(Boutons);
