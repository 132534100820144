import React, { useState } from "react";
// custom component
import Caracteristiques from "parcelles/accordions/caracteristiques";
import Contour from "parcelles/accordions/contour";
// react-bootstrap components
import { Card, Collapse } from "react-bootstrap";
// react-icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const cardBordersFix = {
  borderBottom: "0px",
  borderTop: "1px solid rgba(0, 0, 0, 0.125)",
};

const Accordions = ({
  isUpdate,
  parcelle,
  cultureId,
  surfaceTelepac,
  surfaceDessin,
  handleChange,
  handleReactSelectChange,
  loadOptions,
  setGeometry,
  setSurface,
  history,
}) => {
  const accordionsData = [
    {
      title: "Caractéristiques",
      child: <Caracteristiques />,
    },
    {
      title: "Contour",
      child: <Contour />,
    },
  ];

  const [accordions, setAccordions] = useState(
    accordionsData.map((accordion) => (isUpdate ? true : false))
  );

  const toggle = (index) => {
    let newAccordions = [...accordions];
    newAccordions[index] = !newAccordions[index];
    setAccordions(newAccordions);
  };

  return accordionsData.map((accordion, index) => {
    const { title, child } = accordion;
    const isOpen = accordions[index];
    const icon = isOpen ? (
      <FaChevronUp size={20} />
    ) : (
      <FaChevronDown size={20} />
    );
    return (
      <div key={index} className="w-100">
        <Card onClick={() => toggle(index)} style={cardBordersFix}>
          <Card.Header
            className="d-flex justify-content-between align-items-center text-center"
            style={{ height: "60px" }}
          >
            <div />
            <h5 className="mb-0"> {title} </h5>
            {icon}
          </Card.Header>
        </Card>
        <Collapse in={isOpen}>
          <div>
            {React.cloneElement(child, {
              isOpen: isOpen,
              isUpdate: isUpdate,
              parcelle: parcelle,
              cultureId: cultureId,
              surfaceTelepac: surfaceTelepac,
              surfaceDessin: surfaceDessin,
              handleChange: handleChange,
              handleReactSelectChange: handleReactSelectChange,
              loadOptions: loadOptions,
              setGeometry: setGeometry,
              setSurface: setSurface,
              history: history,
            })}
          </div>
        </Collapse>
      </div>
    );
  });
};

export default Accordions;
