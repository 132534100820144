import React, { useContext } from "react";
// create intervention context
import { CreateContext } from "interventions/createProvider";
import { ParcellesContext } from "parcelles/provider";
// custom components
import { FormDoubleTimePickers } from "interventions/forms.js";
// react-bootstrap components
import { Card, Form } from "react-bootstrap";
// react-select component
import Select from "react-select";
// intervention datas
import { addQuantity } from "interventions/datas.js";

const Materiel = ({ isEditable }) => {
  const {
    listeMateriel,
    intervention,
    updateList,
    updateItem,
    getQuantityPerSurface
  } = useContext(CreateContext);
  const { getSurfaceTotale } = useContext(ParcellesContext);
  const options = addQuantity(listeMateriel);
  return (
    <Card.Body>
      <Select
        isMulti
        placeholder="Ajouter du matériel"
        getOptionValue={option => option.id}
        options={options}
        value={intervention.materiels}
        className="mb-2"
        onChange={updateList.bind(this, "materiels")}
        isDisabled={!isEditable}
      />
      <Form>
        <FormDoubleTimePickers
          getQuantityPerSurface={getQuantityPerSurface.bind(
            this,
            "materiels",
            getSurfaceTotale
          )}
          selectedOptions={intervention.materiels}
          updateItem={updateItem.bind(this, "materiels", getSurfaceTotale)}
          isEditable={isEditable}
        />
      </Form>
    </Card.Body>
  );
};

export default Materiel;
