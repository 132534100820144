import React from "react";
// datas
import { WithParcellesActives } from "parcelles/provider";
// react-bootstrap components
import {
  Button,
  ListGroup,
  Badge,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { getVarieteLabel } from "utils/parcelle";

class Parcelle extends React.Component {
  toggle(event) {
    const target = event.currentTarget;
    const toggledParcelle = {
      id: Number(target.getAttribute("data-id")),
      label: target.getAttribute("data-label"),
      surface: +Number(target.getAttribute("data-surface")).toFixed(2),
      culture: [
        {
          id: Number(target.getAttribute("data-culture-id")),
          label: target.getAttribute("data-culture-label"),
        },
      ],
    };
    this.props.ctx.toggle(toggledParcelle);
  }

  toggleAll() {
    let toggledParcelles = [];
    if (!this.props.ctx.parcellesActives.length) {
      this.props.list.forEach((parcelle) => {
        const { id, label, surface, culture } = parcelle.properties;
        toggledParcelles.push({ id, label, surface, culture });
      });
    }
    this.props.ctx.setParcellesActives(toggledParcelles);
  }

  render() {
    const { isEditable, isSurfaceEditable, list } = this.props;
    const parcellesActives = this.props.ctx.parcellesActives;
    return (
      <>
        {isEditable && (
          <Button
            variant={!parcellesActives.length ? "success" : "danger"}
            className="m-3"
            onClick={isEditable ? this.toggleAll.bind(this) : undefined}
          >
            {!parcellesActives.length
              ? "Sélectionner toute la liste"
              : "Désélectionner toute la liste"}
          </Button>
        )}
        <ListGroup as="ul">
          {list.map((parcelle, index) => {
            const id = parcelle.properties.id;
            const isActive = this.props.ctx.isActive(id);
            const label = parcelle.properties.label;
            const culture = parcelle.properties.culture[0].label.toLowerCase();
            const cultureId = parcelle.properties.culture[0].id;
            const cultureLabel = parcelle.properties.culture[0].label;
            const varietes = parcelle.properties.varietes;
            const surface = parcelle.properties.surface;
            return (
              <ListGroup.Item
                data-id={id}
                data-label={label}
                data-surface={surface}
                data-culture-id={cultureId}
                data-culture-label={cultureLabel}
                key={id}
                as="li"
                action
                onClick={isEditable ? this.toggle.bind(this) : undefined}
                variant={isActive ? "success" : ""}
              >
                {!isActive || (isActive && !isSurfaceEditable) ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0"> {label} </h6>
                    <div className="d-flex flex-row">
                      <h6 className="mb-0 mr-1">
                        <Badge variant="warning">{culture}</Badge>
                      </h6>
                      {varietes.length > 0 && (
                        <h6 className="mb-0">
                          <Badge pill key={index} variant="info">
                            {getVarieteLabel(varietes)}
                          </Badge>
                        </h6>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="mb-0"> {label} </h6>
                      <InputGroup size="sm" className="w-auto">
                        <FormControl
                          data-id={id}
                          name="surface"
                          type="number"
                          step={"0.01"}
                          max={surface}
                          min={0}
                          value={
                            this.props.ctx.parcellesActives.find(
                              (item) => item.id === id
                            ).surface
                          }
                          onChange={this.props.ctx.updateSurface}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>/{surface}ha</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-1">
                      <h6 className="mb-0 mr-1">
                        <Badge variant="warning">{culture}</Badge>
                      </h6>
                      {varietes.length > 0 && (
                        <h6 className="mb-0">
                          <Badge pill key={index} variant="info">
                            {getVarieteLabel(varietes)}
                          </Badge>
                        </h6>
                      )}
                    </div>
                  </div>
                )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  }
}

export default WithParcellesActives(Parcelle);
