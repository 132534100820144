import React, { useState } from "react";
// react-bootstrap components
import { Card, Collapse, Form } from "react-bootstrap";
// react-icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// custom components
import Input from "inputs/input";
import Parcelles from "interventions/accordions/parcelles";

const cardBordersFix = {
  borderBottom: "0px",
  borderTop: "1px solid rgba(0, 0, 0, 0.125)",
};

const Accordions = ({
  accordionsData,
  isUpdate,
  formData,
  handleChange,
  handleReactSelectChange,
}) => {
  const [accordions, setAccordions] = useState(
    accordionsData.map((accordion) => (isUpdate ? true : false))
  );

  const toggle = (index) => {
    let newAccordions = [...accordions];
    newAccordions[index] = !newAccordions[index];
    setAccordions(newAccordions);
  };

  return accordionsData.map((accordion, index) => {
    const { title } = accordion;
    const isOpen = accordions[index];
    const icon = isOpen ? (
      <FaChevronUp size={20} />
    ) : (
      <FaChevronDown size={20} />
    );
    return (
      <div key={index} className="w-100">
        <Card onClick={() => toggle(index)} style={cardBordersFix}>
          <Card.Header
            className="d-flex justify-content-between align-items-center text-center"
            style={{ height: "60px" }}
          >
            <div />
            <h5 className="mb-0"> {title} </h5>
            {icon}
          </Card.Header>
        </Card>
        <Collapse in={isOpen}>
          <div>
            <Card.Body>
              {accordion.inputs && (
                <Inputs
                  inputs={accordion.inputs}
                  formData={formData}
                  handleChange={handleChange}
                  handleReactSelectChange={handleReactSelectChange}
                />
              )}
              {accordion.parcelles && <Parcelles isOpen={isOpen} isEditable />}
              {accordion.inputGroups &&
                accordion.inputGroups.map((inputGroup, index) => (
                  <Card key={index} text="dark" className="mb-3">
                    <Card.Header as="h5">{inputGroup.name}</Card.Header>
                    <Card.Body>
                      <Inputs
                        inputs={inputGroup.fields}
                        formData={formData}
                        handleChange={handleChange}
                        handleReactSelectChange={handleReactSelectChange}
                        gridSize={{ label: "6", input: "6" }}
                      />
                    </Card.Body>
                  </Card>
                ))}
            </Card.Body>
          </div>
        </Collapse>
      </div>
    );
  });
};

export default Accordions;

const Inputs = ({
  inputs,
  formData,
  handleChange,
  handleReactSelectChange,
  gridSize
}) => {
  return (
    <Form>
      {inputs.map((input, index) => {
        let options = [];
        if (input.options) {
          options = input.options;
        }
        return (
          <Input
            key={index}
            input={input}
            value={formData[input.name]}
            options={options}
            handleChange={handleChange}
            handleReactSelectChange={handleReactSelectChange}
            gridSize={gridSize}
          />
        );
      })}
    </Form>
  );
};
