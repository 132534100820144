import React from "react";
import ReactDOM from "react-dom";
// datas
import { WithContext } from "../store/DataProvider";
// react context api HOC component (propagate datas to components using context)
import DataProvider from "../store/DataProvider";
// UI React components
import App from "../App";
// react-bootstrap components
import {
  Image,
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
} from "react-bootstrap";
// brand logo
import logo from "../logo.png";
// toast notifications
import Notifications from "../notifications/component";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
    this.login = this.login.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  login() {
    const email = this.state.email;
    const password = this.state.password;
    this.props.data.login(email, password).then((data) => {
      if (data.utilisateur && data.utilisateur.id) {
        // Go to home
        ReactDOM.render(
          <DataProvider data={data}>
            <App />
          </DataProvider>,
          document.getElementById("root")
        );
      } else {
        this.props.data.showNotification({
          type: "danger",
          title:
            "Vos identifiants ne correspondent à aucun compte Céré d'Oc existant.",
        });
      }
    });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col />
          <Col lg={6}>
            <Card className="p-4">
              <Card.Body>
                <div className="w-75 mx-auto d-block mb-5">
                  <Image src={logo} alt="logo" fluid />
                </div>
                <Form>
                  <Form.Group>
                    <Form.Label>Identifiant</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Votre identifiant"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Votre mot de passe"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Button
                    variant="success"
                    className="float-right"
                    disabled={!this.state.email || !this.state.password}
                    onClick={this.login}
                  >
                    Connexion
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col />
          <Notifications />
        </Row>
      </Container>
    );
  }
}

export default WithContext(Login);
