import React from "react";
// custom style
import "./App.css";
// context providers
import ParcellaireProvider from "parcellaire/provider";
import ParcellesActivesProvider from "parcelles/provider";
import CreateInterventionProvider from "interventions/createProvider";
// context consumer
import { WithContext } from "store/DataProvider";
// router
import { BrowserRouter, Route } from "react-router-dom";
// scroll helper
import ScrollToTop from "scroll/scrollToTop";
// components
import Menu from "menu/menu";
import Home from "home/home";
import Parcellaire from "parcellaire/parcellaire";
import OutilsDessin from "parcellaire/outilsDessin";
import CreateIlot from "ilots/createIlot";
import CreateParcelle from "parcelles/createParcelle";
import CreateGroupe from "groupes/createGroupe";
import Assolement from "parcellaire/assolement";
import Interventions from "interventions/list";
import CreateIntervention from "interventions/create";
import CreateSaisiePPF from "directiveNitrate/CreateSaisiePPF";
import CreateMateriel from "materiel/createMateriel";
import CreateMainOeuvre from "mainOeuvre/createMainOeuvre";
import CreateMouvementStock from "stocks/createMouvementStock";
import TransfertStock from "stocks/transfertStock";
import Exploitations from "exploitations/list";
import Campagnes from "campagnes/list";
import Parametres from "parametres/parametres";
import Parametrable from "generique/parametrable";
// service worker
import * as serviceWorker from "serviceWorker";
// uuid function
import { create_UUID } from "utils/uuid";

class App extends React.Component {
  componentDidMount() {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      // New content is available, all tabs for this page needs to be closed in order to display new content
      onUpdate: (registration) => {
        // Some UI notification comes here, then reload
        this.props.data.showNotification({
          type: "success",
          title: `Nouveau contenu disponible.
          Céré d'Oc va se relancer.`,
        });
        const waitingServiceWorker = registration.waiting;
        if (waitingServiceWorker) {
          waitingServiceWorker.addEventListener("statechange", (event) => {
            if (event.target.state === "activated") {
              setTimeout(() => window.location.reload(), 2000);
            }
          });
          waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
      },
      // Everything has been precached, offline mode is available
      onSuccess: () => {
        // Some UI notification comes here
        this.props.data.showNotification({
          type: "success",
          title: `Céré d'Oc peut fonctionner en mode hors-ligne (sans connexion internet).
            Céré d'Oc va se relancer`,
        });
        setTimeout(() => window.location.reload(), 2000);
      },
      // SW is ready
      onReady: () => {
        if (navigator.serviceWorker) {
          navigator.serviceWorker.addEventListener("message", (message) => {
            if (message.data) {
              if (message.data.type === "REQUEST_QUEUED") {
                this.props.data.updateQueuedRequests();
                this.props.data.showNotification({
                  type: "success",
                  title: "Votre action est en attente de synchronisation",
                });
              } else if (
                message.data.type ===
                ("QUEUE_DID_REPLAY_AUTO" || "QUEUE_DID_REPLAY_MANUAL")
              ) {
                this.props.data.updateQueuedRequests(
                  message.data.queuedRequests
                );
                if (message.data.queuedRequests === 0) {
                  this.props.data.showNotification({
                    type: "success",
                    title: "Toutes vos actions ont bien été synchronisées",
                  });
                }
              }
            }
          });
        }
      },
    });
    this.props.data.sync(true);
  }

  render() {
    return (
      <div className="App fillAvailableHeight">
        <BrowserRouter>
          <ScrollToTop />
          <Menu />
          <Route
            exact
            path="/"
            component={(props) => withContext("home", props, this.props.data)}
          />
          <Route
            path="/parcellaire"
            component={(props) =>
              withContext("parcellaire", props, this.props.data)
            }
          />
          <Route
            path="/outilsDessin"
            component={(props) =>
              withContext3("outilsDessin", props, this.props.data)
            }
          />
          <Route path="/createIlot" component={CreateIlot} />
          <Route
            path="/createParcelle"
            component={(props) =>
              withContext("createParcelle", props, this.props.data)
            }
          />
          <Route
            path="/createGroupe"
            component={(props) =>
              withContext("createGroupe", props, this.props.data)
            }
          />
          <Route
            path="/assolement"
            component={(props) =>
              withContext3("assolement", props, this.props.data)
            }
          />
          <Route path="/interventions" component={Interventions} />
          <Route
            path="/createIntervention"
            component={(props) => withContext2(props, this.props.data)}
          />
          <Route
            path="/createSaisiePPF"
            component={(props) =>
              withContext3("createSaisiePPF", props, this.props.data)
            }
          />
          <Route
            path="/createMouvementStock"
            component={CreateMouvementStock}
          />
          <Route path="/transfertStock" component={TransfertStock} />
          <Route path="/createMateriel" component={CreateMateriel} />
          <Route path="/createMainOeuvre" component={CreateMainOeuvre} />
          <Route path="/exploitations" component={Exploitations} />
          <Route path="/campagnes" component={Campagnes} />
          <Route path="/parametres" component={Parametres} />
          <Route
            path="/parametrable"
            component={(props) => (
              <Parametrable {...props} key={create_UUID()} />
            )}
          />
        </BrowserRouter>
      </div>
    );
  }
}

export default WithContext(App);

const withContext = (path, props, data) => {
  let Provider;
  let providerData;
  let Component;
  if (path === "home") {
    Provider = ParcellesActivesProvider;
    providerData = {
      parcelles: data.parcelles || [],
      varietes: data.varietes || [],
      cultures: data.cultures || [],
    };
    Component = Home;
  } else if (path === "parcellaire") {
    Provider = ParcellaireProvider;
    providerData = {
      ilots: data.ilots || [],
      groupesParcelles: data.groupesParcelles || [],
      parcelles: data.parcelles || [],
      parcellesExploitation: data.parcellesExploitation || [],
      varietes: data.varietes || [],
      cultures: data.cultures || [],
      preference: data.preference || {},
    };
    Component = Parcellaire;
  } else if (path === "createParcelle") {
    Provider = ParcellesActivesProvider;
    providerData = {
      parcelles: data.parcelles || [],
      varietes: data.varietes || [],
      cultures: data.cultures || [],
    };
    Component = CreateParcelle;
  } else if (path === "createGroupe") {
    Provider = ParcellesActivesProvider;
    providerData = {
      parcelles: data.parcelles || [],
      varietes: data.varietes || [],
      cultures: data.cultures || [],
    };
    Component = CreateGroupe;
  }
  return (
    <Provider data={providerData}>
      <Component {...props} />
    </Provider>
  );
};

const withContext2 = (props, data) => {
  return (
    <CreateInterventionProvider
      data={{
        typeId: props.history.location.state.interventionTypeId,
        listeProduits: data.produits || [],
        listeMateriel: data.materiels || [],
        listeMainOeuvre: data.mainOeuvres || [],
      }}
    >
      <ParcellesActivesProvider
        data={{
          parcelles: data.parcelles || [],
          varietes: data.varietes || [],
          cultures: data.cultures || [],
          groupesParcelles: data.groupesParcelles || [],
        }}
      >
        <CreateIntervention {...props} />
      </ParcellesActivesProvider>
    </CreateInterventionProvider>
  );
};

const withContext3 = (path, props, data) => {
  let Component;
  if (path === "createSaisiePPF") {
    Component = CreateSaisiePPF;
  } else if (path === "outilsDessin") {
    Component = OutilsDessin;
  } else if (path === "assolement") {
    Component = Assolement;
  }
  return (
    <ParcellesActivesProvider
      data={{
        parcelles: data.parcelles || [],
        varietes: data.varietes || [],
        cultures: data.cultures || [],
        groupesParcelles: data.groupesParcelles || [],
      }}
    >
      <Component {...props} />
    </ParcellesActivesProvider>
  );
};
