import React, { useContext, useEffect, useRef, useState } from "react";
// global context
import { DataContext } from "store/DataProvider";
// router
import { withRouter } from "react-router-dom";
// ag-grid component
import Grid from "ag-grid/grid";
// ag-grid column buttons component
import buttonRenderer from "stocks/buttonRenderer";
// bootstrap components
import { Container, Row, Col, Button, Modal } from "react-bootstrap";

const TransfertStock = ({ history }) => {
  // global context
  const { campagneActive, sendRequest } = useContext(DataContext);
  // variables locales
  const campagneN = campagneActive.id - 1;
  const campagneN1 = campagneActive.id;
  // const campagneN2 = campagneActive.id + 1;
  // données du tableau
  const data = {
    columnDefs: [
      {
        headerName: "Groupe",
        field: "groupe",
      },
      {
        headerName: "Produit",
        field: "produit",
      },
      {
        headerName: `Stock actuel campagne ${campagneN} / ${campagneN1}`,
        field: "stockActuel",
        minWidth: 300,
      },
      {
        headerName: "Unité",
        field: "unite",
      },
      {
        headerName: "Quantité à déplacer",
        field: "quantite",
        editable: true,
        minWidth: 200,
      },
      {
        headerName: "Action",
        field: "action",
        cellRenderer: "buttonRenderer",
        minWidth: 150,
      },
      // {
      //   headerName: `Stock actuel campagne ${campagneN1} / ${campagneN2}`,
      //   field: "stockFutur",
      //   minWidth: 300,
      // },
    ],
    rowData: [],
    components: {
      buttonRenderer: buttonRenderer,
    },
  };
  // récupérer les stocks de l'utilisateur au montage du composant
  useEffect(() => {
    async function getData() {
      const stocks = await sendRequest("getStocks");
      grid.current.setRowData(stocks);
    }
    getData();
  }, [sendRequest]);
  // grid reference
  const grid = useRef();
  // modal variables
  const [showModal, setShowModal] = useState(false);
  // save function
  const save = async () => {
    const isSuccess = await sendRequest("transfererStock", {
      data: { produits: grid.current.getRowData() },
    });
    if (isSuccess) {
      history.replace("parametrable?requete=ecran&ecran=stocksAppros");
    }
  };
  return (
    <Container fluid className="fillAvailableHeight">
      <Row>
        <h4 className="mt-3 ml-3">
          Report des stocks vers la prochaine campagne
        </h4>
      </Row>
      <Row className="fillAvailableHeight">
        <Grid
          ref={grid}
          specificConfig={data}
          buttons={undefined}
          exportExcel={false}
          exportPDF={false}
          rowClickCallback={undefined}
        />
      </Row>
      <Row className="mt-3 mb-3">
        <Col>
          <Button
            variant="warning"
            onClick={() => setShowModal(true)}
            className="float-right"
          >
            Enregistrer le report
          </Button>
        </Col>
      </Row>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous certain de vouloir enregistrer ce report ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button
            variant="success"
            onClick={() => {
              save();
              setShowModal(false);
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default withRouter(TransfertStock);
