import React from "react";
// router
import { withRouter } from "react-router-dom";
// contexte parcelles (this.props.ctx)
import { WithParcellaire } from "../parcellaire/provider";
// contexte global (this.props.data)
import { WithContext } from "../store/DataProvider";
// custom components
import MiniMap from "../map/miniMap.js";
import ScrollButtons from "scroll/buttons";
// react-bootstrap components
import { Container, Row, Col, Button, ListGroup, Badge } from "react-bootstrap";
// react-icons
import { MdModeEdit } from "react-icons/md";

class ListeGroupes extends React.Component {
  openCreate() {
    this.props.history.push("/createGroupe", {});
  }

  openEdit(e) {
    let id = e.currentTarget.getAttribute("data-id");
    // essayer de convertir le string en number
    if (Number(id)) {
      id = Number(id);
    }
    // ouvrir la page de modification pour les groupes manuels uniquement
    if (typeof id === "number" && id !== 0) {
      this.props.history.push("/createGroupe", {
        id: id,
      });
    }
    if (id < 0) {
      this.props.data.showNotification({
        type: "warning",
        title: `Ce groupe n'est pas encore synchronisé.
          Vous pouvez le visualiser.
          Vous ne pouvez pas le modifier, ni le supprimer tant qu'il n'est pas synchronisé.`,
      });
    }
  }

  render() {
    const groupes = this.props.ctx.groupesParcelles;
    return (
      <>
        <Container className="mt-3">
          <Row className="mb-3">
            <Col>
              <Button
                onClick={this.openCreate.bind(this)}
                variant="warning"
                className="float-right"
              >
                Créer un groupe
                <MdModeEdit size={25} className="ml-2" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup as="ul">
                <ListGroup.Item className="align-items-center">
                  <h4 className="mb-0">Groupes ({groupes.length})</h4>
                </ListGroup.Item>
                {groupes.map((groupe, index) => {
                  const isGeometry = groupe.options.every(
                    (parcelle) =>
                      parcelle.geometry && parcelle.geometry.coordinates
                  );
                  return (
                    <ListGroup.Item
                      data-id={groupe.id}
                      key={groupe.id}
                      as="li"
                      action
                      onClick={this.openEdit.bind(this)}
                      variant={getVariant(groupe.id)}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-row flex-wrap">
                            <h6> {groupe.label} </h6>
                            <h6>
                              <Badge variant="secondary" className="ml-1">
                                {groupe.surfaceTotale}ha
                              </Badge>
                            </h6>
                          </div>
                          <div className="d-flex flex-row flex-wrap">
                            {groupe.options.map(
                              (parcelle, index) =>
                                index <= 3 && (
                                  <h6
                                    key={parcelle.properties.id}
                                    className="mb-0 mr-1"
                                  >
                                    <Badge variant="warning">
                                      {index < 3
                                        ? parcelle.properties.label
                                        : `+${groupe.options.length - index}`}
                                    </Badge>
                                  </h6>
                                )
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-nowrap">
                          {isGeometry && (
                            <MiniMap
                              isTabActive={this.props.isTabActive}
                              parcelles={groupe.options}
                            />
                          )}
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
        <ScrollButtons />
      </>
    );
  }
}

export default withRouter(WithContext(WithParcellaire(ListeGroupes)));

// fonction qui renvoie la couleur en fonction du type de groupe (auto ou manuel)
const getVariant = (id) => {
  // un groupe auto a un id en string ("auto-1 par exemple")
  if (typeof id === "string" || id === 0) {
    return "warning";
  } else {
    return "success";
  }
};
