import React from "react";
// router
import { withRouter, Link } from "react-router-dom";
// datas
import { WithContext } from "../store/DataProvider";
// bootstrap components
import { Navbar, Nav, Modal, Button } from "react-bootstrap";
// custom style
import "./menu.css";
// react-icons
import { MdArrowBack, MdPowerSettingsNew } from "react-icons/md";
// brand logo
import logo from "../logo.png";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isModalOpen: false };
    this.goBack = this.goBack.bind(this);
    this.navbar = React.createRef();
  }

  // componentDidMount() {
  //   // activer un listener pour détecter quand l'utilisateur revient sur l'app
  //   document.addEventListener("visibilitychange", () => {
  //     if (
  //       document.visibilityState === "visible" &&
  //       this.props.history.location.pathname !== "/createIntervention"
  //     ) {
  //       // actualiser les données quand l'utilisateur revient sur l'app
  //       this.props.data.sync();
  //     }
  //   });
  // }

  componentDidUpdate() {
    this.adjustScreenPaddingTop();
  }

  adjustScreenPaddingTop() {
    const rootDiv = document.querySelector("#root");
    const navbarHeight = this.navbar.current.offsetHeight;
    if (rootDiv.style.paddingTop !== navbarHeight) {
      rootDiv.style.paddingTop = `${navbarHeight}px`;
    }
  }

  isHome = () => {
    return this.props.location.pathname === "/";
  };

  isActive = (path, index) => {
    const { pathname, search } = this.props.location;
    // index item menu : paramètres d'url
    const urls = {
      100000000: [
        "/parcellaire",
        "/createParcelle",
        "/outilsDessin",
        "/createGroupe",
        "/createIlot",
        "?requete=boutons&ecran=assolement",
        "/assolement",
      ],
      200000000: [
        "?requete=boutons&ecran=moyensDeProduction",
        "?requete=ecran&ecran=materiel",
        "?requete=ecran&ecran=mainOeuvre",
        "?requete=ecran&ecran=stocksAppros",
        "/createMateriel",
        "/createMainOeuvre",
      ],
      300000000: [
        "/interventions",
        "?requete=boutons&ecran=interventions",
        "/createIntervention",
      ],
      500000000: [
        "?requete=boutons&ecran=syntheses",
        "?requete=synthesesParametrables&ecran=fichesParcellaire",
        "?requete=synthesesParametrables&ecran=historiqueInterv",
        "?requete=synthesesParametrables&ecran=fichesTravaux",
        "?requete=synthesesParametrables&ecran=marges",
        "?requete=synthesesParametrables&ecran=charges",
        "?requete=synthesesParametrables&ecran=materiel",
        "?requete=synthesesParametrables&ecran=mainOeuvre",
        "?requete=synthesesParametrables&ecran=stocksAppros",
        "?requete=synthesesParametrables&ecran=moyensDeProduction",
      ],
      600000000: ["?requete=ecran&ecran=reglementation", "/createSaisiePPF"],
      700000000: ["?requete=ecran&ecran=outils"],
      800000000: ["/parametres"],
      900000000: ["/campagnes"],
      1000000000: ["/exploitations"],
    };
    // cas spécial : pour les écrans paramétrables
    if (pathname === "/parametrable") {
      // distinguer via les paramètres d'url
      return urls[index].includes(search);
      // comparer l'url actuelle avec l'url du menu
    } else {
      return urls[index].includes(pathname);
    }
  };

  onBackButtonPressed = () => {
    const { pathname } = this.props.location;
    const urls = [
      "/createParcelle",
      "/createGroupe",
      "/createIlot",
      "/assolement",
      "/createMateriel",
      "/createMainOeuvre",
      "/createIntervention",
      "/createSaisiePPF",
    ];
    if (urls.includes(pathname)) {
      this.setState({ isModalOpen: true });
    } else {
      this.goBack();
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  deconnexion = () => {
    // reset du cache et des données de l'app & redirection
    this.props.data.deconnexion();
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  render() {
    let { menu } = this.props.data;
    const { campagneActive } = this.props.data;
    let menuCampagne = menu.right.find((item) => item.id === 900000000);
    if (menuCampagne) {
      menuCampagne.titre = `${campagneActive.label}`;
    }
    return (
      <>
        <Navbar
          ref={this.navbar}
          collapseOnSelect
          bg={this.props.data.queuedRequests ? "danger" : "warning"}
          fixed="top"
          expand="lg"
        >
          {!this.isHome() && (
            <button
              id="backBtn"
              type="button"
              aria-controls="responsive-navbar-nav"
              onClick={this.onBackButtonPressed}
              className="mr-3"
            >
              <MdArrowBack size={30} />
            </button>
          )}
          <Navbar.Brand as={Link} to="/">
            <img
              alt=""
              src={logo}
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {menu.left.map((item) => (
                <Nav.Link
                  key={item.id}
                  as={Link}
                  to={item.url}
                  href="#"
                  active={this.isActive(item.url, item.id)}
                >
                  {item.titre}
                </Nav.Link>
              ))}
            </Nav>
            <Nav>
              {menu.right.map((item) => (
                <Nav.Link
                  key={item.id}
                  as={Link}
                  to={item.url}
                  href="#"
                  active={this.isActive(item.url, item.id)}
                >
                  {item.titre}
                </Nav.Link>
              ))}
              {this.props.data.queuedRequests > 0 && (
                <Nav.Link as={Link} href="#">
                  {`${this.props.data.queuedRequests} ${
                    this.props.data.queuedRequests > 1
                      ? "actions non synchro"
                      : "action non synchro"
                  }`}
                </Nav.Link>
              )}
              <Nav.Link onClick={this.deconnexion}>
                <MdPowerSettingsNew size={24} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Modal centered show={this.state.isModalOpen} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Vous êtes sur le point de quitter cette page avant d’avoir
            enregistré votre action. Êtes-vous sûr ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleModal}>
              Annuler
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                this.toggleModal();
                this.goBack();
              }}
            >
              Oui, Quitter
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(WithContext(Menu));
