import React, { useState } from "react";
// custom component
import General from "stocks/accordions/general";
import Produits from "stocks/accordions/produits";
import Details from "stocks/accordions/details";
// react-bootstrap components
import { Card, Collapse } from "react-bootstrap";
// react-icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const cardBordersFix = {
  borderBottom: "0px",
  borderTop: "1px solid rgba(0, 0, 0, 0.125)",
};

const Accordions = ({
  isUpdate,
  mouvement,
  handleChange,
  handleReactSelectChange,
  updateItem,
  updateReactSelectItem,
  history,
}) => {
  const accordionsData = [
    {
      title: "Général",
      child: <General />,
      isVisible: true,
    },
    {
      title: "Produits",
      child: <Produits />,
      isVisible: true,
    },
    {
      title: "Détails du mouvement de stock",
      child: <Details />,
      isVisible: true,
    },
  ];

  const [accordions, setAccordions] = useState(
    accordionsData.map((accordion) => (isUpdate ? true : false))
  );

  const toggle = (index) => {
    let newAccordions = [...accordions];
    newAccordions[index] = !newAccordions[index];
    setAccordions(newAccordions);
  };

  return accordionsData.map((accordion, index) => {
    const { title, child, isVisible } = accordion;
    const isOpen = accordions[index];
    const icon = isOpen ? (
      <FaChevronUp size={20} />
    ) : (
      <FaChevronDown size={20} />
    );
    return (
      isVisible && (
        <div key={index} className="w-100">
          <Card onClick={() => toggle(index)} style={cardBordersFix}>
            <Card.Header
              className="d-flex justify-content-between align-items-center text-center"
              style={{ height: "60px" }}
            >
              <div />
              <h5 className="mb-0"> {title} </h5>
              {icon}
            </Card.Header>
          </Card>
          <Collapse in={isOpen}>
            <div>
              {React.cloneElement(child, {
                isOpen: isOpen,
                isUpdate: isUpdate,
                mouvement: mouvement,
                handleChange: handleChange,
                handleReactSelectChange: handleReactSelectChange,
                updateItem: updateItem,
                updateReactSelectItem: updateReactSelectItem,
                history: history,
              })}
            </div>
          </Collapse>
        </div>
      )
    );
  });
};

export default Accordions;
