import React from "react";
// router
import { withRouter } from "react-router-dom";
// contexte global (this.props.data)
import { WithContext } from "../store/DataProvider";
// react-bootstrap components
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
// react-select component
import Select from "react-select";
// react-icons
import { RiCheckboxMultipleLine } from "react-icons/ri";
// intervention datas
import { listeStatuts } from "interventions/datas.js";
// stocks datas
import { listeTypesMouvements } from "interventions/datas";
// momentjs
import moment from "moment";

class MultiUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      selectedProperty: {},
      selectedValue: {},
      date: moment().format("YYYY-MM-DDTHH:mm"),
      rowSelectionEnabled: false,
    };
  }

  componentWillUnmount() {
    this.resetModalForm();
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  handleSelectChange = (name, option) => {
    this.setState({ [name]: option });
  };

  handleInputChange = (e) => {
    if (e.nativeEvent && e.nativeEvent.target) {
      this.setState({ date: e.nativeEvent.target.value });
    }
  };

  toggleMultiUpdate = () => {
    this.setState(
      {
        rowSelectionEnabled: !this.state.rowSelectionEnabled,
      },
      () => {
        this.props.toggleMultiUpdateFn();
      }
    );
  };

  resetModalForm = () => {
    this.setState({
      rowSelectionEnabled: false,
      selectedProperty: {},
      selectedValue: {},
      date: moment().format("YYYY-MM-DDTHH:mm"),
    });
  };

  validate = async () => {
    const { listName } = this.props;
    // deep copy pour éviter de modifier la liste originale
    let selectedItems = JSON.parse(
      JSON.stringify(this.props.getSelectedItemsFn())
    );
    if (selectedItems.length > 0) {
      const { selectedProperty, selectedValue, date } = this.state;
      selectedItems = selectedItems.map((item) => {
        const propName = selectedProperty.name;
        if (propName === "statutId") {
          item[propName] = selectedValue.value;
        } else if (propName === "date") {
          item[propName] = date;
        } else if (propName === "typeDeMouvementId") {
          item.data[propName] = selectedValue.value;
        }
        if (listName === "interventions") {
          return item;
        } else if (listName === "stocks") {
          return item.data;
        }
      });
      this.bulkUpdate(selectedItems);
    } else {
      let itemsName = "aucun élément";
      if (listName === "interventions") {
        itemsName = "aucune intervention";
      } else if (listName === "stocks") {
        itemsName = "aucun stock";
      }
      this.props.data.showNotification({
        type: "warning",
        title: `Vous n'avez sélectionné ${itemsName} à modifier.`,
      });
    }
  };

  bulkUpdate = async (selectedItems) => {
    const { listName } = this.props;
    let itemsName = "éléments";
    if (listName === "interventions") {
      itemsName = "interventions";
    } else if (listName === "stocks") {
      itemsName = "stocks";
    }
    try {
      const promises = [];
      selectedItems.forEach((item) => {
        if (listName === "interventions") {
          promises.push(this.props.data.updateItem("interventions", item));
        } else if (listName === "stocks") {
          promises.push(
            this.props.data.sendRequest("update", {
              listName: "mouvementsStocks",
              item,
            })
          );
        }
      });
      await Promise.all(promises);
      this.props.data.showNotification({
        type: "success",
        title: `${selectedItems.length} ${itemsName} ont été modifiées`,
      });
      this.toggleMultiUpdate();
      this.resetModalForm();
      if (listName === "interventions") {
        await this.props.data.sync(false, true);
      } else if (listName === "stocks") {
        this.props.history.replace(
          "parametrable?requete=ecran&ecran=stocksAppros"
        );
      }
    } catch (error) {
      this.props.data.showNotification({
        type: "danger",
        title: `Les ${itemsName} n'ont pas pu être modifié(e)s`,
      });
    }
  };

  render() {
    const { listName } = this.props;
    const {
      showModal,
      selectedProperty,
      selectedValue,
      date,
      rowSelectionEnabled,
    } = this.state;
    if (listName === "interventions" || listName === "stocks") {
      return (
        <>
          <Container fluid>
            {!rowSelectionEnabled && (
              <Row>
                <Col>
                  <Button
                    onClick={() => this.showModal()}
                    variant="warning"
                    className="float-right mt-3 ml-3"
                  >
                    Modif. groupée
                    <RiCheckboxMultipleLine size={25} className="ml-2" />
                  </Button>
                </Col>
              </Row>
            )}
            {rowSelectionEnabled && (
              <>
                <Row>
                  <Col>
                    <Button
                      onClick={() => this.validate()}
                      variant="success"
                      className="float-right mt-3 ml-3"
                    >
                      Enregistrer
                      <RiCheckboxMultipleLine size={25} className="ml-2" />
                    </Button>
                    <Button
                      onClick={() => {
                        this.toggleMultiUpdate();
                        this.resetModalForm();
                      }}
                      variant="secondary"
                      className="float-right mt-3 ml-3"
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <BulkInfoMessage listName={listName} />
                </Row>
              </>
            )}
          </Container>
          {this.props.children}
          <Modal
            centered
            show={showModal}
            onHide={() => {
              this.hideModal();
              this.resetModalForm();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Modif. groupée</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Sélectionnez la modification que vous souhaitez apporter de façon
              groupée.
              <br />
              <br />
              <Select
                isSearchable={false}
                placeholder="Modification de ..."
                getOptionValue={(option) => option.id}
                options={getOptions(listName)}
                onChange={this.handleSelectChange.bind(
                  this,
                  "selectedProperty"
                )}
                className="mb-2"
              />
              {selectedProperty.label === "Statut" && (
                <StatusSelect
                  options={
                    listName === "interventions"
                      ? listeStatuts
                      : listeTypesMouvements
                  }
                  onChange={this.handleSelectChange.bind(this, "selectedValue")}
                />
              )}
              {selectedProperty.label === "Date" && (
                <Form.Control
                  name="date"
                  type="datetime-local"
                  // value={selectedValue}
                  value={date}
                  onChange={this.handleInputChange}
                  required
                />
              )}
              <br />
              Après avoir cliqué sur "Valider", vous pourrez sélectionner les
              interventions à modifier en cliquant dessus.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.hideModal();
                  this.resetModalForm();
                }}
              >
                Annuler
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  this.hideModal();
                  this.toggleMultiUpdate();
                }}
                disabled={checkDisabled(
                  listName,
                  selectedProperty,
                  selectedValue,
                  date
                )}
              >
                Valider
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    } else {
      return this.props.children;
    }
  }
}

export default withRouter(WithContext(MultiUpdate));

const BulkInfoMessage = ({ listName }) => (
  <Col>
    <h5 className="text-success">Mode modif. groupée actif</h5>
    <h6 className="text-success">
      {listName === "interventions" &&
        `- modifier les interventions : cliquez sur les interventions à modifier,
      puis bouton Enregistrer`}
      {listName === "stocks" &&
        `- modifier les stocks : cliquez sur les stocks à modifier,
      puis bouton Enregistrer`}
    </h6>
    <h6 className="text-success">
      - sortir du mode sélection : cliquez sur Annuler
    </h6>
  </Col>
);

const getOptions = (listName) => {
  if (listName === "interventions") {
    return [
      { id: 1, label: "Statut", name: "statutId" },
      { id: 2, label: "Date", name: "date" },
    ];
  } else if (listName === "stocks") {
    return [{ id: 1, label: "Statut", name: "typeDeMouvementId" }];
  }
};

const StatusSelect = ({ options, onChange }) => (
  <Select
    isSearchable={false}
    placeholder="Valeur"
    getOptionValue={(option) => option.value}
    options={options}
    onChange={onChange}
  />
);

const checkDisabled = (listName, selectedProperty, selectedValue, date) => {
  if (listName === "interventions") {
    return (
      !selectedProperty.label ||
      (selectedProperty.label === "Statut" && !selectedValue.label) ||
      (selectedProperty.label === "Date" && !date)
    );
  } else if (listName === "stocks") {
    return (
      !selectedProperty.label ||
      (selectedProperty.label === "Statut" && !selectedValue.label)
    );
  }
};
