import React from "react";
// react-bootstrap components
import { Container, Row, Col, Alert, Button } from "react-bootstrap";

const Liens = ({ data }) => {
  const openLink = (lien) => {
    window.open(lien);
  };

  return (
    <Container fluid>
      <Row>
        {data.enfants.map((enfant) => (
          <Col key={enfant.id} lg={4} className="mt-3">
            <Alert show variant="warning">
              <Alert.Heading> {enfant.title} </Alert.Heading>
              <p>{enfant.message}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button variant="warning" onClick={() => openLink(enfant.url)}>
                  Lien vers la documentation
                </Button>
              </div>
            </Alert>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Liens;
