import React from "react";
// datas
import { WithContext } from "../store/DataProvider";
// bootstrap components
import { Container, Row, Form } from "react-bootstrap/";

class Campagnes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campagneActiveId: this.props.data.campagneActive.id,
    };
    this.modifierCampagne = this.modifierCampagne.bind(this);
  }
  modifierCampagne(event) {
    const target = event.target;
    const value = Number(target.value);
    this.props.data.update({ type: "campagne", id: value }).then((success) => {
      if (success) {
        this.props.data.showNotification({
          type: "warning",
          title:
            "Vous travaillez désormais sur la campagne " +
            this.props.data.campagneActive.label,
          autoclose: true,
        });
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <Container>
        <Row className="d-flex justify-content-center">
          <Form.Group>
            <Form.Label>Vous travaillez sur la campagne</Form.Label>
            <Form.Control
              as="select"
              defaultValue={this.state.campagneActiveId}
              onChange={this.modifierCampagne}
            >
              {this.props.data.campagnes.map((campagne) => (
                <option key={campagne.id} value={campagne.id}>
                  {campagne.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
      </Container>
    );
  }
}

export default WithContext(Campagnes);
