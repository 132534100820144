import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// parcellaire datas
import {
  getCulturesParcelles,
  getVarietesParcelles,
  getAutocompleteOptions,
} from "parcellaire/utils.js";
// react-bootstrap components
import { Card, Form } from "react-bootstrap";
// custom components
import Input from "inputs/input";

const parcelleInputs = [
  {
    type: "text",
    label: "Nom parcelle",
    name: "label",
  },
  {
    type: "number",
    label: "Surface Céré d'Oc",
    name: "surface",
    step: "0,01",
    unit: "ha",
  },
  {
    type: "react-select",
    label: "Îlot",
    name: "ilot",
    options: "ilots",
  },
  {
    type: "react-select",
    label: "Type de culture",
    name: "typeCulture",
    options: "typeCulture",
  },
  {
    type: "react-select",
    label: "Destination",
    name: "destination",
    options: "destination",
  },
  {
    type: "react-select",
    label: "Culture",
    name: "culture",
    options: [],
  },
  {
    type: "react-select",
    label: "Variété",
    name: "varietes",
    options: [],
    // disabledProp: "culture",
  },
  {
    type: "react-select",
    label: "Précédent",
    name: "culturePrecedente",
    options: [],
  },
  {
    type: "number",
    label: "Rendement prévisionnel",
    name: "rendement",
    step: "0,01",
    unit: "T/ha",
  },
  {
    type: "react-select",
    label: "Type de sol",
    name: "typeDeSol",
    options: "typeDeSol",
  },
  {
    type: "radio",
    label: "Zone vulnérable",
    name: "zoneVulnerable",
    options: [
      { id: 1, label: "Oui" },
      { id: 2, label: "Non" },
    ],
  },
];

const caracteristiques = ({
  data,
  parcelle,
  cultureId,
  handleChange,
  handleReactSelectChange,
}) => {
  return (
    <Card.Body>
      <Form>
        {parcelleInputs.map((input, index) => {
          let options = data[input.options]
            ? data[input.options]
            : input.options;
          // pour les cultures
          if (input.name === "culture" || input.name === "culturePrecedente") {
            options = getCulturesParcelles(
              { produits: data.produits, culturepac: data.culturepac },
              "cultures"
            );
            // pour les variétés
          } else if (input.name === "varietes") {
            options = getVarietesParcelles(data.produits, cultureId);
          }
          let isDisabled = false;
          const disabledProp = parcelle.properties[input.disabledProp];
          if (disabledProp && !disabledProp.length) {
            isDisabled = true;
          }
          return (
            <Input
              key={index}
              input={input}
              value={parcelle.properties[input.name]}
              options={options}
              isDisabled={isDisabled}
              handleChange={handleChange}
              handleReactSelectChange={handleReactSelectChange}
              loadOptions={getAutocompleteOptions}
            />
          );
        })}
      </Form>
    </Card.Body>
  );
};

export default WithContext(caracteristiques);
