import React, { useState, useEffect, useContext } from "react";
// API HTTP Client
// import { API } from "api/api";
// global context
import { DataContext } from "store/DataProvider";
// custom components
import { FormMouvementStock } from "stocks/forms.js";
// react-bootstrap components
import {
  Card,
  // ButtonToolbar,
  // ToggleButtonGroup,
  // ToggleButton,
  Form,
} from "react-bootstrap";
// react-select component
import Select from "react-select";
import { formatGroupLabel, colourStyles } from "styles/react-select";
// intervention datas
import { getCategories, getCategorie } from "interventions/datas.js";

const Produits = ({
  mouvement,
  handleReactSelectChange,
  updateItem,
  updateReactSelectItem,
}) => {
  // liste de tous les produits
  const [produits, setProduits] = useState([]);
  // catégorie sélectionnée par défaut = toutes (id = -1)
  const [categorieId, setCategorieId] = useState(-1);
  // liste des catégories de produits
  const [categories, setCategories] = useState([]);
  // liste des produits correspondants à la catégorie sélectionnée
  const [categorie, setCategorie] = useState([]);
  // fonction permettant de modifier la catégorie
  const updateCategory = (option) => {
    setCategorieId(option.id);
    setCategorie([option]);
  };
  // liste des produits correspondant à la catégorie traitement de semence
  const [produitsSemence, setProduitsSemence] = useState([]);
  // contexte global
  const { sendRequest } = useContext(DataContext);
  // fonction executée au montage du composant
  useEffect(() => {
    // récupérer les produits de l'utilisateur
    async function fetchProduits() {
      const response = await sendRequest("recupererStocks");
      setProduits(response);
      const cats = getCategories(response, undefined);
      setCategories(cats);
      setCategorie(cats[0]);
      setProduitsSemence(response.find((c) => c.id === 6));
    }
    fetchProduits();
  }, [sendRequest]);

  // const [vueProduits, setVueProduits] = useState(1);
  // const handleRadioChange = (value) => {
  //   setVueProduits(value);
  // };

  // const loadOptions = async (inputValue) => {
  //   if (inputValue.length >= 3) {
  //     try {
  //       let data = await API("autocomplete", {
  //         listName: "produits",
  //         // typeId,
  //         undefined,
  //         inputValue,
  //       });
  //       // rajouter les propriétés
  //       data.forEach((option) => {
  //         // tous les produits ont un champ quantité
  //         option.quantite = "";
  //         // tous les produits qui n'appartiennent pas à la catégorie "traitement de semence" ont un champ quantité surfacique
  //         if (option.idprg !== 6) {
  //           option.quantitePerSurface = "";
  //         }
  //         // les produits de type variété ont un champ "type de semence" et "pmg"
  //         if (option.variete) {
  //           option.typeSemence = 1;
  //           option.pmg = "";
  //         }
  //       });
  //       return data;
  //     } catch (error) {
  //       console.error(error);
  //       return [];
  //     }
  //   } else {
  //     return [];
  //   }
  // };
  return (
    <Card.Body>
      {/* <ButtonToolbar className="mb-2">
        <ToggleButtonGroup
          type="radio"
          name="vueProduits"
          defaultValue={vueProduits}
          onChange={handleRadioChange}
        >
          <ToggleButton value={1} variant="light">
            Ma liste
          </ToggleButton>
          <ToggleButton value={2} variant="light">
            Catalogue
          </ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar> */}
      {/* {vueProduits === 1 && ( */}
      <div>
        <Select
          isSearchable={false}
          getOptionValue={(option) => option.id}
          options={categories}
          value={categorie}
          onChange={updateCategory}
          className="mb-2"
          styles={colourStyles}
        />
        <Select
          isMulti
          placeholder="Saisir un produit de ma liste"
          getOptionValue={(option) => option.id}
          options={getCategorie(produits, undefined, categorieId)}
          formatGroupLabel={formatGroupLabel}
          value={mouvement.produits}
          onChange={handleReactSelectChange.bind(this, "produits")}
          className="mb-2"
          styles={colourStyles}
        />
        {mouvement.produits.length > 0 && (
          <h6 className="mb-4 text-success">
            Vous pouvez ajouter d'autres produits grâce au champ ci-dessus
          </h6>
        )}
      </div>
      <Form>
        <FormMouvementStock
          selectedOptions={mouvement.produits}
          produitsSemence={produitsSemence}
          updateItem={updateItem}
          updateReactSelectItem={updateReactSelectItem}
        />
      </Form>
    </Card.Body>
  );
};

export default Produits;
