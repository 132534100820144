import React from "react";
// generic component
import Generique from "generique/component";

const Wrapper = ({ data }) => {
  return (
    <>
      {data.map((config) => {
        return config.enfants.map((enfant, i) => (
          <Generique key={i} data={enfant} />
        ));
      })}
    </>
  );
};

export default Wrapper;
