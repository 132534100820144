import React from "react";
// datas
import { WithContext } from "../store/DataProvider";
// bootstrap components
import { Container, Row, Form } from "react-bootstrap/";

class Exploitations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exploitationActiveId: this.props.data.exploitationActive.id,
    };
    this.modifierExploitation = this.modifierExploitation.bind(this);
  }
  modifierExploitation(event) {
    const target = event.target;
    const value = Number(target.value);
    this.props.data
      .update({ type: "exploitation", id: value })
      .then((success) => {
        if (success) {
          this.props.data.showNotification({
            type: "warning",
            title:
              "Vous travaillez désormais sur l'exploitation " +
              this.props.data.exploitationActive.label,
            autoclose: true,
          });
          this.props.history.goBack();
        }
      });
  }

  render() {
    return (
      <Container>
        <Row className="d-flex justify-content-center">
          <Form.Group>
            <Form.Label>Vous travaillez sur l'exploitation</Form.Label>
            <Form.Control
              as="select"
              defaultValue={this.state.exploitationActiveId}
              onChange={this.modifierExploitation}
            >
              {this.props.data.exploitations.map((exploitation) => (
                <option key={exploitation.id} value={exploitation.id}>
                  {exploitation.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
      </Container>
    );
  }
}

export default WithContext(Exploitations);
