import React, { useState } from "react";
// router
import { withRouter } from "react-router-dom";
// generic component
import Generique from "generique/component";
// react-bootstrap components
import { Tab, Tabs, Button } from "react-bootstrap";

const Onglets = ({ data, history }) => {
  const [activeKey, setActiveKey] = useState(0);
  const buttonClick = (bouton) => {
    // TODO : parametres location state du ppf
    history.push(bouton.url, {});
  };
  return (
    <Tabs activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
      {data.map((onglet, i1) => (
        <Tab
          key={i1}
          className="ongletsParametrable"
          eventKey={i1}
          title={onglet.titre}
        >
          {onglet.boutons && (
            <div className="pt-3 pl-3 pr-3 d-flex justify-content-end">
              {onglet.boutons.map((bouton, i2) => (
                <Button
                  key={i2}
                  variant="warning"
                  onClick={() => buttonClick(bouton)}
                >
                  {bouton.titre}
                </Button>
              ))}
            </div>
          )}
          {onglet.enfants.map((enfant, i2) => (
            <Generique key={i2} data={enfant} />
          ))}
        </Tab>
      ))}
    </Tabs>
  );
};

export default withRouter(Onglets);
