import React from "react";
// router
import { withRouter } from "react-router-dom";
// global context (this.props.data)
import { WithContext } from "../store/DataProvider";
// react-bootstrap components
import { Container, Row, Col, Alert, Button } from "react-bootstrap";

class Telepac extends React.Component {
  async upload(type, e) {
    if (e.target.files.length) {
      let formData = new FormData();
      formData.append("telepac", e.target.files[0]);
      formData.append("id_utilisateur", this.props.data.utilisateur.id);
      formData.append("id_campagne", this.props.data.campagneActive.id);
      formData.append("id_exploitation", this.props.data.exploitationActive.id);
      const isSuccess = await this.props.data.sendRequest("upload", {
        type,
        formData,
      });
      if (isSuccess) {
        this.props.data.showNotification({
          type: "success",
          title: "Votre dossier Telepac a bien été importé.",
        });
      } else {
        this.props.data.showNotification({
          type: "danger",
          title: "Votre dossier Telepac n'a pas pu être importé.",
        });
      }
    }
  }

  async initialisation() {
    const isSuccess = await this.props.data.sendRequest("init");
    if (isSuccess) {
      this.props.data.showNotification({
        type: "success",
        title: "Votre dossier Telepac a bien été initialisé.",
      });
      await this.props.data.sync(false);
      this.props.history.replace("/parcellaire", { tab: "parcelles" });
    } else {
      this.props.data.showNotification({
        type: "danger",
        title: "Votre dossier Telepac n'a pas pu être initialisé.",
      });
    }
  }

  render() {
    return (
      <Container>
        <Row className="mt-3">
          {this.props.data.utilisateur.initialisation && (
            <Col md={6}>
              <Alert show={true} variant="success">
                <Alert.Heading>Initialisation dossier TELEPAC</Alert.Heading>
                <p>
                  Créez vos parcelles Céré d'Oc grâce à la déclaration TELEPAC.
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    variant="success"
                    onClick={() => this.initialisation()}
                  >
                    Initialiser mon dossier TELEPAC
                  </Button>
                </div>
              </Alert>
            </Col>
          )}
          <Col md={6}>
            <Alert show={true} variant="warning">
              <Alert.Heading>Import dossier TELEPAC</Alert.Heading>
              <p>
                A effectuer à la création du compte, puis tous les ans après
                chaque déclaration PAC.
              </p>
              <hr />
              <div className="d-flex justify-content-end">
                <label className="btn btn-warning">
                  Importer mon dossier TELEPAC
                  <input
                    type="file"
                    hidden
                    onChange={this.upload.bind(this, "import")}
                  />
                </label>
              </div>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(WithContext(Telepac));
