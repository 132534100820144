import React, { useContext, useRef, useEffect } from "react";
// global context
import { DataContext } from "store/DataProvider";
// react-bootstrap components
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap";
// leaflet Map
import MapDraw from "../map/mapDraw.js";
// PDF modules
import { jsPDF } from "jspdf";
import PDFHelper from "pdf/helper";
// react-icons
import { FaFilePdf } from "react-icons/fa";
// chart components
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
// couleurs des cultures
import { couleursCultures } from "parcellaire/couleurs.js";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Cartographie = () => {
  const { utilisateur, parcelles, cultures, surfaces, campagneActive } =
    useContext(DataContext);
  const map = useRef();

  // quand le composant est monté / updaté
  useEffect(() => {
    // forcer la map à s'afficher (sinon ne s'affiche pas correctement)
    if (map.current && map.current.updateUI) {
      map.current.updateUI();
    }
  });

  const culturesTriees = cultures.sort((a, b) => {
    if (a.surfaceTotale > b.surfaceTotale) return -1;
    if (b.surfaceTotale > a.surfaceTotale) return 1;
    return 0;
  });

  const createPDF = async () => {
    if (map.current && map.current.getScreenshot) {
      try {
        // map image
        const mapImage = await map.current.getScreenshot();
        const mapImageDimensions = PDFHelper.getImageDimensions({
          image: mapImage,
        });
        // legende map image
        const mapLegendeImage = await PDFHelper.htmlToImage(
          map.current.getLegendDom()
        );
        let mapLegendeDimensions = PDFHelper.getImageDimensions({
          image: mapLegendeImage,
          orientation: mapImageDimensions.orientation,
          maxWidth: mapImageDimensions.width,
        });
        // nouveau pdf doc
        let pdf = new jsPDF(mapImageDimensions.orientation, "pt", "a4");
        let pdfWidth = pdf.internal.pageSize.getWidth();
        // header
        PDFHelper.setHeader({ doc: pdf, utilisateur, campagneActive });
        // map image title
        pdf.text(
          `Cartographie Assolement ${campagneActive.label}`,
          mapImageDimensions.x + 75,
          mapImageDimensions.y + 75 - 10
        );
        // map image
        pdf.addImage(
          mapImage,
          "PNG",
          mapImageDimensions.x + 75,
          mapImageDimensions.y + 75,
          mapImageDimensions.width,
          mapImageDimensions.height,
          null,
          "NONE"
        );
        // définir la position en y de la legende juste en dessous de la carte
        mapLegendeDimensions.y =
          mapImageDimensions.y +
          75 +
          mapImageDimensions.height -
          mapLegendeDimensions.height;
        // legende map image
        pdf.addImage(
          mapLegendeImage,
          "PNG",
          mapImageDimensions.x + 75,
          mapLegendeDimensions.y,
          mapLegendeDimensions.width,
          mapLegendeDimensions.height
        );
        // footer
        PDFHelper.setFooter({
          doc: pdf,
          pagination: "Page 1 / 2",
        });
        // new pdf page
        pdf.addPage("a4", "landscape");
        pdfWidth = pdf.internal.pageSize.getWidth();
        // header
        PDFHelper.setHeader({ doc: pdf, utilisateur, campagneActive });
        // surfaces cultures
        pdf.text("Détail surfaces", 70, 120 - 10);
        pdf.table(
          70,
          120,
          culturesTriees.map((o) => ({
            culture: o.label,
            surface: `${o.surfaceTotale.toString()} ha`,
            pourcentage: `${Number(
              (o.surfaceTotale / surfaces.totale) * 100
            ).toFixed(0)} %`,
          })),
          PDFHelper.createTableHeaders(["culture", "surface", "pourcentage"]),
          { autoSize: true }
        );
        // surfaces types de culture
        pdf.table(
          70,
          120 + (culturesTriees.length + 2) * 20,
          [
            {
              désignation: "surfaces principales",
              surface: `${surfaces.culturesPrincipales} ha`,
              pourcentage: `${Number(
                (surfaces.culturesPrincipales / surfaces.totale) * 100
              ).toFixed(0)} %`,
            },
            {
              désignation: "surfaces dérobées",
              surface: `${surfaces.culturesDerobees} ha`,
              pourcentage: `${Number(
                (surfaces.culturesDerobees / surfaces.totale) * 100
              ).toFixed(0)} %`,
            },
            {
              désignation: "surface totale",
              surface: `${surfaces.totale} ha`,
              pourcentage: "100 %",
            },
          ],
          PDFHelper.createTableHeaders([
            "désignation",
            "surface",
            "pourcentage",
          ]),
          { autoSize: true }
        );
        // graphique
        const graphiqueImage = await PDFHelper.htmlToImage(
          document.querySelector("#graphique")
        );
        const graphiqueImageDimensions = PDFHelper.getImageDimensions({
          image: graphiqueImage,
          orientation: "landscape",
          maxWidth: pdfWidth / 2,
        });
        pdf.addImage(
          graphiqueImage,
          pdfWidth / 2 + graphiqueImageDimensions.x,
          graphiqueImageDimensions.y + 75,
          graphiqueImageDimensions.width,
          graphiqueImageDimensions.height,
          null,
          "SLOW"
        );
        // footer
        PDFHelper.setFooter({
          doc: pdf,
          pagination: "Page 2 / 2",
        });
        pdf.save(`Assolement ${campagneActive.label}.pdf`);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getCouleurCulture = (cultureId) => {
    const correspondance = couleursCultures.find((item) =>
      item.culturesIds.includes(cultureId)
    );
    if (correspondance) {
      return correspondance.couleur;
    } else {
      return "#B0BEC5";
    }
  };

  const pieChartData = {
    labels: culturesTriees.map((c) => c.label),
    datasets: [
      {
        label: "Graphique assolement",
        data: culturesTriees.map((c) => c.surfaceTotale),
        backgroundColor: culturesTriees.map((c) => getCouleurCulture(c.id)),
        borderColor: "white",
      },
    ],
  };

  return (
    <Container fluid>
      <Row>
        <Col md={8} className="mt-3">
          <MapDraw
            ref={map}
            parcelles={parcelles}
            isLegende
            dragging={false}
            scrollWheelZoom={false}
            doubleClickZoom={false}
          />
        </Col>
        <Col md={4} className="mt-3">
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => createPDF()}
              variant="danger"
              className="mb-3"
            >
              Export PDF
              <FaFilePdf size={20} className="ml-2" />
            </Button>
          </div>
          <div id="cultures">
            <ListGroup.Item>
              <h4>Cultures ({cultures.length})</h4>
            </ListGroup.Item>
            <ListGroup>
              {cultures.map((culture) => (
                <ListGroup.Item key={culture.id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0">{culture.label.toLowerCase()}</h6>
                    <h6 className="mb-0">{culture.surfaceTotale}ha</h6>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
          <div id="graphique" className="mt-5">
            <Pie
              // height={window.innerHeight - 400}
              data={pieChartData}
              options={{ maintainAspectRatio: true }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Cartographie;
