// import React, { useRef, useEffect, useState } from "react";
import React, { useRef, useEffect } from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// react-bootstrap components
import {
  Row,
  Card,
  ListGroup,
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  // Form,
} from "react-bootstrap";
// custom components
// import Input from "inputs/input";
import MapDraw from "map/mapDraw";

// const dessinInputs = [
//   {
//     type: "react-select",
//     label: "Affecter un dessin",
//     name: "telepac",
//     options: "telepac",
//     colors: true,
//   },
// ];

const Contour = ({
  isOpen,
  isUpdate,
  // data,
  parcelle,
  surfaceTelepac,
  surfaceDessin,
  // handleReactSelectChange,
  setGeometry,
  setSurface,
  history,
}) => {
  const map = useRef();
  // const [vue, setVue] = useState(1);
  const isSurfaceTelepacSelected =
    parcelle.properties.surface === surfaceTelepac;
  const isSurfaceDessinSelected = parcelle.properties.surface === surfaceDessin;

  useEffect(() => {
    if (isOpen) {
      map.current && map.current.updateUI();
    }
  }, [isOpen]);

  const handleButtonChange = (value) => {
    if (value === 2) {
      history.push("/outilsDessin");
    }
  };

  return (
    <>
      <Row noGutters>
        <ListGroup className="w-100">
          <ListGroup.Item variant="success">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                {/* {surfaceTelepac ? ( */}
                <Button
                  variant={isSurfaceTelepacSelected ? "success" : "light"}
                  onClick={() => setSurface(surfaceTelepac)}
                >
                  {surfaceTelepac
                    ? `Affecter la surface telepac ${surfaceTelepac} ha`
                    : `Surface admissible telepac : ${surfaceTelepac} ha`}
                </Button>
                {/* ) 
                : (
                  <h6>{`Surface admissible Telepac : ${surfaceTelepac} ha`}</h6>
                )} */}
                {/* {surfaceDessin ? ( */}
                <Button
                  variant={isSurfaceDessinSelected ? "success" : "light"}
                  onClick={() => setSurface(surfaceDessin)}
                >
                  {surfaceDessin
                    ? `Affecter la surface du dessin ${surfaceDessin} ha`
                    : `Surface du dessin : ${surfaceDessin} ha`}
                </Button>
                {/* ) 
                : (
                  <h6>{`Surface dessin : ${surfaceDessin} ha`}</h6>
                )} */}
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Row>
      <Card.Body>
        <ButtonToolbar className="mb-3">
          <ToggleButtonGroup
            type="radio"
            name="vue"
            defaultValue={1}
            onChange={(value) => handleButtonChange(value)}
          >
            <ToggleButton value={1} variant="light">
              Dessiner le contour
            </ToggleButton>
            {isUpdate && (
              <ToggleButton value={2} variant="light">
                Fusionner ou découper la parcelle
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </ButtonToolbar>
        {/* Affecter un dessin : plus utilisé */}
        {/* {vue === 0 && (
          <Form>
            {dessinInputs.map((input, index) => {
              // TODO : remplacer options par this.props.data[input.options] (liste telepac)
              let options = [
                {
                  id: 1,
                  ilot: { label: "ilot 1" },
                  parcelle: { label: "parcelle 1" },
                  origine: "n",
                  surface: 2.4,
                },
                {
                  id: 2,
                  ilot: { label: "ilot 1" },
                  parcelle: { label: "parcelle 2" },
                  origine: "n",
                  surface: 3.6,
                },
                {
                  id: 7,
                  ilot: { label: "ilot 2" },
                  parcelle: { label: "parcelle 4" },
                  origine: "n-1",
                  surface: 4.8,
                },
                {
                  id: 23,
                  ilot: { label: "ilot 5" },
                  parcelle: { label: "parcelle 8" },
                  origine: "ancien",
                  surface: 5.2,
                },
              ].map((option) => {
                let color;
                if (option.origine === "n") {
                  color = "green";
                } else if (option.origine === "n-1") {
                  color = "orange";
                } else {
                  color = "gray";
                }
                return {
                  ...option,
                  label: `${option.ilot.label} ${option.parcelle.label} (${option.surface} ha) - ${option.origine}`,
                  color: color,
                };
              });
              return (
                <Input
                  key={index}
                  input={input}
                  value={parcelle.properties[input.name]}
                  // TODO : remplacer options par this.props.data[input.options] (liste telepac) //
                  // options={data[input.options]} //
                  options={options}
                  handleReactSelectChange={handleReactSelectChange}
                />
              );
            })}
          </Form>
        )} */}
        <MapDraw
          ref={map}
          /* draws={["polygon", "circle"]} */
          draws={["polygon"]}
          isParcelleEditable
          parcelle={[parcelle]}
          bounds={[parcelle]}
          culture={parcelle.properties.culture}
          setGeometry={setGeometry}
        />
      </Card.Body>
    </>
  );
};

export default WithContext(Contour);
