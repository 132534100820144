import React, { forwardRef, useImperativeHandle } from "react";
// custom components
import MiniMap from "../map/miniMap.js";
// react-bootstrap components
import { ListGroup, Button, Badge } from "react-bootstrap";
import { getVarieteLabel } from "utils/parcelle.js";

const Parcelle2 = forwardRef(
  (
    {
      list,
      isEditable,
      isActive,
      toggleAll,
      isTabActive,
      handleClick,
      isDeleteMode,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      getList() {
        return JSON.parse(JSON.stringify(list));
      },
    }));

    const getVariant = (isActive) => {
      if (isActive) {
        if (isDeleteMode) {
          return "danger";
        } else {
          return "success";
        }
      }
      return undefined;
    };

    return (
      <>
        {isEditable && (
          <Button
            variant={!isActive ? "success" : "danger"}
            className="m-3"
            onClick={isEditable ? toggleAll : undefined}
          >
            {!isActive
              ? "Sélectionner toute la liste"
              : "Désélectionner toute la liste"}
          </Button>
        )}
        {list.map((parcelle, index) => {
          const parcelleProps = parcelle.properties;
          const isActive = parcelleProps.isActive;
          // TODO : récupérer telepac par origine ?
          const telepac = parcelleProps.telepac[0];
          const typeCulture = parcelleProps.typeCulture[0] || {};
          const culture = parcelleProps.culture[0] || {};
          const culturePrecedente = parcelleProps.culturePrecedente[0];
          const destination = parcelleProps.destination[0];
          const rendement = parcelleProps.rendement;
          const prixDeVente = parcelleProps.prixDeVente;
          const isGeometry = parcelle.geometry && parcelle.geometry.coordinates;
          return (
            <ListGroup.Item
              key={parcelleProps.id}
              data-id={parcelleProps.id}
              as="li"
              action
              variant={getVariant(isActive)}
              onClick={() =>
                handleClick ? handleClick(parcelleProps.id) : undefined
              }
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row flex-wrap">
                    <h6 className="mr-1">
                      {`${parcelleProps.label} - ${parcelleProps.surface}ha`}
                    </h6>
                    {telepac && (
                      <h6>{`| ${telepac.ilot} - ${telepac.parcelle}`}</h6>
                    )}
                  </div>
                  <div className="d-flex flex-row flex-wrap">
                    <h6 className="mb-0 mr-1">
                      <Badge variant="warning">{`${
                        culture.label ? culture.label : ""
                      } ${
                        typeCulture.label ? `(${typeCulture.label})` : ""
                      }`}</Badge>
                    </h6>
                    {parcelleProps.varietes.length > 0 && (
                      <h6 className="mb-0 mr-1">
                        <Badge pill key={index} variant="info">
                          {getVarieteLabel(parcelleProps.varietes)}
                        </Badge>
                      </h6>
                    )}
                    {culturePrecedente && (
                      <h6 className="mb-0 mr-1">
                        <Badge variant="success">
                          {`préc. ${culturePrecedente.label}`}
                        </Badge>
                      </h6>
                    )}
                    {destination && (
                      <h6 className="mb-0 mr-1">
                        <Badge variant="light">{destination.label}</Badge>
                      </h6>
                    )}
                    {rendement > 0 && (
                      <h6 className="mb-0 mr-1">
                        <Badge variant="light">{`Rdt prév ${rendement} t/ha`}</Badge>
                      </h6>
                    )}
                    {prixDeVente > 0 && (
                      <h6 className="mb-0 mr-1">
                        <Badge variant="light">{`Prix prév ${prixDeVente} €/t`}</Badge>
                      </h6>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-nowrap">
                  {isGeometry && (
                    <MiniMap isTabActive={isTabActive} parcelles={[parcelle]} />
                  )}
                </div>
              </div>
            </ListGroup.Item>
          );
        })}
      </>
    );
  }
);

export default Parcelle2;
