import React from "react";
// API HTTP Client
import { API } from "../api/api";
// loading indicator component
import LoadingIndicator from "loadingIndicator/component";

class ApiManager extends React.Component {
  constructor() {
    super();
    this.state = { isLoading: false };
  }

  async sendRequest(name, ...params) {
    this.setState({ isLoading: true });
    const response = await API(name, ...params);
    this.setState({ isLoading: false });
    return response;
  }

  render() {
    return (
      <>
        <LoadingIndicator isLoading={this.state.isLoading} />
        {this.props.children}
      </>
    );
  }
}

export default ApiManager;
