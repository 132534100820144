import React, { useContext } from "react";
// global context
import { DataContext } from "store/DataProvider";
// react-bootstrap components
import { Container, Jumbotron } from "react-bootstrap";

const FicheExploitation = () => {
  const { utilisateur } = useContext(DataContext);
  return (
    <Container className="mt-3">
      <Jumbotron>
        <h1>{`${utilisateur.nom} ${utilisateur.prenom}`}</h1>
        <h4>{`CETA ${utilisateur.ceta}`}</h4>
        <h4>{`${utilisateur.niveau} épis`}</h4>
        <h4>Céré d'Oc {`${process.env.REACT_APP_VERSION}`}</h4>
      </Jumbotron>
    </Container>
  );
};

export default FicheExploitation;
