// PDF modules
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// momentjs
import moment from "moment";
// brand logo
import logo from "../logo.png";
// js pdf instance utilisée uniquement pour la fonction getImageProperties
const pdf = new jsPDF("landscape", "pt", "a4");

const pdfFunctions = {
  getImageDimensions: ({ image, orientation, maxWidth }) => {
    const imageProps = pdf.getImageProperties(image);
    if (orientation === "landscape") {
      return getLandscapeDimensions({ imageProps, maxWidth });
    } else if (orientation === "portrait") {
      return getPortraitDimensions({ imageProps, maxWidth });
    } else {
      if (imageProps.width >= imageProps.height) {
        return getLandscapeDimensions({ imageProps, maxWidth });
      } else {
        return getPortraitDimensions({ imageProps, maxWidth });
      }
    }
  },
  htmlToImage: async (html) => {
    const canvas = await html2canvas(html);
    return canvas.toDataURL("image/png");
  },
  setHeader: ({ doc, utilisateur, campagneActive }) => {
    const pdfWidth = doc.internal.pageSize.getWidth();
    const isLandscape = pdfWidth === 841.89;
    doc.setDrawColor(0);
    doc.setFillColor(255, 193, 7);
    doc.rect(10, 10, pdfWidth - 25, 30, "FD");
    doc.setFont("times", "bold");
    doc.setFontSize(16);
    doc.setTextColor("black");
    doc.text(utilisateur.nom, 20, 30);
    doc.text("ASSOLEMENT", isLandscape ? 370 : 220, 30);
    doc.text(`Campagne : ${campagneActive.label}`, pdfWidth - 185, 30);
  },
  setFooter: ({ doc, pagination }) => {
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();
    const isLandscape = pdfWidth === 841.89;
    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.setTextColor("gray");
    doc.text(moment().format("DD-MM-YYYY"), 20, pdfHeight - 20);
    doc.addImage(
      logo,
      isLandscape ? 370 : 250,
      pdfHeight - 40,
      104,
      24,
      null,
      "SLOW"
    );
    doc.text(pagination, pdfWidth - 80, pdfHeight - 20);
  },
  createTableHeaders: function (keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: "center",
        padding: 0,
      });
    }
    return result;
  },
};

export default pdfFunctions;

const getLandscapeDimensions = ({ imageProps, maxWidth }) => {
  const pdfWidth = maxWidth || 841.89 - 150;
  const pdfHeight = 595.28 - 150;
  let imageHeight = (imageProps.height * pdfWidth) / imageProps.width;
  let imageWidth = pdfWidth;
  if (imageHeight > pdfHeight) {
    imageHeight = pdfHeight;
    imageWidth = (imageProps.width * pdfHeight) / imageProps.height;
  }
  return {
    orientation: "landscape",
    width: imageWidth,
    height: imageHeight,
    x: (pdfWidth - imageWidth) / 2,
    y: (pdfHeight - imageHeight) / 2,
  };
};

const getPortraitDimensions = ({ imageProps, maxWidth }) => {
  const pdfWidth = maxWidth || 595.28 - 150;
  const pdfHeight = 841.89 - 150;
  let imageWidth = (imageProps.width * pdfHeight) / imageProps.height;
  let imageHeight = pdfHeight;
  if (imageWidth > pdfWidth) {
    imageWidth = pdfWidth;
    imageHeight = (imageProps.height * pdfWidth) / imageProps.width;
  }
  return {
    orientation: "portrait",
    width: imageWidth,
    height: imageHeight,
    x: (pdfWidth - imageWidth) / 2,
    y: (pdfHeight - imageHeight) / 2,
  };
};
