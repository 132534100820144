import React, { useContext } from "react";
// global context
import { DataContext } from "store/DataProvider";
// create intervention context
import { CreateContext } from "interventions/createProvider";
// react-bootstrap components
import { Card, Form } from "react-bootstrap";
// intervention helper
import { Helper } from "interventions/helpers.js";

const Details = ({ isEditable }) => {
  const { typeId, intervention, handleInputChange } = useContext(CreateContext);
  let { typesOperation } = useContext(DataContext);
  typesOperation = typesOperation[typeId];
  const isLibelle = Helper.isLibelle(typeId);
  const isIrrigation = Helper.isIrrigation(typeId);
  const isTraitementPhyto = Helper.isTraitementPhyto(typeId);
  const isTypeOperation = Helper.isTypeOperation(typeId);
  const isSemis = Helper.isSemis(typeId);
  const { observation, quantiteEau, volumeBouillie, typeOperationId, semisDe } =
    intervention;
  return (
    <Card.Body>
      <Form>
        <Form.Group>
          <Form.Label>{isLibelle ? "Libellé" : "Observation"}</Form.Label>
          <Form.Control
            name="observation"
            value={observation}
            as="textarea"
            rows="3"
            onChange={handleInputChange}
            disabled={!isEditable}
          />
        </Form.Group>
        {isIrrigation && (
          <Form.Group>
            <Form.Label>Quantité d'eau (en mm)</Form.Label>
            <Form.Control
              name="quantiteEau"
              type="number"
              value={quantiteEau}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </Form.Group>
        )}
        {isTraitementPhyto && (
          <Form.Group>
            <Form.Label>Volume de bouillie (L/ha)</Form.Label>
            <Form.Control
              name="volumeBouillie"
              type="number"
              value={volumeBouillie}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </Form.Group>
        )}
        {isTypeOperation && (
          <Form.Group>
            <Form.Label>Type d'opération</Form.Label>
            <Form.Control
              name="typeOperationId"
              as="select"
              value={typeOperationId}
              onChange={handleInputChange}
              disabled={!isEditable}
            >
              <option key={0} value="">
                Sélectionnez un type d'opération
              </option>
              {(typesOperation || []).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
        {isSemis && (
          <>
            <Form.Label as="legend" className="mr-2">
              Semis de
            </Form.Label>
            <Form.Check
              inline
              type="radio"
              label="culture"
              name="semisDe"
              value={1}
              checked={semisDe === 1}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
            <Form.Check
              inline
              type="radio"
              label="couvert végétal"
              name="semisDe"
              value={2}
              checked={semisDe === 2}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </>
        )}
      </Form>
    </Card.Body>
  );
};

export default Details;
