import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// parcelles actives context (this.props.ctx)
import { WithParcellesActives } from "parcelles/provider";
// react-bootstrap components
import { Container, Row, Alert, Col, Button, Modal } from "react-bootstrap";
// custom component
import Accordions from "directiveNitrate/accordions/component";
// react-icons
import { MdEdit, MdDelete } from "react-icons/md";

class CreateSaisie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accordionsData: [],
      formData: {},
      isModalOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.displayMethodes = this.displayMethodes.bind(this);
    this.displayDonneesPPF = this.displayDonneesPPF.bind(this);
    this.getFormDataValues = this.getFormDataValues.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.idppf = this.props.history.location.state.idppf;
  }

  componentDidMount() {
    this.displayRegions();
  }

  // fonction qui récupère les régions et les méthodes
  async displayRegions() {
    const response = await this.props.data.sendRequest("getRegionsPpf");
    if (response) {
      this.setState({
        accordionsData: [
          {
            title: "Choix de la méthode",
            inputs: [
              {
                type: "react-select",
                label: "Région",
                name: "region",
                options: response,
                required: true,
              },
            ],
          },
        ],
      });
      // si ppf existant
      if (this.idppf) {
        // récupérer la région du ppf existant
        const region = response.find(
          (region) => region.id === this.props.history.location.state.idreg
        );
        // setter cette region dans le state
        if (region && region.id) {
          this.setState((state) => {
            state.formData.region = [region];
            return state;
          });
          // désactiver la saisie du champ
          this.disableInput(0);
          // afficher ensuite le champ methode
          this.displayMethodes(region.methodes);
        }
      }
    }
  }

  handleChange(e) {
    const target = e.target;
    const type = target.type;
    let value = target.value;
    const name = target.name;
    // pour pouvoir accéder au nativeEvent
    if (type === "select-one") e.persist();

    this.setState((state) => {
      let newValue = value;
      if (type === "select-one") {
        if (value) {
          const nativeTarget = e.nativeEvent.target;
          const index = nativeTarget.selectedIndex;
          const label = nativeTarget[index].text;
          // ajouter l'id et le label de l'option sélectionnée
          newValue = [{ id: Number(value), label: label }];
        } else {
          newValue = [];
        }
      } else if (type === "number" && value !== "") {
        // tronquer au nombre de décimales souhaité
        const decimals = target.getAttribute("decimals");
        newValue = +Number(target.value).toFixed(decimals);
      } else if (type === "radio") {
        newValue = Number(value);
      }
      state.formData[name] = newValue;
      return state;
    });
  }

  handleReactSelectChange(name, option) {
    this.setState((state) => {
      state.formData[name] = [option];
      return state;
    });
    if (name === "region" || name === "methode") {
      let inputIndex;
      if (name === "region") {
        inputIndex = 0;
        this.displayMethodes(option.methodes);
      } else if (name === "methode") {
        inputIndex = 1;
        this.displayDonneesPPF(option.id);
      }
      // désactiver la saisie du champ concerné
      this.disableInput(inputIndex);
    }
  }

  disableInput(inputIndex) {
    // désactiver la saisie du champ concerné
    this.setState((state) => {
      state.accordionsData[0].inputs[inputIndex].isDisabled = true;
      return state;
    });
  }

  displayMethodes(methodes) {
    // champ des méthodes
    const methodeInput = {
      type: "react-select",
      label: "Méthode / Culture",
      name: "methode",
      options: methodes,
      required: true,
    };
    // afficher le champ en le rajoutant dans le state
    this.setState((state) => {
      state.accordionsData[0].inputs = [
        ...state.accordionsData[0].inputs,
        methodeInput,
      ];
      return state;
    });
    // si ppf existant
    if (this.idppf) {
      // récupérer la méthode du ppf existant
      const methode = methodes.find(
        (methode) => methode.id === this.props.history.location.state.idmet
      );
      // si méthode trouvée
      if (methode && methode.id) {
        // setter cette methode dans le state
        this.setState((state) => {
          state.formData.methode = [methode];
          return state;
        });
        // désactiver la saisie du champ
        this.disableInput(1);
        // récupérer les champs de saisie correspondant à ce PPF
        this.displayDonneesPPF(methode.id);
      }
    }
  }

  async displayDonneesPPF(idMethode) {
    // requête pour récupérer les champs de saisie PPF
    const response = await this.props.data.sendRequest("getChampsPpf", {
      idMethode,
      idppf: this.idppf,
    });

    if (response) {
      const saisiePPFAccordion = {
        title: "Saisie données PPF",
        inputGroups: response,
      };
      // puis rajouter...
      this.setState((state) => {
        state.accordionsData = [
          ...state.accordionsData,
          // - l'accordéon de sélection des parcelles
          { title: "Choix des parcelles", parcelles: true },
          // - l'accordéon saisie de données PPF avec les champs
          saisiePPFAccordion,
        ];
        // les valeurs des champs
        state.formData = {
          ...state.formData,
          ...this.getFormDataValues(saisiePPFAccordion),
        };
        return state;
      });
      const { parcelles } = this.props.history.location.state;
      // si ppf existant et que des parcelles sont attachées à ce ppf
      if (this.idppf && parcelles && parcelles.length) {
        // setter les parcelles actives dans le state
        this.props.ctx.setParcellesActives(parcelles);
      }
    }
  }

  getFormDataValues(data) {
    let defaultValues = {};
    data.inputGroups.forEach((inputGroup) => {
      inputGroup.fields.forEach((input) => {
        if (input.type === "number" || input.type === "react-select") {
          if (this.idppf && input.value) {
            defaultValues[input.name] = input.value;
          } else {
            // valeur par défaut
            defaultValues[input.name] = "";
          }
        }
      });
    });
    return defaultValues;
  }

  isValid(ppf) {
    let isValid = true;

    // // récupérer tous les inputs
    // let inputs = [
    //   ...this.state.accordionsData[0].inputs,
    //   ...this.state.accordionsData[2].inputs,
    // ];
    // // pour chaque input
    // for (let i = 0; i < inputs.length; i++) {
    //   const input = inputs[i];
    //   // si requis et non renseigné
    //   if (input.required && !this.state.formData[input.name]) {
    //     // exception pour les radio boutons (peut être égal à 0)
    //     if (input.type !== "radio") {
    //       isValid = false;
    //       alert(`le champ ${input.label} est requis`);
    //       return;
    //     }
    //   }
    // }

    // si aucune parcelle sélectionnée
    if (!ppf.parcelles.length) {
      isValid = false;
      this.props.data.showNotification({
        type: "warning",
        title: "Aucune parcelle sélectionnée",
      });
    }
    return isValid;
  }

  transformData(ppf) {
    // pour chaque propriété du ppf
    Object.keys(ppf).forEach((propName) => {
      const prop = ppf[propName];
      // exception pour les parcelles (on doit garder un tableau pour la surface)
      if (propName !== "parcelles") {
        // si tableau et qu'on a un id
        if (Array.isArray(prop) && prop[0].id) {
          // ne garder que l'id
          ppf[propName] = ppf[propName][0].id;
        }
      }
    });
    return ppf;
  }

  async save() {
    let ppf = { ...this.state.formData };
    ppf.parcelles = this.props.ctx.parcellesActives;
    if (this.isValid(ppf)) {
      const data = this.transformData(ppf);
      const idMethode = data.methode;
      const isSuccess = await this.props.data.sendRequest("createPpf", {
        params: {
          idMethode,
          idppf: this.idppf,
        },
        data,
      });
      if (isSuccess) {
        this.goToList();
      }
    }
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  async delete() {
    let ppf = { ...this.state.formData };
    const data = this.transformData(ppf);
    const idMethode = data.methode;
    const isSuccess = await this.props.data.sendRequest("deletePpf", {
      itemId: this.idppf,
      idMethode,
    });
    if (isSuccess) {
      this.goToList();
    }
  }

  goToList() {
    this.props.history.goBack();
  }

  render() {
    const isCreate = this.state.accordionsData.length === 3;
    const isUpdate = this.idppf;
    return (
      <Container>
        <Row>
          <Alert
            variant={isUpdate ? "warning" : "success"}
            className="text-center w-100 mb-0"
          >
            <Alert.Heading>
              {isUpdate ? "Modifier le PPF" : "Créer un PPF"}
            </Alert.Heading>
          </Alert>
        </Row>
        <Row>
          <Accordions
            accordionsData={this.state.accordionsData}
            formData={this.state.formData}
            isUpdate={isUpdate}
            handleChange={this.handleChange}
            handleReactSelectChange={this.handleReactSelectChange}
          />
        </Row>
        <Row className="mt-2 mb-2">
          <Col className="d-flex justify-content-end">
            {isUpdate && (
              <Button variant="danger" onClick={this.toggleModal}>
                Supprimer le PPF
                <MdDelete size={25} className="ml-2" />
              </Button>
            )}
            {isCreate && (
              <Button variant="warning" onClick={this.save} className="ml-2">
                Enregistrer
                <MdEdit size={25} className="ml-2" />
              </Button>
            )}
          </Col>
        </Row>
        <Modal centered show={this.state.isModalOpen} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous certain de vouloir supprimer ce PPF ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleModal}>
              Annuler
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                this.toggleModal();
                this.delete();
              }}
            >
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default WithContext(WithParcellesActives(CreateSaisie));
