import React from "react";
// datas
import { WithParcellesActives } from "parcelles/provider";
// custom components
import Parcelle from "../parcellaire/parcelle.js";
// react-bootstrap components
import { ListGroup, Collapse, Button } from "react-bootstrap";
// react-icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

class Groupes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accordions: props.ctx.groupesParcelles.map(group => false)
    };
  }

  toggleAccordion(index) {
    let accordions = this.state.accordions;
    accordions[index] = !accordions[index];
    this.setState({
      accordions: accordions
    });
  }
  groupToggle = (index, e) => {
    e.stopPropagation();
    this.toggleAccordion(index);
    this.props.ctx.groupToggle(index, e);
  };

  render() {
    return this.props.ctx.groupesParcelles.map((group, i) => (
      <ListGroup as="ul" key={i}>
        <ListGroup.Item
          onClick={this.toggleAccordion.bind(this, i)}
          variant="light"
          className="d-flex justify-content-between align-items-center"
        >
          <Button
            variant={group.checked ? "danger" : "success"}
            size="sm"
            onClick={this.groupToggle.bind(this, i)}
            disabled={!this.props.isEditable}
          >
            {group.checked ? "Retirer" : "Ajouter"}
          </Button>
          <h6> {group.label} </h6>
          {this.state.accordions[i] && (
            <FaChevronUp size={20} className="float-right" />
          )}
          {!this.state.accordions[i] && (
            <FaChevronDown size={20} className="float-right" />
          )}
        </ListGroup.Item>
        <Collapse in={this.state.accordions[i]}>
          <div>
            <Parcelle list={group.options} isEditable={this.props.isEditable} />
          </div>
        </Collapse>
      </ListGroup>
    ));
  }
}

export default WithParcellesActives(Groupes);
