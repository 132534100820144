import React, { useState } from "react";
// router
import { withRouter } from "react-router-dom";
// react-bootstrap components
import {
  Container,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
// switcher component
import Switcher from "switcher/component";
// ag-grid component
import Grid from "ag-grid/grid";
// list component
import List from "generique/liste";

const Tableau = ({ data, history }) => {
  const [vue, setVue] = useState(2);
  const rowClickCallback = (rowData) => {
    if (data.rowClick) {
      history.push(data.rowClick, rowData.data);
    }
  };

  if (data.modeListe) {
    if (history.location.search === "?requete=ecran&ecran=stocksAppros") {
      return (
        <Switcher
          config={[{ nom: "stocks", liste: data }]}
          rowClickCallback={rowClickCallback}
        >
          <GenericList data={data} />
        </Switcher>
      );
    } else {
      return (
        <>
          <Container fluid className="d-flex justify-content-end mt-3">
            <ButtonToolbar className="ml-3">
              <ToggleButtonGroup
                type="radio"
                name="vue"
                defaultValue={vue}
                onChange={(value) => setVue(value)}
              >
                <ToggleButton value={1} variant="light">
                  Liste
                </ToggleButton>
                <ToggleButton value={2} variant="light">
                  Tableau
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </Container>
          {vue === 1 && <GenericList data={data} />}
          {vue === 2 && (
            <GenericGrid data={data} rowClickCallback={rowClickCallback} />
          )}
        </>
      );
    }
  } else {
    return <GenericGrid data={data} rowClickCallback={rowClickCallback} />;
  }
};

export default withRouter(Tableau);

const GenericList = ({ data }) => <List data={data} />;

const GenericGrid = ({ data, rowClickCallback }) => (
  <Grid
    specificConfig={data}
    buttons={data.buttons}
    exportExcel={data.exportCSV}
    exportPDF={data.exportPDF}
    urlPDF={data.urlPDF}
    tableauFiltrable={data.tableauFiltrable}
    getRowStyle={data.getRowStyle}
    rowClickCallback={rowClickCallback}
    height={data.height}
  />
);
