// HTTP client
import axios from "axios";
// urls
const baseUrl = "https://json.ceredoc.fr/ajax";
// const baseUrl = "https://json.ceredoc.fr/ajax_beta";
const subUrls = {
  interventions: {
    create: "creerIntervention.php",
    update: "modifierIntervention.php",
    delete: "supprimerIntervention.php",
  },
  ilots: {
    create: "creerIlot.php",
    update: "modifierIlot.php",
    delete: "supprimerIlot.php",
  },
  parcelles: {
    create: "creerParcelle.php",
    update: "modifierParcelle.php",
    bulkUpdate: "modifierAssolement.php",
    delete: "supprimerParcelle.php",
    N_1: "recupererN_1.php",
  },
  groupesParcelles: {
    create: "creerGroupe.php",
    update: "modifierGroupe.php",
    delete: "supprimerGroupe.php",
  },
  telepac: {
    import: "importTelepac.php",
  },
  materiels: {
    create: "creerMateriel.php",
    update: "modifierMateriel.php",
    delete: "supprimerMateriel.php",
    N_1: "recupererMaterielN_1.php",
  },
  mainOeuvres: {
    create: "creerMainOeuvre.php",
    update: "modifierMainOeuvre.php",
    delete: "supprimerMainOeuvre.php",
    N_1: "recupererMainOeuvreN_1.php",
  },
  mouvementsStocks: {
    recupererStocks: "recupererStocks.php",
    getStocks: "getStocks.php",
    create: "creerMouvementStock.php",
    update: "modifierMouvementStock.php",
    delete: "supprimerMouvementStock.php",
    transfererStock: "transfererStock.php",
    decomposerStockParProduits: "decomposerStockParProduits.php",
    decomposerStockParQuantite: "decomposerStockParQuantite.php",
  },
};

export const API = async (operation, ...args) => {
  try {
    const data = await operations[operation](...args);
    return data;
  } catch (error) {
    if (!navigator.onLine) {
      console.error("API catch - pas de connexion internet");
    } else {
      console.error("API catch - connexion internet OK - erreur serveur : ");
      console.error(error);
    }
    if (operation === "create") {
      return args[2];
      // TODO : à désactiver
    } else if (operation === "upload") {
      return false;
    } else {
      return true;
    }
  }
};

const operations = {
  login: async ({ email, password }) => {
    const response = await axios.get(
      `${baseUrl}/login.php?identifiant=${email}&password=${password}`
    );
    console.log("login request - response : ");
    console.log(response.data);
    return response.data;
  },
  fetch: async ({ id_utilisateur, id_campagne, id_exploitation, token }) => {
    // console.log(`fetch request - url : ${baseUrl}/login.php`);
    // console.log(
    //   `fetch request - params : ${JSON.stringify({
    //     id_utilisateur,
    //     id_campagne,
    //     id_exploitation,
    //     token,
    //   })}`
    // );
    const response = await axios.post(`${baseUrl}/login.php`, {
      id_utilisateur,
      id_campagne,
      id_exploitation,
      token,
    });
    return response.data;
  },
  create: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
    listName,
    item,
  }) => {
    const response = await axios.post(
      `${baseUrl}/${subUrls[listName].create}`,
      { id_utilisateur, id_campagne, id_exploitation, ...item }
    );
    const { id } = response.data.data;
    if (id) {
      if (item.properties) {
        item.properties.id = id;
      } else {
        item.id = id;
      }
    }
    return item;
  },
  update: async ({
    listName,
    item,
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.post(
      `${baseUrl}/${subUrls[listName].update}`,
      { ...item, id_utilisateur, id_campagne, id_exploitation }
    );
    return response.data.success;
  },
  bulkUpdate: async ({
    listName,
    items,
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.post(
      `${baseUrl}/${subUrls[listName].bulkUpdate}`,
      { ...items, id_utilisateur, id_campagne, id_exploitation }
    );
    return response.data.success;
  },
  delete: async ({
    listName,
    itemId,
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.post(
      `${baseUrl}/${subUrls[listName].delete}`,
      {
        id: itemId,
        id_utilisateur,
        id_campagne,
        id_exploitation,
      }
    );
    return response.data;
  },
  autocomplete: async ({ listName, typeId, inputValue }) => {
    const response = await axios.get(
      `${baseUrl}/selectOptions.php?liste=${listName}&interventionTypeId=${typeId}&input=${inputValue}`
    );
    return response.data;
  },
  getParcellesN_1: async ({ id_utilisateur, id_campagne, id_exploitation }) => {
    const response = await axios.get(
      `${baseUrl}/${subUrls["parcelles"]["N_1"]}?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`
    );
    return response.data.success;
  },
  upload: async ({ type, formData }) => {
    const response = await axios.post(
      `${baseUrl}/${subUrls["telepac"][type]}`,
      formData,
      { timeout: 30000 }
    );
    return response.data.success;
  },
  init: async ({ id_utilisateur, id_campagne, id_exploitation }) => {
    const response = await axios.get(
      `${baseUrl}/initialisationTelepac.php?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`
    );
    return response.data.success;
  },
  getSynthesesPpf: async ({ id_utilisateur, id_campagne, id_exploitation }) => {
    const response = await axios.get(
      `${baseUrl}/synthesesPpf.php?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`
    );
    return response.data;
  },
  getRegionsPpf: async ({ id_utilisateur, id_exploitation }) => {
    const response = await axios.get(
      `${baseUrl}/choixRegOpe.php?id_utilisateur=${id_utilisateur}&id_exploitation=${id_exploitation}`
    );
    return response.data;
  },
  getChampsPpf: async ({
    idMethode,
    idppf,
    id_utilisateur,
    id_exploitation,
  }) => {
    let url = `${baseUrl}/formPpf.php?idmet=${idMethode}&id_utilisateur=${id_utilisateur}&id_exploitation=${id_exploitation}`;
    if (idppf) {
      url += `&idppf=${idppf}`;
    }
    const response = await axios.get(url);
    return response.data;
  },
  createPpf: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
    params,
    data,
  }) => {
    let url = `${baseUrl}`;
    const { idppf, idMethode } = params;
    if (idppf) {
      url += "/modifierPpf.php";
    } else {
      url += "/creerPpf.php";
    }
    const response = await axios.post(url, {
      ...data,
      id_utilisateur,
      id_campagne,
      id_exploitation,
      idMethode,
      idppf,
    });
    return response.data.success;
  },
  deletePpf: async ({
    itemId,
    idMethode,
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.post(`${baseUrl}/supprimerPpf.php`, {
      id: itemId,
      idMethode,
      id_utilisateur,
      id_campagne,
      id_exploitation,
    });
    return response.data.success;
  },
  fusionDecoupePreview: async ({
    data,
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.post(`${baseUrl}/fusionDecoupePreview.php`, {
      ...data,
      id_utilisateur,
      id_campagne,
      id_exploitation,
    });
    return response.data;
  },
  fusionDecoupe: async ({
    data,
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.post(`${baseUrl}/fusionDecoupe.php`, {
      ...data,
      id_utilisateur,
      id_campagne,
      id_exploitation,
    });
    return response.data;
  },
  synthesesParametrables: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
    ecran,
  }) => {
    let params = `?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`;
    if (ecran) {
      params += `&ecran=${ecran}`;
    }
    const response = await axios.get(
      `${baseUrl}/synthesesParametrables.php${params}`
    );
    return response.data;
  },
  boutons: async ({ id_utilisateur, id_campagne, id_exploitation, ecran }) => {
    let urlParams = `?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`;
    if (ecran) {
      urlParams += `&ecran=${ecran}`;
    }
    const response = await axios.get(`${baseUrl}/boutons.php${urlParams}`);
    return response.data;
  },
  ecran: async ({ id_utilisateur, id_campagne, id_exploitation, ecran }) => {
    let urlParams = `?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`;
    if (ecran) {
      urlParams += `&ecran=${ecran}`;
    }
    const response = await axios.get(`${baseUrl}/ecran.php${urlParams}`);
    return response.data;
  },
  fichesParcellaire: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
    data,
  }) => {
    const response = await axios.post(`${baseUrl}/fichesParcellaire.php`, {
      ...data,
      id_utilisateur,
      id_campagne,
      id_exploitation,
    });
    return response.data;
  },
  tableauFiltrable: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
    data,
  }) => {
    const response = await axios.post(`${baseUrl}/tableauFiltrable.php`, {
      ...data,
      id_utilisateur,
      id_campagne,
      id_exploitation,
    });
    return response.data;
  },
  listes: async ({ id_utilisateur, id_campagne, id_exploitation, ecran }) => {
    const response = await axios.get(
      `${baseUrl}/listes.php?ecran=${ecran}&id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`
    );
    return response.data;
  },
  getMaterielN_1: async ({ id_utilisateur, id_campagne, id_exploitation }) => {
    const response = await axios.get(
      `${baseUrl}/${subUrls["materiels"]["N_1"]}?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`
    );
    return response.data.success;
  },
  getMainOeuvreN_1: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.get(
      `${baseUrl}/${subUrls["mainOeuvres"]["N_1"]}?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`
    );
    return response.data.success;
  },
  creationProduit: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
    data,
  }) => {
    const response = await axios.post(`${baseUrl}/creationProduit.php`, {
      ...data,
      id_utilisateur,
      id_campagne,
      id_exploitation,
    });
    return response.data;
  },
  conformitePhyto: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
    data,
  }) => {
    const response = await axios.post(`${baseUrl}/conformitePhyto.php`, {
      ...data,
      id_utilisateur,
      id_campagne,
      id_exploitation,
    });
    return response.data;
  },
  getStocks: async ({ id_utilisateur, id_campagne, id_exploitation }) => {
    const response = await axios.get(
      `${baseUrl}/${subUrls["mouvementsStocks"]["getStocks"]}?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`
    );
    return response.data;
  },
  recupererStocks: async ({ id_utilisateur, id_campagne, id_exploitation }) => {
    const response = await axios.get(
      `${baseUrl}/${subUrls["mouvementsStocks"]["recupererStocks"]}?id_utilisateur=${id_utilisateur}&id_campagne=${id_campagne}&id_exploitation=${id_exploitation}`
    );
    return response.data;
  },
  transfererStock: async ({
    id_utilisateur,
    id_campagne,
    id_exploitation,
    data,
  }) => {
    const response = await axios.post(
      `${baseUrl}/${subUrls["mouvementsStocks"]["transfererStock"]}`,
      {
        ...data,
        id_utilisateur,
        id_campagne,
        id_exploitation,
      }
    );
    return response.data.success;
  },
  decomposerStockParProduits: async ({
    itemId,
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.post(
      `${baseUrl}/${subUrls["mouvementsStocks"]["decomposerStockParProduits"]}`,
      {
        id: itemId,
        id_utilisateur,
        id_campagne,
        id_exploitation,
      }
    );
    return response.data.success;
  },
  decomposerStockParQuantite: async ({
    itemId,
    quantite,
    id_utilisateur,
    id_campagne,
    id_exploitation,
  }) => {
    const response = await axios.post(
      `${baseUrl}/${subUrls["mouvementsStocks"]["decomposerStockParQuantite"]}`,
      {
        id: itemId,
        quantite,
        id_utilisateur,
        id_campagne,
        id_exploitation,
      }
    );
    return response.data.success;
  },
};

// DEBUG CREATE
// return new Promise(resolve => {
//   setTimeout(() => {
//     if (item.properties) {
//       item.properties.id = 999;
//     } else {
//       item.id = 999;
//     }
//     console.log("will return item in api");
//     resolve(item);
//   }, 1000);
// });

// DEBUG UPDATE / DELETE
// return new Promise(resolve => {
//   setTimeout(() => {
//     resolve(true);
//   }, 1000);
// });
