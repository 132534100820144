import React from "react";
// global context (this.props.data)
import { WithContext } from "../store/DataProvider";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  InputGroup,
  Button,
  Modal
} from "react-bootstrap";
// react-icons
import { MdEdit, MdDelete } from "react-icons/md";

class CreateIlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isModalOpen: false };
    const { id } = this.props.history.location.state;
    let ilotExistant;
    let nouvelIlot = {
      properties: {
        label: "",
        pacId: "",
        surface: ""
      }
    };
    if (id) {
      ilotExistant = props.data.ilots.find(item => item.properties.id === id);
    }
    if (ilotExistant) {
      this.state.ilot = ilotExistant.properties;
      if (!this.state.ilot.pacId) this.state.ilot.pacId = "";
    } else {
      this.state.ilot = nouvelIlot.properties;
    }

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const type = target.type;
    let value = target.value;
    const name = target.name;

    // si champ number vide : retirer le 0 qui précède la saisie
    if (type === "number" && value === "") {
      value = "";
    } else {
      // si surface : tronquer à 2 décimales
      if (name === "surface") {
        value = +Number(target.value).toFixed(2);
      } else if (name === "pacId") {
        value = Number(value) || "";
      }
    }

    this.setState(state => {
      state.ilot[name] = value;
      return state;
    });
  }

  async save() {
    let ilot = { ...this.state.ilot };
    if (!ilot.pacId) ilot.pacId = null;
    if (ilot.label && ilot.surface) {
      if (ilot.id) {
        await this.props.data.updateItem("ilots", ilot);
      } else {
        await this.props.data.addItem("ilots", ilot);
      }
      this.goToList();
    } else {
      this.props.data.showNotification({
        type: "danger",
        title: "Tous les champs doivent être renseignés."
      });
    }
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  async delete() {
    const { id } = this.state.ilot;
    await this.props.data.removeItem("ilots", id);
    this.goToList();
  }

  goToList() {
    this.props.history.replace("/parcellaire");
  }

  render() {
    const isUpdate = this.props.history.location.state.id;
    // TODO : est-ce que c'est bien comme ça qu'on détermine si l'ilot vient de telepac ?
    const isIlotTelepac = this.state.ilot.pacId;
    return (
      <Container>
        <Row className="mb-3">
          <Col>
            <Alert
              variant={isUpdate ? "warning" : "success"}
              className="text-center"
            >
              <Alert.Heading>
                {isUpdate ? "Modifier" : "Créer"} un îlot
              </Alert.Heading>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Nom îlot
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="label"
                    type="text"
                    placeholder="Nom îlot"
                    value={this.state.ilot.label}
                    onChange={this.handleChange}
                  />
                </Col>
              </Form.Group>
              {!isIlotTelepac && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      N° îlot PAC
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        name="pacId"
                        as="select"
                        value={this.state.ilot.pacId}
                        onChange={this.handleChange}
                      >
                        {/* TODO : this.props.data.telepac.map ... options */}
                        <option value="">Aucun</option>
                        <option value="1">Îlot 1</option>
                        <option value="2">Îlot 2</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Surface
                    </Form.Label>
                    <Col sm="10">
                      <InputGroup>
                        <Form.Control
                          name="surface"
                          type="number"
                          placeholder="Surface"
                          step="0.01"
                          value={this.state.ilot.surface}
                          onChange={this.handleChange}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>ha</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </>
              )}
            </Form>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col className="d-flex justify-content-end">
            {isUpdate && !isIlotTelepac && (
              <Button variant="danger" onClick={this.toggleModal}>
                Supprimer l'îlot
                <MdDelete size={25} className="ml-2" />
              </Button>
            )}
            <Button variant="warning" onClick={this.save} className="ml-2">
              Enregistrer
              <MdEdit size={25} className="ml-2" />
            </Button>
          </Col>
        </Row>
        <Modal centered show={this.state.isModalOpen} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous certain de vouloir supprimer cet ilot ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleModal}>
              Annuler
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default WithContext(CreateIlot);
