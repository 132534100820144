// https://github.com/sconstantinides/react-pullable
import React from "react";
import PropTypes from "prop-types";
// react-bootstrap components
import Spinner from "react-bootstrap/Spinner";
// react-icons
import { MdRefresh } from "react-icons/md";

class Pullable extends React.Component {
  constructor(props) {
    super(props);

    this.clearTouchStatus();

    this.state = {
      status: "ready",
      height: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("touchstart", this.onTouchStart);
    window.addEventListener("touchmove", this.onTouchMove, { passive: false });
    window.addEventListener("touchend", this.onTouchEnd);
  }

  componentWillUnmount() {
    window.removeEventListener("touchstart", this.onTouchStart);
    window.removeEventListener("touchmove", this.onTouchMove, {
      passive: false,
    });
    window.removeEventListener("touchend", this.onTouchEnd);

    clearTimeout(this.refreshCompletedTimeout);
    clearTimeout(this.resetTimeout);
  }

  clearTouchStatus = () => {
    this.pullStartY = null;
    this.pullMoveY = null;
    this.dist = 0;
    this.distResisted = 0;
    this.ignoreTouches = false;
  };

  onTouchStart = (e) => {
    if (this.props.disabled || this.ignoreTouches) return;

    if (this.state.status === "ready" && this.props.shouldPullToRefresh()) {
      this.pullStartY = e.touches[0].screenY;
    } else {
      this.pullStartY = null;
    }
  };

  onTouchMove = (e) => {
    if (this.props.disabled || this.ignoreTouches || this.pullStartY === null)
      return;

    this.pullMoveY = e.touches[0].screenY;
    this.dist = this.pullMoveY - this.pullStartY;

    if (this.dist > 0) {
      e.preventDefault();

      this.distResisted = Math.min(
        this.dist / this.props.resistance,
        this.props.distThreshold
      );

      this.setState({ status: "pulling", height: this.distResisted }, () => {
        if (this.distResisted === this.props.distThreshold) this.refresh();
      });
    }
  };

  onTouchEnd = (e) => {
    if (this.props.disabled || this.ignoreTouches) return;

    if (this.state.status === "pulling") {
      this.ignoreTouches = true;
      this.setState({ status: "pullAborted", height: 0 }, () => {
        this.reset(this.props.resetDuration);
      });
    } else {
      this.reset();
    }
  };

  // refresh = () => {
  //   this.ignoreTouches = true;
  //   this.setState({ status: "refreshing" }, () => {
  //     this.props.onRefresh();
  //     this.refreshCompletedTimeout = setTimeout(() => {
  //       this.setState({ status: "refreshCompleted", height: 0 }, () => {
  //         this.reset(this.props.resetDuration);
  //       });
  //     }, this.props.refreshDuration);
  //   });
  // };

  // Si on veut que le spinner s'arrête dès qu'on a reçu la réponse
  refresh = () => {
    this.ignoreTouches = true;
    this.setState({ status: "refreshing" }, () => {
      this.props.onRefresh().finally(() => {
        this.setState({ status: "refreshCompleted", height: 0 }, () => {
          this.reset(this.props.resetDuration);
        });
      });
    });
  };

  reset = (delay = 0) => {
    this.resetTimeout = setTimeout(() => {
      this.clearTouchStatus();
      this.setState({ status: "ready" });
    }, delay);
  };

  render() {
    const status = this.state.status;
    const shouldReset =
      status === "pullAborted" || status === "refreshCompleted";

    return (
      <React.Fragment>
        <div
          className={this.props.className}
          height={this.state.height}
          style={{
            display: "flex",
            overflow: "hidden",
            justifyContent: "center",
            pointerEvents: "none",
            alignItems: this.props.centerSpinner ? "center" : "flex-start",
            transition: shouldReset
              ? `height ${this.props.resetDuration}ms ${this.props.resetEase}`
              : "none",
          }}
        >
          <div
            className="text-primary bg-light rounded-circle shadow"
            style={{ opacity: this.state.height / 100 }}
          >
            {this.state.status === "pulling" && <MdRefresh size={40} />}
            {this.state.status === "refreshing" && (
              <Spinner animation="grow" variant="primary" />
            )}
          </div>
        </div>
        {this.props.children}
      </React.Fragment>
    );
  }
}

Pullable.defaultProps = {
  className: "pullable",
  centerSpinner: true,
  fadeSpinner: true,
  spinnerOffset: 0,
  distThreshold: 72,
  resistance: 2.5,
  refreshDuration: 2000,
  resetDuration: 400,
  resetEase: "cubic-bezier(0.215, 0.61, 0.355, 1)",
  shouldPullToRefresh: () => window.scrollY <= 0,
  disabled: false,
};

Pullable.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  className: PropTypes.string,
  centerSpinner: PropTypes.bool,
  fadeSpinner: PropTypes.bool,
  spinnerOffset: PropTypes.number,
  distThreshold: PropTypes.number,
  resistance: PropTypes.number,
  refreshDuration: PropTypes.number,
  resetDuration: PropTypes.number,
  resetEase: PropTypes.string,
  shouldPullToRefresh: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Pullable;
