import React, { useContext } from "react";
// create intervention context
import { CreateContext } from "interventions/createProvider";
// intervention datas
import { listeStatuts } from "interventions/datas.js";
// react-bootstrap components
import { Row, Col, Card, Form } from "react-bootstrap";
// intervention helper
import { Helper } from "interventions/helpers.js";

const General = ({ isEditable }) => {
  const { intervention, typeId, handleInputChange } = useContext(CreateContext);
  const { date, statutId, statutPrixId } = intervention;
  const isStatut = Helper.isStatut(typeId);
  const isPrixDeVente = Helper.isPrixDeVente(typeId);
  return (
    <Card.Body>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            Date
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="date"
              type="datetime-local"
              value={date}
              onChange={handleInputChange}
              required
              disabled={!isEditable}
            />
          </Col>
        </Form.Group>
        {isStatut && (
          <fieldset>
            <Form.Group as={Row}>
              <Form.Label as="legend" column sm={4}>
                Statut de l'intervention
              </Form.Label>
              <Col sm={4}>
                {listeStatuts.map((statut) => (
                  <Form.Check
                    key={statut.value}
                    type="radio"
                    label={statut.label}
                    name="statutId"
                    value={statut.value}
                    checked={statutId === statut.value}
                    onChange={handleInputChange}
                    disabled={!isEditable}
                  />
                ))}
              </Col>
            </Form.Group>
          </fieldset>
        )}
        {isPrixDeVente && (
          <fieldset>
            <Form.Group as={Row}>
              <Form.Label as="legend" column sm={4}>
                Statut du prix
              </Form.Label>
              <Col sm={4}>
                <Form.Check
                  type="radio"
                  label="Prévisionnel"
                  name="statutPrixId"
                  value={1}
                  checked={statutPrixId === 1}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
                <Form.Check
                  type="radio"
                  label="Effectif"
                  name="statutPrixId"
                  value={2}
                  checked={statutPrixId === 2}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
              </Col>
            </Form.Group>
          </fieldset>
        )}
      </Form>
    </Card.Body>
  );
};

export default General;
