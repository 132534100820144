import React from "react";
// global context (this.props.data)
import { WithContext } from "../store/DataProvider";
// contexte parcelles (this.props.ctx)
import { WithParcellaire } from "../parcellaire/provider";
// react-bootstrap components
import { Tab, Tabs } from "react-bootstrap";
// custom component
import ListeParcelles from "../parcellaire/listeParcelles.js";
import ListeGroupes from "../parcellaire/listeGroupes.js";
import ListeIlots from "../parcellaire/listeIlots.js";
import Telepac from "../parcellaire/telepac.js";

class Parcellaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // onglet par défaut : parcelles
      tab: "parcelles",
    };
  }

  // rétablir le dernier onglet sélectionné
  componentDidMount() {
    const historyState = this.props.history.state;
    const pathname = this.props.location.pathname;
    if (historyState && historyState[pathname]) {
      this.setState({ tab: historyState[pathname].tab });
    }
  }

  // sauvegarder le dernier onglet sélectionné
  componentWillUnmount() {
    if (!this.props.history.state) {
      this.props.history.state = {};
    }
    const pathname = this.props.location.pathname;
    this.props.history.state[pathname] = {
      tab: this.state.tab,
    };
  }

  selectTab(tab) {
    this.setState({ tab: tab });
  }

  render() {
    const isIlot = this.props.ctx.preference.ilot;
    const isTelepac = this.props.data.parametres.parcellaireModifiable;
    return (
      <Tabs activeKey={this.state.tab} onSelect={this.selectTab.bind(this)}>
        <Tab eventKey="parcelles" title="Parcelles">
          <ListeParcelles isTabActive={this.state.tab === "parcelles"} />
        </Tab>
        <Tab eventKey="groupes" title="Groupes">
          <ListeGroupes isTabActive={this.state.tab === "groupes"} />
        </Tab>
        {isIlot && (
          <Tab eventKey="ilots" title="Îlots">
            <ListeIlots isTabActive={this.state.tab === "ilots"} />
          </Tab>
        )}
        {isTelepac && (
          <Tab eventKey="telepac" title="Lien TELEPAC">
            <Telepac />
          </Tab>
        )}
      </Tabs>
    );
  }
}

export default WithContext(WithParcellaire(Parcellaire));
