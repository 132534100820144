import React from "react";
// react-bootstrap components
import { Row, Col, Card, Form } from "react-bootstrap";
// types de mouvements (statuts)
import { listeTypesMouvements } from "interventions/datas";

const General = ({ mouvement, handleChange, isUpdate }) => {
  return (
    <Card.Body>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            Date
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="date"
              type="date"
              value={mouvement.date}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>
        {
          <fieldset>
            <Form.Group as={Row}>
              <Form.Label as="legend" column sm={4}>
                Statut du mouvement de stock
              </Form.Label>
              <Col sm={4}>
                {listeTypesMouvements.map((type, i) => (
                  <Form.Check
                    key={i}
                    type="radio"
                    label={type.label}
                    name="typeDeMouvementId"
                    value={type.value}
                    checked={mouvement.typeDeMouvementId === type.value}
                    onChange={handleChange}
                    disabled={isUpdate}
                  />
                ))}
              </Col>
            </Form.Group>
          </fieldset>
        }
      </Form>
    </Card.Body>
  );
};

export default General;
