import React from "react";
// router
import { withRouter } from "react-router-dom";
// icons
import { FaChevronCircleUp, FaChevronCircleDown } from "react-icons/fa";

class ScrollButtons extends React.Component {
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  render() {
    const { pathname } = this.props.location;
    if (pathname === "/interventions" || pathname === "/parcellaire") {
      return (
        <div
          className="d-flex flex-row-reverse"
          style={{
            position: "fixed",
            padding: 15,
            bottom: 0,
            right: 0,
            zIndex: 999,
          }}
        >
          <div className="d-flex flex-column">
            <div
              onClick={this.scrollToTop}
              style={{ fontSize: 32, color: "#ffc107" }}
            >
              <FaChevronCircleUp />
            </div>
            <div
              onClick={this.scrollToBottom}
              style={{ fontSize: 32, color: "#ffc107" }}
            >
              <FaChevronCircleDown />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(ScrollButtons);
