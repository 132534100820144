import React from "react";
// react-bootstrap components
import Toast from "react-bootstrap/Toast";

class Notifications extends React.Component {
  getVariant(notificationType) {
    if (notificationType === "danger") {
      return "bg-danger text-light";
    } else if (notificationType === "success") {
      return "bg-success text-light";
    } else if (notificationType === "warning") {
      return "bg-warning";
    } else {
      return undefined;
    }
  }

  getBorderBottom(notificationType) {
    if (notificationType === "success") {
      return {
        borderBottom: "1px solid #b1dfbb",
      };
    } else if (notificationType === "danger") {
      return {
        borderBottom: "1px solid #f5c6cb",
      };
    } else if (notificationType === "warning") {
      return {
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      };
    }
  }

  render() {
    const notifications = this.props.notifications || [];
    return (
      <div
        style={{
          position: "fixed",
          textAlign: "center",
          width: "100%",
          /* top: this.props.data && this.props.data.utilisateur ? 71 : 14, */
          top: 71,
          paddingLeft: 14,
          paddingRight: 14,
          zIndex: 1000,
        }}
      >
        {notifications.map((notification) => (
          <Toast
            key={notification.id}
            animation
            /* delay={5000}
              autohide */
            onClose={() => this.props.hideNotification(notification.id)}
            className={this.getVariant(notification.type)}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Toast.Header
              className={this.getVariant(notification.type)}
              style={this.getBorderBottom(notification.type)}
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/logo-192.png"}
                height="20"
                alt=""
                className="mr-2"
              />
              <strong className="mr-auto">Céré d'Oc</strong>
              {/* <small> {moment().fromNow(true)} </small> */}
            </Toast.Header>
            <Toast.Body> {notification.title} </Toast.Body>
          </Toast>
        ))}
      </div>
    );
  }
}

export default Notifications;
