import React from "react";
// router
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {
  // scroll to top everytime component is mounted
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    // scroll to top if location has changed (different page)
    // avoid to scroll when notification is displayed / closed / sync toolbar update
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
