import React from "react";
// contexte global (this.props.data)
import { WithContext } from "../store/DataProvider";
// pull-to-refresh
import Pullable from "../pull-to-refresh/component";
// custom components
import Switcher from "switcher/component";
import Filters from "../filters/component3";
import Intervention from "../interventions/intervention";
import ScrollButtons from "scroll/buttons";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
// react-icons
import { MdModeEdit } from "react-icons/md";

class Interventions extends React.Component {
  constructor() {
    super();
    this.listRef = React.createRef();
  }

  openInterventionTypes() {
    // requete parametrable
    this.props.history.push(
      "/parametrable?requete=boutons&ecran=interventions"
    );
  }

  openEdit({ id, interventionTypeId }) {
    this.props.history.push("/createIntervention", {
      id,
      interventionTypeId,
    });
    if (id < 0) {
      this.props.data.showNotification({
        type: "warning",
        title: `Cette intervention n'est pas encore synchronisée.
          Vous pouvez la visualiser et la dupliquer.
          Vous ne pouvez pas la modifier, ni la supprimer tant qu'elle n'est pas synchronisée.`,
      });
    }
  }

  toggleParcellesExploitation(value) {
    this.props.data.toggleParcellesExploitation(value);
  }

  getButtons() {
    return (
      <>
        <ButtonToolbar>
          {this.props.data.exploitations.length > 1 && (
            <ToggleButtonGroup
              type="radio"
              name="vue"
              defaultValue={this.props.data.parcellesMode}
              onChange={this.toggleParcellesExploitation.bind(this)}
            >
              <ToggleButton
                value={1}
                variant={
                  this.props.data.parcellesMode === 1 ? "success" : "light"
                }
              >
                Toutes les parcelles
              </ToggleButton>
              <ToggleButton
                value={2}
                variant={
                  this.props.data.parcellesMode === 2 ? "success" : "light"
                }
              >
                Mes parcelles
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </ButtonToolbar>
        <Button
          onClick={this.openInterventionTypes.bind(this)}
          variant="warning"
          className="ml-3"
        >
          Créer une intervention
          <MdModeEdit size={25} className="ml-2" />
        </Button>
      </>
    );
  }

  render() {
    const interventions = this.props.data.interventions;
    const { state } = this.props.history.location;
    // Filtres envoyés en paramètre par l'écran précédent (modal accueil leaflet)
    const activeFilters =
      state && state.activeFilters ? state.activeFilters : undefined;
    return (
      <Switcher
        config={[{ nom: "interventions", liste: interventions }]}
        rowClickCallback={this.openEdit.bind(this)}
        buttons={this.getButtons()}
      >
        <Pullable onRefresh={() => this.props.data.sync(true)}>
          <Container fluid className="mt-3">
            <Row>
              <Col className="mb-3">
                <Filters
                  pathName="interventions"
                  listName="interventions"
                  list={interventions}
                  label="Interventions"
                  activeFilters={activeFilters}
                  saveFilters
                >
                  <Intervention
                    ref={this.listRef}
                    handleClick={this.openEdit.bind(this)}
                  />
                </Filters>
              </Col>
            </Row>
          </Container>
          <ScrollButtons />
        </Pullable>
      </Switcher>
    );
  }
}

export default WithContext(Interventions);
