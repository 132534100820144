import React from "react";
import {
  GiFarmTractor,
  GiGroundSprout,
  GiFertilizerBag,
  GiSpray,
  GiFarmer,
  GiTap,
  GiCorn,
  GiRoad,
  GiBinoculars,
  GiMoneyStack,
  GiPiggyBank,
  GiCalendar,
  GiRoundSilo,
  GiBlackBook,
  GiHills,
  GiRiver,
  GiPieChart,
} from "react-icons/gi";

import { FaWarehouse } from "react-icons/fa";

const Icons = {
  GiFarmTractor: GiFarmTractor,
  GiGroundSprout: GiGroundSprout,
  GiFertilizerBag: GiFertilizerBag,
  GiSpray: GiSpray,
  GiFarmer: GiFarmer,
  GiTap: GiTap,
  GiCorn: GiCorn,
  GiRoad: GiRoad,
  GiBinoculars: GiBinoculars,
  GiMoneyStack: GiMoneyStack,
  GiPiggyBank: GiPiggyBank,
  GiCalendar: GiCalendar,
  GiRoundSilo: GiRoundSilo,
  GiBlackBook: GiBlackBook,
  GiHills: GiHills,
  GiRiver: GiRiver,
  GiPieChart: GiPieChart,
  FaWarehouse: FaWarehouse,
};

const Icon = (props) => {
  const { iconName, size, color, float, verticalAlign } = props;
  let icon;
  if (Icons[iconName]) {
    icon = React.createElement(Icons[iconName], {
      style: {
        verticalAlign: verticalAlign,
        float: float,
      },
    });
  } else {
    icon = <img src={iconName} height={size} alt={iconName} />;
  }
  return <div style={{ fontSize: size, color: color }}>{icon}</div>;
  // return <div style={{ fontSize: size, height: size, color: color }}>{icon}</div>;
};

export default Icon;
