import React from "react";
// global context (this.props.data)
import { WithContext } from "store/DataProvider";
// react-bootstrap components
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
// custom components
import Input from "inputs/input";
// react-icons
import { MdEdit } from "react-icons/md";

class CreationProduit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      categorie: [],
      unite: "",
      prix: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.validate = this.validate.bind(this);
    this.save = this.save.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const type = target.type;
    let value = target.value;
    const name = target.name;
    // pour pouvoir accéder au nativeEvent
    if (type === "select-one") e.persist();

    this.setState((state) => {
      let newValue = value;
      if (type === "select-one") {
        if (value) {
          const nativeTarget = e.nativeEvent.target;
          const index = nativeTarget.selectedIndex;
          const label = nativeTarget[index].text;
          // ajouter l'id et le label de l'option sélectionnée
          newValue = [{ id: Number(value), label: label }];
        } else {
          newValue = [];
        }
      } else if (type === "number" && value !== "") {
        // tronquer au nombre de décimales souhaité
        const decimals = target.getAttribute("decimals");
        newValue = +Number(target.value).toFixed(decimals);
      } else if (type === "radio") {
        newValue = Number(value);
      }
      state[name] = newValue;
      return state;
    });
  }

  handleReactSelectChange(name, option) {
    this.setState((state) => {
      state[name] = [option];
      return state;
    });
  }

  validate() {
    let data = { ...this.state };

    let isValid = true;
    if (!data.label) {
      isValid = false;
      this.showMessage("Vous n'avez pas renseigné le nom du produit.");
    }
    if (!data.categorie.length) {
      isValid = false;
      this.showMessage("Vous n'avez pas renseigné la catégorie du produit.");
    }

    if (isValid) {
      this.save({ produit: data, utilisateur: this.props.data.utilisateur });
    }
  }

  async save(item) {
    const isSuccess = await this.props.data.sendRequest("creationProduit", {
      data: item,
    });
    if (isSuccess) {
      this.showMessage(
        "Votre demande de création de produit a bien été envoyée. Nous vous enverrons un mail de confirmation une fois le produit disponible dans cérédoc."
      );
    }
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  showMessage(message) {
    this.props.data.showNotification({
      type: "warning",
      title: message,
    });
  }

  render() {
    return (
      <Container className="mt-3">
        <Row>
          <h1>Formulaire de demande de création d'un produit</h1>
        </Row>
        <Row>
          <h4>
            Renseignez le produit que vous souhaitez créer, puis envoyer votre
            demande :
          </h4>
        </Row>

        <Row>
          <Card.Body>
            <Form>
              {inputs.map((input, index) => {
                return (
                  <Input
                    key={index}
                    input={input}
                    value={this.state[input.name]}
                    options={input.options}
                    handleChange={this.handleChange}
                    handleReactSelectChange={this.handleReactSelectChange}
                  />
                );
              })}
            </Form>
          </Card.Body>
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            <Button
              variant="warning"
              onClick={this.validate}
              className="float-right"
            >
              Enregistrer
              <MdEdit size={25} className="ml-2" />
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default WithContext(CreationProduit);

const inputs = [
  {
    type: "text",
    label: "Nom du produit",
    name: "label",
  },
  {
    type: "react-select",
    label: "Catégorie",
    name: "categorie",
    options: [
      { id: 1, label: "Produit phytosanitaire" },
      { id: 2, label: "Semence" },
      { id: 3, label: "Engrais" },
      { id: 4, label: "Amendement" },
      { id: 5, label: "Oligo-Biostimulant" },
    ],
  },
  {
    type: "text",
    label: "Unité",
    name: "unite",
  },
  {
    type: "number",
    label: "Prix",
    name: "prix",
    step: "0,01",
    unit: "€/unité",
  },
];
