import React, { useContext } from "react";
// create intervention context
import { CreateContext } from "interventions/createProvider";
import { ParcellesContext } from "parcelles/provider";
// custom components
import { FormDoubleInputs } from "interventions/forms.js";
// react-bootstrap components
import { Card, Form } from "react-bootstrap";

const Recolte = ({ isEditable }) => {
  const { intervention, updateItem, getQuantityPerSurface } =
    useContext(CreateContext);
  const { getSurfaceTotale } = useContext(ParcellesContext);
  const { recoltes } = intervention;
  return (
    <Card.Body>
      <Form>
        <FormDoubleInputs
          step={".01"}
          selectedOptions={recoltes}
          updateItem={updateItem.bind(this, "recoltes", getSurfaceTotale)}
          getQuantityPerSurface={getQuantityPerSurface.bind(
            this,
            "recoltes",
            getSurfaceTotale
          )}
          isEditable={isEditable}
        />
      </Form>
    </Card.Body>
  );
};

export default Recolte;
