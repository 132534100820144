var CryptoJS = require("crypto-js");

export default function CryptoJSAesEncrypt(passphrase, plain_text) {
  var salt = CryptoJS.lib.WordArray.random(256);
  var iv = CryptoJS.lib.WordArray.random(16);

  var key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: 64 / 8,
    iterations: 999,
  });

  var encrypted = CryptoJS.AES.encrypt(plain_text, key, { iv: iv });

  var data = {
    ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
    salt: CryptoJS.enc.Hex.stringify(salt),
    iv: CryptoJS.enc.Hex.stringify(iv),
  };

  return JSON.stringify(data);
}
