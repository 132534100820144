import React, { useState, useContext } from "react";
// global context
import { DataContext } from "store/DataProvider";
// bootstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Collapse,
  Form,
} from "react-bootstrap";
// custom components
import Input from "../inputs/input";
import Tableau from "./tableau";
// react-icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const cardBordersFix = {
  borderBottom: "0px",
  borderTop: "1px solid rgba(0, 0, 0, 0.125)",
};

const TableauFiltrable = ({ data }) => {
  const dataContext = useContext(DataContext);
  const { code, titre, sousTitre, filtres, boutons, tableau } = data;

  let initialValues = {};
  // for each input, set initial values
  filtres.forEach((input) => {
    initialValues[input.name] = input.value;
  });

  // getter et setter des valeurs des filtres
  const [values, setValues] = useState(initialValues);
  // getter et setter du tableau
  const [showGrid, setShowGrid] = useState(false); // useState(tableau.config.rowData.length > 0)
  const [tableauConfig, setTableauConfig] = useState(tableau.config);
  // getter et setter des accordéons
  const [filtersAccordionOpen, setFiltersAccordionOpen] = useState(true);
  const [gridAccordionOpen, setGridAccordionOpen] = useState(false);

  const handleChange = (e) => {
    const target = e.target;
    const type = target.type;
    let value = target.value;
    const name = target.name;
    // pour pouvoir accéder au nativeEvent
    if (type === "select-one") e.persist();

    let newValue = value;
    if (type === "select-one") {
      if (value) {
        const nativeTarget = e.nativeEvent.target;
        const index = nativeTarget.selectedIndex;
        const label = nativeTarget[index].text;
        // ajouter l'id et le label de l'option sélectionnée
        newValue = [{ id: Number(value), label: label }];
      } else {
        newValue = [];
      }
    } else if (type === "number" && value !== "") {
      // tronquer au nombre de décimales souhaité
      const decimals = target.getAttribute("decimals");
      newValue = +Number(target.value).toFixed(decimals);
    } else if (type === "radio") {
      newValue = Number(value);
    }
    setValues({ ...values, [name]: newValue });
  };

  const handleReactSelectChange = (name, options) => {
    setValues({ ...values, [name]: options });
  };

  const buttonClick = async (bouton) => {
    if (isValid()) {
      // démonter le composant tableau (pour reset le layout)
      setShowGrid(false);
      // cacher le tableau
      setGridAccordionOpen(false);
      // récupérer les données du tableau (valeurs des filtres dans le body)
      let response = await dataContext.sendRequest(bouton.requete, {
        data: { code, ...values },
      });

      // console.log("bouton.requete");
      // console.log(bouton.requete);

      // console.log("response");
      // console.log(response);

      // let response = {
      //   autoGroupColumnDef: {
      //     // donner un nom à la colonne groupée (sinon "Group" par defaut)
      //     headerName: "Culture",
      //   },
      //   suppressAggFuncInHeader: true, // désactiver le renommage automatique des colonnes agrégées (sinon SUM(surface))
      //   // groupIncludeTotalFooter: true, // afficher le total final en bas de tableau
      //   columnDefs: [
      //     {
      //       field: "parcelle",
      //       aggFunc: "count",
      //     },
      //     {
      //       field: "culture", // on groupe par cultures
      //       rowGroup: true,
      //       hide: true, // cacher cette colonne car déjà regroupée
      //     },
      //     {
      //       field: "surface",
      //       aggFunc: "sum", // fonction d'agrégation native ag-grid (cumule les valeurs des surfaces)
      //       decimales: 2, // on peut ajouter le nombre de décimales souhaité
      //     },
      //     {
      //       field: "ift",
      //       // fonction d'agrégation personnalisée
      //       // pAvg est dévelopée côté app (cette fonction js ne peut pas être renvoyée par le serveur)
      //       // pAvg effectue le calcul d'une moyenne pondérée
      //       aggFunc: "pAvg",
      //       // basé sur ces propriétés
      //       aggFuncConfig: {
      //         dividende: ["surface", "ift"], // dividende = surface x ift
      //         diviseur: "surface", // diviseur = surface
      //         // moyenne pondérée = dividende / diviseur = (surface x ift) / surface
      //       },
      //       decimales: 2, // on peut ajouter le nombre de décimales souhaité
      //     },
      //     {
      //       field: "note",
      //       aggFunc: "avg",
      //       decimales: 0, // on peut ajouter le nombre de décimales souhaité
      //     },
      //   ],
      //   rowData: [
      //     {
      //       parcelle: "Ilot 1",
      //       culture: "Blé tendre",
      //       surface: 1.0000000001, // j'ai mis des nombre avec des décimales pour démo
      //       ift: 2.0000000001, // j'ai mis des nombre avec des décimales pour démo
      //       note: 8.001, // j'ai mis des nombre avec des décimales pour démo
      //     },
      //     {
      //       parcelle: "Ilot 2",
      //       culture: "Blé tendre",
      //       surface: 2,
      //       ift: 2.5,
      //       note: 12,
      //     },
      //     {
      //       parcelle: "Ilot 3",
      //       culture: "Blé tendre",
      //       surface: 20,
      //       ift: 4,
      //       note: 9,
      //     },
      //     {
      //       parcelle: "Ilot 5",
      //       culture: "Tournesol",
      //       surface: 2,
      //       ift: 4,
      //       note: 15,
      //     },
      //     {
      //       parcelle: "Ilot 6",
      //       culture: "Tournesol",
      //       surface: 10,
      //       ift: 1.5,
      //       note: 5,
      //     },
      //   ],
      //   exportCSV: true,
      //   exportPDF: true,
      //   // url du PDF qui devra être renvoyée par le serveur (dynamique)
      //   urlPDF: "https://www.agrodoc.fr/public/LivretDesServicesOct2019.pdf",
      //   // propriété nécessaire pour que l'application puisse activer certaines fonctionnalités ag-grid
      //   tableauFiltrable: true,
      // };

      // ajouter les propriétés nécessaires au fonctionnement des agrégations customisées
      // dans la définition des colonnes
      response.columnDefs = addAggFuncProps(response.columnDefs);
      // fonction d'agrégation personnalisées
      response.aggFuncs = { count, pAvg };
      // styles personnalisés
      response.getRowStyle = boldRowGroups;

      // mettre à jour les données du tableau
      setTableauConfig(response);
      // cacher les filtres
      setFiltersAccordionOpen(false);
      // afficher le tableau
      setShowGrid(true);
      setGridAccordionOpen(true);
    }
  };

  const isValid = () => {
    let isValidForm = true;
    filtres
      .filter((input) => input.required)
      .forEach((requiredInput) => {
        if (
          (requiredInput.type === "react-select" &&
            !values[requiredInput.name].length) ||
          (requiredInput.type !== "react-select" && !values[requiredInput.name])
        ) {
          dataContext.showNotification({
            type: "warning",
            title: `Le champ ${requiredInput.label} est requis`,
          });
          isValidForm = false;
        }
      });
    return isValidForm;
  };

  return (
    <Container className="fillAvailableHeight mt-3">
      <Card
        onClick={() => setFiltersAccordionOpen(!filtersAccordionOpen)}
        style={cardBordersFix}
      >
        <Card.Header
          className="d-flex justify-content-between align-items-center text-center"
          style={{ height: "60px" }}
        >
          <div />
          <h5 className="mb-0">Filtres</h5>
          {filtersAccordionOpen ? (
            <FaChevronUp size={20} />
          ) : (
            <FaChevronDown size={20} />
          )}
        </Card.Header>
      </Card>
      <Collapse in={filtersAccordionOpen}>
        <div>
          <Card.Body>
            <Row>
              <Col className="mt-3">
                <h4>{titre}</h4>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h6>{sousTitre}</h6>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form>
                  {filtres.map((input, index) => {
                    let options = [];
                    if (input.options) {
                      options = input.options;
                    }
                    return (
                      <Input
                        key={index}
                        input={input}
                        value={values[input.name]}
                        options={options}
                        handleChange={handleChange}
                        handleReactSelectChange={handleReactSelectChange}
                        // gridSize={{ label: "6", input: "6" }}
                      />
                    );
                  })}
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                {boutons.map((bouton, index) => (
                  <Button
                    key={index}
                    variant="warning"
                    onClick={() => buttonClick(bouton)}
                  >
                    {bouton.titre}
                  </Button>
                ))}
              </Col>
            </Row>
          </Card.Body>
        </div>
      </Collapse>
      <Card
        onClick={() => setGridAccordionOpen(!gridAccordionOpen)}
        style={cardBordersFix}
      >
        <Card.Header
          className="d-flex justify-content-between align-items-center text-center"
          style={{ height: "60px" }}
        >
          <div />
          <h5 className="mb-0">Tableau</h5>
          {gridAccordionOpen ? (
            <FaChevronUp size={20} />
          ) : (
            <FaChevronDown size={20} />
          )}
        </Card.Header>
      </Card>
      {showGrid && gridAccordionOpen && (
        <Collapse className="fillAvailableHeight" in={gridAccordionOpen}>
          <div>
            <Card.Body className="fillAvailableHeight">
              <Row className="fillAvailableHeight">
                <Col>
                  <Tableau data={tableauConfig} />
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Collapse>
      )}
    </Container>
  );
};

export default TableauFiltrable;

// fonction d'agrégation qui cumule les valeurs ou cumule le nb de cellules
const count = (params) => {
  let result = 0;
  // pour chaque valeur de cellule
  params.values.forEach((value) => {
    if (typeof value === "number") {
      // si c'est un nombre, les cumuler
      // result = cumul des valeurs de la colonne
      result += value;
    } else if (typeof value === "string") {
      // si c'est un string, on ajoute 1
      // result = cumul du nombre de cellules de la colonne
      result += 1;
    }
  });
  return result;
};

// fonction d'agrégation qui effectue un moyenne pondérée
const pAvg = (params) => {
  // cumuls
  let dividendeSum = 0;
  let diviseurSum = 0;
  // pour chaque valeur de cellule
  params.values.forEach((value) => {
    // cumuler les valeurs
    if (value && value.dividende) {
      dividendeSum += value.dividende;
    }
    // cumuler les valeurs
    if (value && value.diviseur) {
      diviseurSum += value.diviseur;
    }
  });
  return {
    // surface: surfaceSum,
    // hidden: hiddenSum,
    toString: () => {
      return dividendeSum && diviseurSum ? dividendeSum / diviseurSum : 0;
    },
  };
};

const boldRowGroups = (params) => {
  // pour les regroupements de ligne uniquement (data = undefined dans ce cas)
  if (params.data === undefined) {
    return { fontWeight: "bold" };
  }
};

// ajouter les propriétés nécessaires au fonctionnement des agrégations customisées
// dans la définition des colonnes
const addAggFuncProps = (columnDefs) => {
  columnDefs.forEach((c) => {
    if (c.aggFunc === "pAvg") {
      // récupérer les valeurs nécessaires au calcul de la moyenne pondérée
      const { aggFuncConfig, field } = c;
      // définir un value getter personnalisé
      c.valueGetter = (params) => {
        // no need to handle group levels - calculated in the 'pAvg' function
        if (!params.node.group) {
          // objet qui sera retourné dans ag-grid
          return {
            // renvoyer les valeurs nécessaires au calcul de la moyenne pondérée
            dividende:
              params.data[aggFuncConfig.dividende[0]] *
              params.data[aggFuncConfig.dividende[1]],
            diviseur: params.data[aggFuncConfig.diviseur],
            // fonction toString = la valeur de chaque cellule
            toString: () => params.data[field],
          };
        }
      };
    }
  });
  return columnDefs;
};
