import React from "react";
import Alert from "react-bootstrap/Alert";
// import Button from "react-bootstrap/Button";

class AlertSmall extends React.Component {
  render() {
    return (
      <Alert dismissible={this.props.dismissible} variant={this.props.variant}>
        <span className="h6">{this.props.title} </span>
        {this.props.message}
        {/* {this.props.title} {this.props.message} */}
      </Alert>
    );
  }
}

export default AlertSmall;
