import React, { createContext } from "react";
// intervention datas
import { getCategories } from "interventions/datas.js";
// intervention helper
import { Helper } from "interventions/helpers.js";
// time functions
import { getTimeString, getDurationInMinutes } from "interventions/utils.js";
// momentjs
import moment from "moment";

export const CreateContext = createContext({
  typeId: "",
  listeProduits: [],
  listeMateriel: [],
  listeMainOeuvre: [],
});

class CreateInterventionProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.data,
      intervention: getInterventionData(props.data.typeId),
      vueProduits: 1,
      categorieProduitsId: -1,
      categorieProduits: getCategories(
        props.data.listeProduits,
        props.data.typeId
      )[0],
      setIntervention: (intervention) => {
        this.setState({ intervention: intervention });
      },
      handleInputChange: (event) => {
        const target = event.target;
        let value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (["number", "select-one", "radio"].includes(target.type)) {
          value = Number(value);
          if (!value) {
            value = "";
          }
        }

        this.setState((state) => {
          state.intervention[name] = value;
          return state;
        });

        // règle spécifique :
        if (name === "date") {
          // si date > aujourd'hui -> statut = prévu
          // sinon date <= aujourd'hui -> statut = effectué
          let statutId = moment(value).isAfter(moment()) ? 1 : 3;
          this.setState((state) => {
            state.intervention.statutId = statutId;
            return state;
          });
        }
      },
      updateCategory: (listName, selectedOption) => {
        this.setState({
          [listName]: selectedOption,
          categorieProduitsId: selectedOption.id,
        });
      },
      updateList: (listName, selectedOptions) => {
        // uniquement pour materiels & mainOeuvres
        if (listName === "materiels" || listName === "mainOeuvres") {
          // si ajout d'un item
          if (selectedOptions.length) {
            // chercher l'item ayant la plus grande quantite
            let maxItem = [];
            if (this.state.intervention.materiels) {
              maxItem.push(...this.state.intervention.materiels);
            }
            if (this.state.intervention.mainOeuvres) {
              maxItem.push(...this.state.intervention.mainOeuvres);
            }
            // si item trouvé
            if (maxItem.length > 0) {
              maxItem = maxItem.reduce(
                (prev, current) =>
                  prev.quantite > current.quantite ? prev : current,
                0
              );
              // copier la dernière option sélectionnée
              let selectedOption = selectedOptions[selectedOptions.length - 1];
              // dernière option sélectionnée = quantités max saisie
              selectedOption.quantite = maxItem.quantite;
            }
          }
          this.setState((state) => {
            state.intervention[listName] = selectedOptions;
            return state;
          });
        } else if (listName === "produits") {
          this.setState((state) => {
            const produitsCatalogue = this.state.filtrerProduits("catalogue");
            state.intervention.produits = [
              ...produitsCatalogue,
              ...selectedOptions,
            ];
            return state;
          });
        } else if (listName === "produitsManquants") {
          if (selectedOptions[selectedOptions.length - 1]) {
            selectedOptions[selectedOptions.length - 1].catalogue = true;
          }
          this.setState((state) => {
            const produitsListe = this.state.filtrerProduits("liste");
            state.intervention.produits = [
              ...produitsListe,
              ...selectedOptions,
            ];
            return state;
          });
        } else {
          this.setState((state) => {
            state.intervention[listName] = selectedOptions;
            return state;
          });
        }
      },
      filtrerProduits: (filtre) => {
        return this.state.intervention.produits.filter(
          (produitsSelectionne) => {
            if (filtre === "liste") {
              return !produitsSelectionne.catalogue;
            } else if (filtre === "catalogue") {
              return produitsSelectionne.catalogue;
            }
            return false;
          }
        );
      },
      getQuantityPerSurface: (listName, getSurfaceTotale, quantity) => {
        let decimals = 0;
        if (
          listName === "produits" ||
          listName === "recoltes" ||
          listName === "charges"
        ) {
          decimals = 2;
        }

        if (
          listName === "produits" &&
          (Helper.isTraitementPhyto(this.state.typeId) ||
            Helper.isSemis(this.state.typeId))
        ) {
          decimals = 3;
        }

        const surfaceTotale = getSurfaceTotale();
        let quantityPerSurface;

        if (quantity === "") {
          return "";
        }

        if (!surfaceTotale) {
          if (listName !== "materiels" && listName !== "mainOeuvres") {
            quantityPerSurface = Number(0).toFixed(decimals);
          } else {
            quantityPerSurface = "00:00";
          }
        } else {
          quantityPerSurface = Number(
            (quantity / surfaceTotale).toFixed(decimals)
          );
          if (listName === "materiels" || listName === "mainOeuvres") {
            quantityPerSurface = getTimeString(quantityPerSurface);
          }
        }
        return quantityPerSurface;
      },
      getQuantityGrainesPerSurface: (getSurfaceTotale, item) => {
        return item.quantiteGrainesPerSurface;
      },
      updateItem: (listName, getSurfaceTotale, event) => {
        const target = event.target;
        const id = target.getAttribute("data-id");
        const name = target.getAttribute("name");
        const nbGrainesParDose = target.getAttribute("data-dose-graines");
        const surfaceTotale = getSurfaceTotale();

        let decimals = 0;
        if (
          listName === "produits" ||
          listName === "recoltes" ||
          listName === "charges"
        ) {
          decimals = 2;
        }

        if (
          listName === "produits" &&
          (Helper.isTraitementPhyto(this.state.typeId) ||
            Helper.isSemis(this.state.typeId))
        ) {
          decimals = 3;
        }

        // Find item index
        const itemIndex = this.state.intervention[listName].findIndex(
          (item) => item.id.toString() === id
        );
        // Copy item properties (to avoid to mutate options list)
        let item = { ...this.state.intervention[listName][itemIndex] };
        const isQuantitePerSurface = item.hasOwnProperty("quantitePerSurface");
        // Set item properties
        if (name === "quantite") {
          if (listName !== "materiels" && listName !== "mainOeuvres") {
            item.quantite = Number(target.value);
            if (surfaceTotale === 0) {
              item.quantitePerSurface = Number(0).toFixed(decimals);
            } else {
              item.quantitePerSurface = Number(
                (Number(target.value) / Number(surfaceTotale)).toFixed(decimals)
              );
              if (nbGrainesParDose) {
                item.quantiteGrainesPerSurface = Number(
                  (Number(target.value) * nbGrainesParDose) / surfaceTotale
                ).toFixed(0);
              }
            }
          } else {
            item.quantite = getDurationInMinutes(target.value);
          }
        } else if (name === "quantitePerSurface") {
          if (surfaceTotale === 0) {
            if (listName !== "materiels" && listName !== "mainOeuvres") {
              item.quantite = Number(0).toFixed(decimals);
              item.quantitePerSurface = Number(0).toFixed(decimals);
            } else {
              item.quantite = "00:00";
            }
          } else {
            if (listName !== "materiels" && listName !== "mainOeuvres") {
              item.quantite = Number(
                (Number(target.value) * Number(surfaceTotale)).toFixed(decimals)
              );
              item.quantitePerSurface = Number(
                (item.quantite / surfaceTotale).toFixed(decimals)
              );
              if (nbGrainesParDose) {
                item.quantiteGrainesPerSurface = Number(
                  item.quantitePerSurface * nbGrainesParDose
                ).toFixed(0);
              }
            } else {
              item.quantite = Number(
                (
                  Number(getDurationInMinutes(target.value)) *
                  Number(surfaceTotale)
                ).toFixed(decimals)
              );
            }
            if (listName === "materiels" || listName === "mainOeuvres") {
              item.quantite = getTimeString(item.quantite);
              item.quantite = getDurationInMinutes(item.quantite);
            }
          }
        } else if (name === "quantiteGrainesPerSurface") {
          item.quantiteGrainesPerSurface = Number(target.value);
          item.quantitePerSurface = Number(
            item.quantiteGrainesPerSurface / nbGrainesParDose
          ).toFixed(decimals);
          item.quantite = Number(
            item.quantitePerSurface * surfaceTotale
          ).toFixed(decimals);
        } else if (name.includes("typeSemence")) {
          item.typeSemence = Number(target.value);
        } else if (name === "pmg") {
          item.pmg = Number(target.value);
        } else if (name.includes("origineStock")) {
          item.origineStock = Number(target.value);
        }

        if (target.value === "") {
          item.quantite = "";
          item.quantitePerSurface = "";
        }

        // ne pas ajouter la quantité surfacique si pas présente dans l'item
        if (!isQuantitePerSurface) {
          delete item.quantitePerSurface;
        }

        // Copy array (to avoid to mutate original array)
        let list = [...this.state.intervention[listName]];
        // Set updated item
        list[itemIndex] = item;
        // Update list
        this.setState((state) => {
          state.intervention[listName] = list;
          return state;
        });
      },
    };
  }

  render() {
    return (
      <CreateContext.Provider value={this.state}>
        {this.props.children}
      </CreateContext.Provider>
    );
  }
}

export default CreateInterventionProvider;

export const WithCreateIntervention = (Component) => {
  return React.forwardRef((props, ref) => {
    return (
      <CreateContext.Consumer>
        {(value) => <Component {...props} ref={ref} create={value} />}
      </CreateContext.Consumer>
    );
  });
};

const getInterventionData = (interventionTypeId) => {
  let intervention = {
    typeId: interventionTypeId,
    date: moment().format("YYYY-MM-DDTHH:mm"),
    observation: "",
  };
  // Fertilisation, Traitement phyto
  if (Helper.isProduits(interventionTypeId)) {
    intervention.produits = [];
  }
  // !Observation, !Autres produits, !Autres charges
  if (Helper.isMateriel(interventionTypeId)) {
    intervention.materiels = [];
  }
  // !Autres produits, !Autres charges
  if (Helper.isMainOeuvre(interventionTypeId)) {
    intervention.mainOeuvres = [];
  }
  // Autres charges
  if (Helper.isCharges(interventionTypeId)) {
    intervention.charges = [];
  }
  // Récolte
  if (Helper.isRecolte(interventionTypeId)) {
    intervention.recoltes = [
      {
        id: 31,
        label: "Récolte",
        culture: "",
        unit: "T",
        quantite: "",
      },
    ];
  }
  // !Observation
  if (Helper.isStatut(interventionTypeId)) {
    intervention.statutId = 3;
  }
  // Irrigation
  if (Helper.isIrrigation(interventionTypeId)) {
    intervention.quantiteEau = "";
  }
  // Traitement phyto
  if (Helper.isTraitementPhyto(interventionTypeId)) {
    intervention.volumeBouillie = "";
  }
  // Travail du sol, Façon culturale, Transport
  if (Helper.isTypeOperation(interventionTypeId)) {
    intervention.typeOperationId = "";
  }
  // Semis
  if (Helper.isSemis(interventionTypeId)) {
    intervention.semisDe = 1;
  }
  // Prix de vente
  if (Helper.isPrixDeVente(interventionTypeId)) {
    intervention.statutPrixId = 1;
    intervention.prixDeVente = 0;
  }
  return intervention;
};
