import React from "react";
// router
import { withRouter } from "react-router-dom";
// react-bootstrap components
import { ListGroup, Badge } from "react-bootstrap";

const Liste = ({ data, history }) => {
  const rowClickCallback = (rowData) => {
    if (data.rowClick) {
      history.push(data.rowClick, rowData.data);
    }
  };
  return (
    <ListGroup as="ul" className="mt-3 w-100">
      {data.rowData.map((row, i1) => {
        return (
          <ListGroup.Item
            key={i1}
            as="li"
            action
            onClick={() => rowClickCallback(row)}
          >
            <h6 className="mb-0"> {row[data.columnDefs[0].field]} </h6>
            <div className="d-flex flex-row">
              {data.columnDefs.map((def, i2) => (
                <div key={i2}>
                  {i2 !== 0 && (
                    <h6 className="mb-0 mr-1">
                      <Badge variant="warning">{row[def.field]}</Badge>
                    </h6>
                  )}
                </div>
              ))}
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default withRouter(Liste);
