import React, { useContext } from "react";
// create intervention context
import { CreateContext } from "interventions/createProvider";
import { ParcellesContext } from "parcelles/provider";
// custom components
import { FormDoubleInputs } from "interventions/forms.js";
// react-bootstrap components
import { Card, Form } from "react-bootstrap";
// react-select component
import Select from "react-select";
// intervention datas
import { getCategories, getCategorie } from "interventions/datas.js";

const Charges = ({ isEditable }) => {
  const {
    listeProduits,
    categorieProduitsId,
    categorieProduits,
    typeId,
    intervention,
    updateCategory,
    updateList,
    updateItem,
    getQuantityPerSurface
  } = useContext(CreateContext);
  const { getSurfaceTotale } = useContext(ParcellesContext);
  const categories = getCategories(listeProduits, typeId);
  const categorie = getCategorie(listeProduits, typeId, categorieProduitsId);
  const { charges } = intervention;
  return (
    <Card.Body>
      <Select
        isSearchable={false}
        getOptionValue={option => option.id}
        options={categories}
        value={categorieProduits}
        onChange={updateCategory.bind(this, "categorieProduits")}
        className="mb-2"
        isDisabled={!isEditable}
      />
      <Select
        isMulti
        placeholder="Ajouter des charges"
        getOptionValue={option => option.id}
        options={categorie}
        value={charges}
        onChange={updateList.bind(this, "charges")}
        className="mb-2"
        isDisabled={!isEditable}
      />
      <Form>
        <FormDoubleInputs
          step={".01"}
          getQuantityPerSurface={getQuantityPerSurface.bind(
            this,
            "charges",
            getSurfaceTotale
          )}
          selectedOptions={charges}
          updateItem={updateItem.bind(this, "charges", getSurfaceTotale)}
          isEditable={isEditable}
        />
      </Form>
    </Card.Body>
  );
};

export default Charges;
