import React from "react";
// other generic components
import Wrapper from "generique/wrapper";
import Onglets from "generique/onglets";
import Tableau from "generique/tableau";
import Titre from "generique/titre";
import Boutons from "generique/boutons";
// import GroupeBoutons from "generique/groupeBoutons";
import Filtres from "generique/filtres";
import TableauFiltrable from "generique/tableauFiltrable";
import Liens from "generique/liens";
import Cartographie from "generique/cartographie";
// custom components
import AlertBig from "../alerts/AlertBig.js";
// react-bootstrap components
import { Container } from "react-bootstrap";

const Generique = ({ data }) => {
  let Component;
  if (data.type === "wrapper") {
    Component = Wrapper;
  } else if (data.type === "boutons") {
    Component = Boutons;
  } else if (data.type === "onglets") {
    Component = Onglets;
  } else if (data.type === "titre") {
    Component = Titre;
  } else if (data.type === "groupeBoutons") {
    // Component = GroupeBoutons;
    Component = Onglets;
  } else if (data.type === "tableau") {
    Component = Tableau;
  } else if (data.type === "filtres") {
    Component = Filtres;
  } else if (data.type === "tableauFiltrable") {
    Component = TableauFiltrable;
  } else if (data.type === "liens") {
    Component = Liens;
  } else if (data.type === "cartographie") {
    Component = Cartographie;
  }
  if (Component) {
    return <Component data={data.config} />;
  }
  return (
    <Container fluid>
      <AlertBig
        dismissible={false}
        title={"Fonctionnalité en cours de développement"}
        message={"si besoin contactez l’assistance Céré D’Oc au 05 62 07 33 94"}
        variant={"warning"}
      />
    </Container>
  );
};

export default Generique;
