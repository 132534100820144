import React, { useState } from "react";
// custom component
import General from "interventions/accordions/general";
import Parcelles from "interventions/accordions/parcelles";
import PrixDeVente from "interventions/accordions/prixDeVente";
import Produits from "interventions/accordions/produits";
import Recolte from "interventions/accordions/recolte";
import Charges from "interventions/accordions/charges";
import Materiel from "interventions/accordions/materiel";
import MainOeuvre from "interventions/accordions/mainOeuvre";
import Details from "interventions/accordions/details";
// react-bootstrap components
import { Card, Collapse } from "react-bootstrap";
// react-icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// intervention helper
import { Helper } from "interventions/helpers.js";

const cardBordersFix = {
  borderBottom: "0px",
  borderTop: "1px solid rgba(0, 0, 0, 0.125)"
};

const Accordions = ({ interventionTypeId, isUpdate, isEditable }) => {
  const accordionsData = [
    {
      title: "Général",
      child: <General />,
      isVisible: true
    },
    {
      title: "Parcelles",
      child: <Parcelles />,
      isVisible: true
    },
    {
      title: "Prix de vente",
      child: <PrixDeVente />,
      isVisible: Helper.isPrixDeVente(interventionTypeId)
    },
    {
      title: "Produits",
      child: <Produits />,
      isVisible: Helper.isProduits(interventionTypeId)
    },
    {
      title: "Récolte",
      child: <Recolte />,
      isVisible: Helper.isRecolte(interventionTypeId)
    },
    {
      title: "Charges",
      child: <Charges />,
      isVisible: Helper.isCharges(interventionTypeId)
    },
    {
      title: "Matériel",
      child: <Materiel />,
      isVisible: Helper.isMateriel(interventionTypeId)
    },
    {
      title: "Main d'oeuvre",
      child: <MainOeuvre />,
      isVisible: Helper.isMainOeuvre(interventionTypeId)
    },
    {
      title: "Détails de l'intervention",
      child: <Details />,
      isVisible: true
    }
  ];

  const [accordions, setAccordions] = useState(
    accordionsData.map(accordion => (isUpdate ? true : false))
  );

  const toggle = index => {
    let newAccordions = [...accordions];
    newAccordions[index] = !newAccordions[index];
    setAccordions(newAccordions);
  };

  return accordionsData.map((accordion, index) => {
    const { title, child, isVisible } = accordion;
    const isOpen = accordions[index];
    const icon = isOpen ? (
      <FaChevronUp size={20} />
    ) : (
      <FaChevronDown size={20} />
    );
    return (
      isVisible && (
        <div key={index} className="w-100">
          <Card onClick={() => toggle(index)} style={cardBordersFix}>
            <Card.Header
              className="d-flex justify-content-between align-items-center text-center"
              style={{ height: "60px" }}
            >
              <div />
              <h5 className="mb-0"> {title} </h5>
              {icon}
            </Card.Header>
          </Card>
          <Collapse in={isOpen}>
            <div>
              {React.cloneElement(child, {
                isOpen: isOpen,
                isEditable: isEditable,
              })}
            </div>
          </Collapse>
        </div>
      )
    );
  });
};

export default Accordions;
