import React, { useContext, useRef, useEffect, useState } from "react";
// parcelles actives context
import { ParcellesContext } from "parcelles/provider";
// custom components
import MapDraw from "map/mapDraw.js";
import Filters from "filters/component2";
import Parcelle from "parcellaire/parcelle.js";
import Groupes from "parcellaire/groupes.js";
// react-bootstrap components
import {
  Row,
  Col,
  ListGroup,
  Badge,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "react-bootstrap";

const Parcelles = ({ isOpen, isEditable }) => {
  const { parcelles, parcellesActives, getSurfaceTotale } =
    useContext(ParcellesContext);
  const map = useRef();
  const [vueParcelles, setVueParcelles] = useState(1);
  const [showMap, setShowMap] = useState(true);

  const handleRadioChange = (value) => {
    setVueParcelles(value);
  };

  useEffect(() => {
    if (isOpen) {
      map.current && map.current.updateUI();
    }
  }, [isOpen]);

  return (
    <>
      <Row noGutters>
        <ListGroup className="w-100">
          <ListGroup.Item variant="success" style={{ minHeight: "80px" }}>
            <div className="d-flex justify-content-between">
              <h6>{parcellesActives.length} parcelle(s)</h6>
              <h6>Surface totale : {getSurfaceTotale()} ha</h6>
            </div>
            <div className="d-flex flex-wrap">
              {parcellesActives.map((parcelle) => (
                <Badge
                  key={parcelle.id}
                  variant="success"
                  className="mr-2 mt-2"
                >
                  {parcelle.label}
                </Badge>
              ))}
            </div>
            <Button
              variant={"warning"}
              onClick={() => setShowMap(!showMap)}
              className="mt-3"
            >
              {showMap ? "Masquer la carte" : "Afficher la carte"}
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Row>
      <Row noGutters>
        <Col className={!showMap ? "d-none" : undefined}>
          <MapDraw
            ref={map}
            isClickable={isEditable}
            isLegende
            isGeolocation
            dragging={true}
            scrollWheelZoom={true}
            doubleClickZoom={false}
          />
        </Col>
        <Col md={showMap ? 6 : 12}>
          <ButtonToolbar className="d-flex flex-column">
            <ToggleButtonGroup
              type="radio"
              name="vueParcelles"
              defaultValue={vueParcelles}
              onChange={handleRadioChange}
            >
              <ToggleButton
                value={1}
                variant={vueParcelles === 1 ? "secondary" : "light"}
              >
                Parcelles
              </ToggleButton>
              <ToggleButton
                value={2}
                variant={vueParcelles === 2 ? "secondary" : "light"}
              >
                Groupes
              </ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
          {vueParcelles === 1 && (
            <Filters
              pathName="createIntervention"
              listName="selectionParcelles"
              list={parcelles}
            >
              <Parcelle
                isEditable={isEditable}
                isSurfaceEditable={isEditable}
              />
            </Filters>
          )}
          {vueParcelles === 2 && <Groupes isEditable={isEditable} />}
        </Col>
      </Row>
    </>
  );
};

export default Parcelles;
