import React, { useContext, useState } from "react";
// global context
import { DataContext } from "store/DataProvider";
// router
import { withRouter } from "react-router-dom";
// react-bootstrap components
import { Button, Modal } from "react-bootstrap";

const Titre = ({ data, history }) => {
  // app context
  const dataContext = useContext(DataContext);
  // modal
  const [isOpen, setOpen] = useState(false);
  // requete N_1
  const [N_1, setN_1] = useState();

  const handleClick = (bouton) => {
    if (bouton.url) {
      history.push(bouton.url);
    } else if (bouton.onClick) {
      if (
        bouton.onClick === "getMaterielN_1" ||
        bouton.onClick === "getMainOeuvreN_1"
      ) {
        setN_1(bouton.onClick);
        setOpen(true);
      }
    }
  };

  const getN_1 = async () => {
    const ecran = N_1 === "getMaterielN_1" ? "materiel" : "mainOeuvre";
    const isSuccess = await dataContext.sendRequest(N_1);
    if (isSuccess) {
      history.replace(`/parametrable?requete=ecran&ecran=${ecran}`);
    } else {
      dataContext.showNotification({
        type: "danger",
        title: "Impossible de récupérer les éléments N-1.",
      });
    }
  };

  return (
    <div className="pt-3 pl-3 pr-3">
      <div className="d-flex justify-content-between">
        <h3>{data.titre}</h3>
        <div className="d-flex justify-content-end">
          {data.boutons &&
            data.boutons.map((bouton, i) => (
              <Button
                key={i}
                variant="warning"
                onClick={() => handleClick(bouton)}
                className="ml-3"
              >
                {bouton.label}
              </Button>
            ))}
        </div>
      </div>
      <Modal centered show={isOpen} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir récupérer les éléments N-1 ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setOpen(false);
              getN_1();
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withRouter(Titre);
