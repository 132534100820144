export const couleursCultures = [
  {
    label: "Céréales à paille",
    couleur: "#FFC107",
    culturesIds: [101, 102, 105, 110, 118, 151, 160, 185, 301]
  },
  {
    label: "Céréales d'été",
    // couleur: "#4CAF50",
    couleur: "#009688",
    culturesIds: [104, 108, 139, 175, 196]
  },
  {
    label: "Oléagineux",
    // couleur: "#5D4037",
    couleur: "#FF5722",
    culturesIds: [103, 107, 109, 126, 134, 165, 193, 198, 305]
  },
  {
    label: "Protéagineux",
    couleur: "#2196F3",
    culturesIds: [106, 112, 132, 133, 135, 136, 159, 169, 218, 232]
  },
  {
    label: "Prairies",
    couleur: "#673AB7",
    culturesIds: [127, 129, 146, 148, 171, 248, 253]
  },
  {
    label: "Surfaces PAC",
    // couleur: "#FFFFFF",
    couleur: "#757575",
    culturesIds: [
      131,
      219,
      220,
      221,
      237,
      240,
      241,
      242,
      243,
      244,
      245,
      246,
      249,
      250,
      251,
      252,
      256,
      300
    ]
  },
  {
    label: "Autres cultures",
    // couleur: "#9E9E9E",
    couleur: "#B0BEC5",
    culturesIds: [
      116,
      117,
      120,
      124,
      125,
      128,
      138,
      140,
      141,
      142,
      143,
      145,
      147,
      149,
      150,
      161,
      166,
      167,
      170,
      176,
      177,
      178,
      179,
      180,
      181,
      182,
      184,
      186,
      187,
      188,
      189,
      190,
      191,
      192,
      194,
      195,
      197,
      199,
      200,
      201,
      202,
      203,
      204,
      205,
      206,
      207,
      209,
      210,
      211,
      212,
      213,
      214,
      215,
      216,
      217,
      233,
      234,
      235,
      238,
      247,
      254,
      260,
      306
    ]
  }
];
