import React, { useContext, useRef, useEffect, useState } from "react";
// global context
import { DataContext } from "store/DataProvider";
// parcelles actives context
import { ParcellesContext } from "parcelles/provider";
// custom components
import MapDraw from "map/mapDraw";
// import Filters from "filters/component2";
// import Parcelle from "parcellaire/parcelle.js";
// import Groupes from "parcellaire/groupes.js";
// react-bootstrap components
import {
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  Badge,
  Modal,
} from "react-bootstrap";
// react-icons
import { MdRemoveRedEye } from "react-icons/md";

const OutilsDessin = ({ history }) => {
  // données du contexte
  const { sendRequest, sync, showNotification } = useContext(DataContext);
  const {
    // parcelles,
    parcellesActives,
    setParcellesActives,
    getSurfaceTotale,
  } = useContext(ParcellesContext);
  // données de l'historique (parcelle sélectionnée par l'utilisateur)
  let parcelle;
  if (history.location.state) {
    parcelle = history.location.state.parcelle;
  }

  // au montage du composant
  useEffect(() => {
    if (parcelle) {
      // mettre la parcelle en surbrillance
      setParcellesActives([parcelle.properties]);
    }
  }, [parcelle, setParcellesActives]);

  // ligne de découpe
  const [lineCoordinates, setLineCoordinates] = useState([]);
  const setGeometry = (data) => {
    setLineCoordinates(data.geometry.coordinates);
  };

  // preview fusion / découpe
  const [parcellesPreview, setParcellesPreview] = useState([]);
  const getPreview = async () => {
    if (isValid()) {
      const data = {
        parcelles: parcellesActives,
        decoupe: lineCoordinates,
      };
      const response = await sendRequest("fusionDecoupePreview", { data });
      if (response && response.success && response.parcelles) {
        // TODO : supprimer ceci (utilisé pour la démo)
        // const parcellesIds = parcellesActives.map((p) => p.id);
        // let parcellesPreview = parcelles.filter((p) =>
        //   parcellesIds.includes(p.properties.id)
        // );
        setParcellesPreview(response.parcelles);
        showModal();
      } else if (response && !response.success && response.message) {
        showNotification({
          type: "warning",
          title: response.message,
        });
      } else {
        showNotification({
          type: "danger",
          title: "Le serveur n'a pas pu traiter votre demande",
        });
      }
    }
  };

  // modal
  const [show, setShow] = useState(false);
  const closeModal = () => setShow(false);
  const showModal = () => {
    setShow(true);
  };

  // map
  const previewMap = useRef();
  useEffect(() => {
    if (show) {
      // forcer l'affichage de la map (recalcul des bounds)
      previewMap.current && previewMap.current.updateUI();
    }
  }, [show]);

  const isValid = () => {
    const isDecoupe = lineCoordinates.length > 0;
    // fusion valide
    if (parcellesActives.length > 1 && !isDecoupe) {
      return true;
      // découpe valide
    } else if (parcellesActives.length === 1 && isDecoupe) {
      return true;
    } else {
      let message = "";
      // aucune parcelle
      if (!parcellesActives.length) {
        message = "Vous n'avez sélectionné aucune parcelle";
        // fusion avec moins d'une parcelle
      } else if (parcellesActives.length <= 1 && !isDecoupe) {
        message = "Il faut sélectionner 2 parcelles pour pouvoir les fusionner";
        // découpe avec plusieurs parcelles sélectionnées
      } else if (parcellesActives.length > 1 && isDecoupe) {
        message =
          "Impossible de fusionner et découper en même temps (il faut fusionner d'abord, puis découper ensuite)";
      } else {
        message = "Erreur inattendue";
      }
      showNotification({
        type: "warning",
        title: message,
      });
      return false;
    }
  };

  const getPreviewSurface = () => {
    return parcellesPreview.reduce(
      (total, parcelle) => parcelle.properties.surface + total,
      0
    );
  };

  const save = async () => {
    const data = {
      parcelles: parcellesActives,
      decoupe: lineCoordinates,
    };
    const response = await sendRequest("fusionDecoupe", { data });
    if (response.success) {
      await sync(false);
      history.replace("/parcellaire");
    } else {
      showNotification({
        type: "danger",
        title: "Le serveur n'a pas pu traiter votre demande",
      });
    }
  };

  return (
    <>
      <Container fluid>
        <Row noGutters>
          <ListGroup className="w-100 mt-3">
            <ListGroup.Item variant="success" style={{ minHeight: "80px" }}>
              <div className="d-flex justify-content-between">
                <h6>{parcellesActives.length} parcelle(s)</h6>
                <h6>Surface totale : {getSurfaceTotale()} ha</h6>
              </div>
              <div className="d-flex flex-wrap">
                {parcellesActives.map((parcelle) => (
                  <Badge
                    key={parcelle.id}
                    variant="success"
                    className="mr-2 mt-2"
                  >
                    {parcelle.label}
                  </Badge>
                ))}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Row>
        <Row noGutters>
          {/* <Col md={6}> */}
          <Col>
            <MapDraw
              /* ref={mapDraw} */
              isClickable
              draws={["polyline"]}
              parcelle={parcelle ? [parcelle] : undefined}
              bounds={parcelle ? [parcelle] : undefined}
              culture={parcelle ? parcelle.properties.culture : undefined}
              setGeometry={setGeometry}
              reduceHeight
            />
          </Col>
          {/* <Col md={6}>
            <Filters
              pathName="createIntervention"
              listName="selectionParcelles"
              list={parcelles}
            >
              <Parcelle isEditable />
            </Filters>
          </Col> */}
        </Row>
        <Row noGutters>
          <div className="mt-3">
            <h6>
              <strong>- Fusionner 2 parcelles :</strong> sélectionnez 2
              parcelles contiguës, puis prévisualisez le résultat
            </h6>
          </div>
        </Row>
        <Row noGutters>
          <h6>
            <strong>- Découper une parcelle :</strong> sélectionnez 1 seule
            parcelle, tracez 1 seul trait, prévisualisez le résultat
          </h6>
        </Row>
        <Row noGutters>
          <Col className="d-flex justify-content-end">
            <Button variant="warning" onClick={getPreview} className="mt-3">
              {/* {`Prévisualiser la ${buttonTitle}`} */}
              Prévisualiser le résultat
              <MdRemoveRedEye size={25} className="ml-2" />
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal size="lg" show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Prévisualisation du résultat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup className="w-100">
            <ListGroup.Item variant="success" style={{ minHeight: "80px" }}>
              <div className="d-flex justify-content-end">
                <h6>{`Surface totale : ${getPreviewSurface()} ha`}</h6>
              </div>
              <div className="d-flex flex-wrap">
                {parcellesPreview.map((parcelle, i) => (
                  <Badge key={i} variant="success" className="mr-2 mt-2">
                    {`${parcelle.properties.label} - ${parcelle.properties.surface} ha`}
                  </Badge>
                ))}
              </div>
            </ListGroup.Item>
          </ListGroup>
          <MapDraw
            ref={previewMap}
            parcelles={parcellesPreview}
            bounds={parcellesPreview}
            dragging={true}
            scrollWheelZoom={true}
            doubleClickZoom={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Annuler
          </Button>
          <Button variant="warning" onClick={save}>
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OutilsDessin;
