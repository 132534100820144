import React from "react";
// react-bootstrap components
import { Row, Col, Form, InputGroup } from "react-bootstrap";
// react-select component
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { colourStyles } from "styles/react-select";

const Input = ({
  input,
  value,
  options,
  handleChange,
  handleReactSelectChange,
  loadOptions,
  gridSize,
}) => {
  if (!options) {
    options = [];
  }
  if (!gridSize) {
    gridSize = { label: "2", input: "10" };
  }
  let Component;
  if (input.type === "text") {
    Component = InputText;
  } else if (input.type === "number") {
    Component = InputNumber;
  } else if (input.type === "select") {
    Component = InputSelect;
  } else if (input.type === "react-select") {
    Component = InputReactSelect;
  } else if (input.type === "react-select-autocomplete") {
    Component = InputReactSelectAutocomplete;
  } else if (input.type === "radio") {
    Component = InputRadio;
  } else if (input.type === "date") {
    Component = InputDate;
  }
  return (
    <Form.Group as={Row}>
      <Form.Label column sm={gridSize.label}>
        {input.label}
      </Form.Label>
      <Col sm={gridSize.input}>
        <Component
          input={input}
          value={value}
          options={options}
          handleChange={handleChange}
          handleReactSelectChange={handleReactSelectChange}
          loadOptions={loadOptions}
        />
      </Col>
    </Form.Group>
  );
};

export default Input;

const InputText = ({ input, value, handleChange }) => {
  return (
    <Form.Control
      name={input.name}
      type="text"
      placeholder={input.label}
      value={value}
      onChange={handleChange}
    />
  );
};

const InputNumber = ({ input, value, handleChange }) => {
  return (
    <InputGroup>
      <Form.Control
        name={input.name}
        type="number"
        placeholder={input.label}
        step={input.step}
        decimals={getDecimals(input.step)}
        value={value}
        onChange={handleChange}
      />
      {input.unit && (
        <InputGroup.Append>
          <InputGroup.Text>{input.unit}</InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>
  );
};

const getDecimals = (step) => {
  let decimals = 0;
  if (step === "0,1") {
    decimals = 1;
  } else if (step === "0,01") {
    decimals = 2;
  }
  return decimals;
};

const InputSelect = ({ input, value, options, handleChange }) => {
  if (value && value[0]) {
    value = value[0].id;
  } else {
    value = "";
  }
  return (
    <Form.Control
      name={input.name}
      as="select"
      value={value}
      onChange={handleChange}
    >
      {/* <option value="">Aucun(e)</option> */}
      {options.map((option) => {
        let telepacProps = {};
        if (input.options === "telepac") {
          telepacProps.ilot = option.ilot.label;
          telepacProps.parcelle = option.parcelle.label;
          telepacProps.origine = option.origine;
        }
        return (
          <option key={option.id} value={option.id} {...telepacProps}>
            {option.label}
          </option>
        );
      })}
    </Form.Control>
  );
};

const InputReactSelect = ({
  input,
  value,
  options,
  handleReactSelectChange,
}) => {
  return (
    <Select
      isMulti={input.isMulti}
      isDisabled={input.isDisabled}
      placeholder={input.label}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      onChange={handleReactSelectChange.bind(this, input.name)}
      styles={input.colors ? colourStyles : undefined}
    />
  );
};

const InputReactSelectAutocomplete = ({
  input,
  value,
  options,
  handleReactSelectChange,
  loadOptions,
}) => {
  return (
    <AsyncSelect
      isDisabled={input.isDisabled}
      placeholder={input.label}
      getOptionValue={(option) => option.id}
      cacheOptions
      defaultOptions={options}
      loadOptions={loadOptions.bind(this, input.name)}
      value={value}
      onChange={handleReactSelectChange.bind(this, input.name)}
      styles={input.colors ? colourStyles : undefined}
    />
  );
};

const InputRadio = ({ input, value, options, handleChange }) => {
  return options.map((option) => (
    <Form.Check
      key={option.id}
      type="radio"
      name={input.name}
      label={option.label}
      value={option.id}
      checked={value === option.id}
      onChange={handleChange}
    />
  ));
};

const InputDate = ({ input, value, handleChange }) => {
  return (
    <Form.Control
      name={input.name}
      type="date"
      placeholder="jj/mm/aaaa"
      value={value}
      onChange={handleChange}
    />
  );
};
