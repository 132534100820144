import React from "react";
// react-bootstrap components
import { Spinner } from "react-bootstrap";

const LoadingIndicator = ({ isLoading }) => {
  return isLoading ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.7,
        backgroundColor: "black",
        zIndex: 1001,
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner animation="border" variant="warning" />
      <h4 className="text-light ml-3">Chargement en cours ...</h4>
    </div>
  ) : null;
};

export default LoadingIndicator;
