import React from "react";
// react-bootstrap components
import {
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Badge,
} from "react-bootstrap";
// time functions
import { getTimeString } from "./utils.js";
// icons
import { MdCheckCircle, MdCancel } from "react-icons/md";

export const FormDoubleInputs = (props) => {
  const form = props.selectedOptions.map((option, index) => (
    <div key={option.id || option.value} className="d-flex flex-column mb-2">
      <div className="d-flex">
        <div className="w-100">
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>{option.label}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              data-id={option.id || option.value}
              data-label={option.label}
              data-unit={option.unit}
              name="quantite"
              type="number"
              placeholder="Quantité totale"
              step={props.step ? props.step : 1}
              min={0}
              value={option.quantite}
              onChange={props.updateItem}
              onBlur={props.testConformitePhyto}
              disabled={!props.isEditable}
            />
            <InputGroup.Append>
              <InputGroup.Text>{option.unit}</InputGroup.Text>
            </InputGroup.Append>
            <FormControl
              data-id={option.id || option.value}
              data-label={option.label}
              data-unit={option.unit}
              name="quantitePerSurface"
              type="number"
              placeholder="Dose"
              step={props.step ? props.step : 1}
              min={0}
              value={props.getQuantityPerSurface(option.quantite)}
              onChange={props.updateItem}
              onBlur={props.testConformitePhyto}
              disabled={!props.isEditable}
            />
            <InputGroup.Append>
              <InputGroup.Text>{option.unit}/ha</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
        {option.statut && (
          <div className="flex-shrink-1 ml-2">
            {option.statut === 1 && <MdCheckCircle size={32} color="green" />}
            {option.statut === 2 && <MdCancel size={32} color="red" />}
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between mt-2">
        {option.lienEphy && (
          <div>
            <a
              href={`https://ephy.anses.fr/ppp/${option.lienEphy}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`Lien vers la page produit Ephy ${option.label}`}</a>
          </div>
        )}
        {option.messages && (
          <div className="d-flex flex-row">
            {option.messages.map((message, index) => (
              <h6 key={index} className="mb-0 mr-2">
                <Badge pill variant="danger">
                  {message}
                </Badge>
              </h6>
            ))}
          </div>
        )}
      </div>
      {props.isMultiExploitations && (
        <Row className="mt-2">
          <Col>
            <Form.Label as="legend" className="mr-2">
              Origine du stock
            </Form.Label>
            <Form.Check
              inline
              data-id={option.id || option.value}
              type="radio"
              label="origine applicateur"
              name={"origineStock-" + option.id}
              value={1}
              checked={option.origineStock === 1}
              onChange={props.updateItem}
              disabled={!props.isEditable}
            />
            <Form.Check
              inline
              data-id={option.id || option.value}
              type="radio"
              label="parcelle d’origine"
              name={"origineStock-" + option.id}
              value={2}
              checked={option.origineStock === 2}
              onChange={props.updateItem}
              disabled={!props.isEditable}
            />
          </Col>
        </Row>
      )}
    </div>
  ));
  return form;
};

export const FormSemis = (props) => {
  const form = props.selectedOptions.map((option, index) => (
    <div key={option.id || option.value}>
      <InputGroup size="sm" className="mb-2">
        <InputGroup.Prepend>
          <InputGroup.Text>{option.label}</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          data-id={option.id || option.value}
          data-dose-graines={option.nbGrainesParDose}
          name="quantite"
          type="number"
          step={props.step ? props.step : 1}
          min={0}
          value={option.quantite}
          onChange={props.updateItem}
          disabled={!props.isEditable}
        />
        <InputGroup.Append>
          <InputGroup.Text>{option.unit}</InputGroup.Text>
        </InputGroup.Append>
        {option.hasOwnProperty("quantitePerSurface") && (
          <>
            <FormControl
              data-id={option.id || option.value}
              data-dose-graines={option.nbGrainesParDose}
              name="quantitePerSurface"
              type="number"
              step={props.step ? props.step : 1}
              min={0}
              value={props.getQuantityPerSurface(option.quantite)}
              onChange={props.updateItem}
              disabled={!props.isEditable}
            />
            <InputGroup.Append>
              <InputGroup.Text>{option.unit}/ha</InputGroup.Text>
            </InputGroup.Append>
          </>
        )}
      </InputGroup>
      {option.hasOwnProperty("dose") && (
        <InputGroup size="sm" className="mb-2">
          <FormControl
            data-id={option.id || option.value}
            data-dose-graines={option.nbGrainesParDose}
            name="quantiteGrainesPerSurface"
            type="number"
            step={1000}
            min={0}
            value={props.getQuantityGrainesPerSurface(option)}
            onChange={props.updateItem}
            disabled={!props.isEditable}
          />
          <InputGroup.Append>
            <InputGroup.Text>Grains/ha</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      )}
      {option.hasOwnProperty("variete") && !option.hasOwnProperty("dose") && (
        <InputGroup size="sm" className="mb-2">
          <FormControl
            data-id={option.id || option.value}
            name="pmg"
            type="number"
            min={0}
            value={option.pmg}
            onChange={props.updateItem}
            disabled={!props.isEditable}
          />
          <InputGroup.Append>
            <InputGroup.Text>PMG</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      )}
      {option.hasOwnProperty("variete") && (
        <Row className="mb-2">
          <Col>
            <Form.Label as="legend" className="mr-2">
              Type de semence
            </Form.Label>
            <Form.Check
              inline
              data-id={option.id || option.value}
              type="radio"
              label="certifiée"
              name={"typeSemence-" + option.id}
              value={1}
              checked={option.typeSemence === 1}
              onChange={props.updateItem}
              disabled={!props.isEditable}
            />
            <Form.Check
              inline
              data-id={option.id || option.value}
              type="radio"
              label="fermière"
              name={"typeSemence-" + option.id}
              value={2}
              checked={option.typeSemence === 2}
              onChange={props.updateItem}
              disabled={!props.isEditable}
            />
          </Col>
        </Row>
      )}
      {props.isMultiExploitations && (
        <Row className="mb-2">
          <Col>
            <Form.Label as="legend" className="mr-2">
              Origine du stock
            </Form.Label>
            <Form.Check
              inline
              data-id={option.id || option.value}
              type="radio"
              label="origine applicateur"
              name={"origineStock-" + option.id}
              value={1}
              checked={option.origineStock === 1}
              onChange={props.updateItem}
              disabled={!props.isEditable}
            />
            <Form.Check
              inline
              data-id={option.id || option.value}
              type="radio"
              label="parcelle d’origine"
              name={"origineStock-" + option.id}
              value={2}
              checked={option.origineStock === 2}
              onChange={props.updateItem}
              disabled={!props.isEditable}
            />
          </Col>
        </Row>
      )}
    </div>
  ));
  return form;
};

export const FormDoubleTimePickers = (props) => {
  const form = props.selectedOptions.map((option, index) => (
    <InputGroup key={option.id || option.value} size="sm" className="mb-2">
      <InputGroup.Prepend>
        <InputGroup.Text>{option.label}</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        data-id={option.id || option.value}
        data-label={option.label}
        data-unit={option.unit}
        name="quantite"
        type="time"
        step={900}
        value={getTimeString(option.quantite)}
        onChange={props.updateItem}
        disabled={!props.isEditable}
      />
      <InputGroup.Append>
        <InputGroup.Text>{option.unit}</InputGroup.Text>
      </InputGroup.Append>
      <Form.Control
        data-id={option.id || option.value}
        data-label={option.label}
        data-unit={option.unit}
        name="quantitePerSurface"
        type="time"
        step={900}
        value={props.getQuantityPerSurface(option.quantite)}
        onChange={props.updateItem}
        disabled={!props.isEditable}
      />
      <InputGroup.Append>
        <InputGroup.Text>{option.unit}/ha</InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  ));
  return form;
};
