import React from "react";
// toast notifications
import Toolbar from "toolbar/component";
// momentjs
import "moment/locale/fr";
import moment from "moment";

class ToolbarManager extends React.Component {
  constructor() {
    super();
    this.state = {
      showToolBar: false,
      syncStatus: "completed",
      queuedRequests: 0,
      date: moment(),
    };
    this.showToolbar = this.showToolbar.bind(this);
    this.hideToolbar = this.hideToolbar.bind(this);
  }

  showToolbar() {
    this.setState({ showToolBar: true, syncStatus: "in progress" });
    // cacher la toolbar au bout de 4 secondes
    setTimeout(() => {
      this.hideToolbar();
    }, 4000);
  }

  hideToolbar() {
    this.setState({ showToolBar: false });
  }

  set(data) {
    this.setState(data);
  }

  render() {
    return <Toolbar {...this.state} />;
  }
}

export default ToolbarManager;
