import React from "react";
// react-bootstrap components
import {
  Row,
  Col,
  InputGroup,
  Form,
  FormControl,
  Card,
} from "react-bootstrap/";
// react-select component
import Select from "react-select";

const inputs = [
  {
    placeholder: "Stock actuel",
    name: "stock",
    type: "number",
    unit: true,
    disabled: true,
  },
  {
    placeholder: "Quantité",
    name: "quantite",
    type: "number",
    unit: true,
  },
  {
    placeholder: "Prix unitaire",
    name: "prixUnitaire",
    type: "number",
    step: "0,01",
    unit: true,
    price: true,
  },
  {
    placeholder: "Numéro de lot",
    name: "numeroLot",
    type: "text",
  },
  {
    placeholder: "Traitement de semence",
    name: "traitementSemence",
    type: "react-select",
  },
  {
    placeholder: "Type de semence",
    name: "typeSemence",
    type: "radio",
    options: [
      { id: 1, label: "certifiée" },
      { id: 2, label: "fermière" },
    ],
  },
];

export const FormMouvementStock = (props) => {
  const form = props.selectedOptions.map((option, i1) => (
    <Card key={i1} text="dark" className="mb-3">
      <Card.Header as="h5">{option.label}</Card.Header>
      <Card.Body>
        {inputs.map((input, i2) => {
          let value = option[input.name];
          // si stock sans valeur
          if (input.name === "stock" && !value) {
            // valeur par défaut = 0
            value = 0;
          } else if (!value) {
            // valeur par défaut = ""
            value = "";
          }
          if (input.type === "number" || input.type === "text") {
            return (
              <InputGroup key={i2} size="sm" className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>{input.placeholder}</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  data-id={option.id || option.value}
                  data-label={option.label}
                  data-unit={option.unit}
                  name={input.name}
                  type={input.type}
                  step={input.step ? input.step : 1}
                  min={0}
                  value={value}
                  onChange={props.updateItem}
                  disabled={input.disabled}
                />
                {input.unit && (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      {input.price ? `€/${option.unit}` : option.unit}
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            );
          } else if (input.type === "react-select" && option.variete) {
            return (
              <Select
                key={i2}
                isMulti={false}
                placeholder={input.placeholder}
                getOptionValue={(option) => option.id}
                options={props.produitsSemence.options || []}
                value={value || []}
                onChange={props.updateReactSelectItem.bind(this, option.id)}
              />
            );
          } else if (
            input.type === "radio" &&
            option.variete &&
            option.unit === "Kg"
          ) {
            return (
              <Row key={i2} className="mb-2">
                <Col>
                  <Form.Label as="legend" className="mr-2">
                    {input.placeholder}
                  </Form.Label>
                  {input.options.map((o) => (
                    <Form.Check
                      key={o.id}
                      inline
                      data-id={option.id || option.value}
                      type="radio"
                      label={o.label}
                      name={input.name + option.id}
                      value={o.id}
                      checked={value === o.id}
                      onChange={props.updateItem}
                    />
                  ))}
                </Col>
              </Row>
            );
          }
          return null;
        })}
      </Card.Body>
    </Card>
  ));
  return form;
};
