import React from "react";
// router
import { withRouter } from "react-router-dom";
// contexte parcelles (this.props.ctx)
import { WithParcellaire } from "../parcellaire/provider";
// custom components
import Filters from "../filters/component2";
import Ilot from "../ilots/ilot";
// react-bootstrap components
import { Container, Row, Col, Button, ListGroup, Badge } from "react-bootstrap";
// react-icons
import { MdModeEdit } from "react-icons/md";

class ListeIlots extends React.Component {
  openCreate() {
    this.props.history.push("/createIlot", {});
  }

  openEdit(e) {
    const id = Number(e.currentTarget.getAttribute("data-id"));
    this.props.history.push("/createIlot", {
      id: id,
    });
    if (id < 0) {
      this.props.data.showNotification({
        type: "warning",
        title: `Cet îlot n'est pas encore synchronisé.
          Vous pouvez le visualiser.
          Vous ne pouvez pas le modifier, ni le supprimer tant qu'il n'est pas synchronisé.`,
      });
    }
  }

  getSurfaceTotale() {
    return this.props.ctx.ilots.reduce((total, currentValue) => {
      let surfaceTotale =
        Number(total) + Number(currentValue.properties.surface);
      return surfaceTotale.toFixed(2);
    }, 0);
  }

  render() {
    const ilots = this.props.ctx.ilots;
    return (
      <Container className="mt-3">
        <Row className="mb-3">
          <Col md={8} sm={12}>
            <Button
              onClick={this.openCreate.bind(this)}
              variant="warning"
              className="float-right"
            >
              Créer un îlot
              <MdModeEdit size={25} className="ml-2" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="mb-3">
            <Filters
              pathName="ilots"
              listName="ilots"
              list={ilots}
              label="Îlots"
              saveFilters
            >
              <Ilot
                isTabActive={this.props.isTabActive}
                handleClick={this.openEdit.bind(this)}
              />
            </Filters>
          </Col>
          <Col md={4} className="mb-3">
            <ListGroup.Item>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Surface totale</h4>
                <h6 className="mb-0">
                  <Badge variant="secondary">{this.getSurfaceTotale()}ha</Badge>
                </h6>
              </div>
            </ListGroup.Item>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(WithParcellaire(ListeIlots));
