import React, { useContext, useReducer, useRef } from "react";
// contexte global (this.props.data)
import { DataContext } from "../store/DataProvider";
// parcelles actives context
import { ParcellesContext } from "parcelles/provider";
// composants
import Filters from "../filters/component2";
import Parcelle2 from "../parcellaire/parcelle2";
import MapDraw from "map/mapDraw.js";
// parcellaire datas
import {
  getCulturesParcelles,
  getVarietesParcelles,
} from "parcellaire/utils.js";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
// react-select component
import Select from "react-select";
// react-icons
import { MdEdit } from "react-icons/md";

const Assolement = ({ history, ...props }) => {
  // utiliser un hook context
  const data = useContext(DataContext);
  const { toggle, toggleAllParcelles } = useContext(ParcellesContext);
  // router history state (envoyé par écran précédent --history.push)
  const { id } = history.location.state;
  // récupérer les propriétés de l'assolement choisi précédemment
  const assolement = typesAssolement.find((type) => type.id === id);
  const { typeAssolement, inputType, property, listName, unit } = assolement;
  // state par défaut
  const initialState = {
    parcellesOrigine: data.parcelles,
    parcelles: (data.parcelles || []).map((parcelle) => {
      parcelle.properties.isActive = false;
      return parcelle;
    }),
    value: "",
    property: property,
    cultureId: -1,
  };
  // utiliser un hook reducer
  const [state, dispatch] = useReducer(reducer, initialState);
  const isActive = state.parcelles.some(
    (parcelle) => parcelle.properties.isActive
  );
  const handleClick = (id) => {
    dispatch({
      type: "toggleParcelle",
      id: id,
    });
    // deep copy des parcelles pour ne pas modifier le state existant
    let parcelles = JSON.parse(JSON.stringify(state.parcelles));
    let toggleParcelle = parcelles.find(
      (parcelle) => parcelle.properties.id === id
    );
    // rendre la parcelle active dans le contexte
    toggle(toggleParcelle.properties);
  };
  const toggleAll = () => {
    const parcelles = JSON.parse(JSON.stringify(filter.current.getList()));
    dispatch({
      type: "toggleAllParcelles",
      isActive: isActive,
      parcelles: parcelles,
    });
    // rendre les parcelles actives dans le contexte
    toggleAllParcelles(parcelles, isActive);
  };

  const map = useRef();
  const filter = useRef();

  const getOptions = (typeAssolement) => {
    if (typeAssolement === "cultures") {
      return getCulturesParcelles(
        { produits: data.produits, culturepac: data.culturepac },
        typeAssolement
      );
    } else if (typeAssolement === "variétés") {
      return getVarietesParcelles(data.produits, state.cultureId);
    } else if (typeAssolement === "zones vulnérables") {
      return [
        { id: 1, label: "Oui" },
        { id: 2, label: "Non" },
      ];
    }
    return data[listName];
  };

  const save = async () => {
    const parcelles = state.parcelles
      // ne garder que les parcelles modifiées
      .filter((parcelle) => parcelle.properties.isActive)
      // modifier la structure des données pour envoi serveur
      .map((parcelle) => {
        const value =
          inputType === "select"
            ? parcelle.properties[property][0].id
            : parcelle.properties[property];
        return {
          id: parcelle.properties.id,
          // TODO : utiliser des id plutot que des labels (voir avec Pascal)
          property: { name: property, value: value },
        };
      });
    await data.updateItems("parcelles", state.parcelles, parcelles);
    history.replace("/parametrable?requete=boutons&ecran=assolement");
  };

  const isValueSelected = () => {
    return (
      (Array.isArray(state.value) && state.value.length > 0) || state.value > 0
    );
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <Alert variant="success" className="text-center">
            <Alert.Heading>{`Affecter des ${typeAssolement}`}</Alert.Heading>
          </Alert>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {typeAssolement === "variétés" && (
            <Input
              inputType="select"
              typeAssolement="cultures"
              options={getOptions("cultures")}
              dispatch={dispatch}
              property={property}
            />
          )}
          <Input
            inputType={inputType}
            typeAssolement={typeAssolement}
            value={state.value}
            unit={unit}
            options={getOptions(typeAssolement)}
            dispatch={dispatch}
            property={property}
          />
        </Col>
      </Row>
      {isValueSelected() && (
        <>
          <Row className="mt-3">
            <Col>
              <MapDraw
                ref={map}
                isClickable
                handleClick={handleClick}
                isLegende
                isGeolocation
                dragging={true}
                scrollWheelZoom={true}
                doubleClickZoom={false}
              />
            </Col>
            <Col>
              <Filters
                pathName="assolement"
                listName="parcelles"
                list={state.parcelles}
              >
                <Parcelle2
                  ref={filter}
                  isTabActive
                  isEditable
                  isActive={isActive}
                  toggleAll={toggleAll}
                  handleClick={handleClick}
                />
              </Filters>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col className="d-flex justify-content-end">
              <Button variant="warning" onClick={save}>
                Enregistrer
                <MdEdit size={25} className="ml-2" />
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default Assolement;

const Input = ({
  inputType,
  typeAssolement,
  value,
  options,
  unit,
  dispatch,
  property,
  loadOptions,
}) => {
  return (
    <>
      {inputType === "select" && (
        <Select
          placeholder={typeAssolement}
          getOptionValue={(opt) => opt.id}
          options={options}
          value={value}
          onChange={(option) =>
            dispatch({
              type:
                property === "varietes" && typeAssolement === "cultures"
                  ? "setCultureId"
                  : "setSelectOptions",
              option: option,
            })
          }
          className="mb-2"
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      )}
      {inputType === "number" && (
        <InputGroup>
          <Form.Control
            type="number"
            step="0.01"
            value={value}
            onChange={(e) =>
              dispatch({
                type: "setFloatValue",
                value: e.target.value,
              })
            }
          />
          <InputGroup.Append>
            <InputGroup.Text>{unit}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      )}
    </>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setCultureId": {
      return {
        ...state,
        // id option sélectionnée
        cultureId: action.option.id,
        // reset champ variétés
        value: [],
      };
    }
    case "setSelectOptions": {
      return {
        ...state,
        // option sélectionnée
        value: [action.option],
      };
    }
    case "setFloatValue": {
      let value = action.value;
      // retirer le 0 qui précéde la saisie
      if (value !== "") {
        // float avec 2 décimales
        value = +Number(value).toFixed(2);
      }
      return {
        ...state,
        value: value,
      };
    }
    case "toggleParcelle": {
      const isValue =
        (Array.isArray(state.value) && state.value.length > 0) ||
        state.value > 0;
      // si option sélectionnée ou valeur saisie
      if (isValue) {
        // deep copy des parcelles pour ne pas modifier le state existant
        let parcelles = JSON.parse(JSON.stringify(state.parcelles));
        const index = parcelles.findIndex(
          (parcelle) => parcelle.properties.id === action.id
        );
        let toggleParcelle = parcelles[index];

        const isActive = toggleParcelle.properties.isActive;
        // si précédemment sélectionnée
        if (isActive) {
          const initialValue =
            state.parcellesOrigine[index].properties[state.property];
          // remettre valeur initiale
          toggleParcelle.properties[state.property] = initialValue;
        } else {
          // affecter nouvelle valeur
          toggleParcelle.properties[state.property] = state.value;
        }
        // inverser prop isActive
        toggleParcelle.properties.isActive = !isActive;
        // remplacer la parcelle dans la liste
        parcelles[index] = toggleParcelle;
        return {
          ...state,
          parcelles: parcelles,
        };
      } else {
        return state;
      }
    }
    case "toggleAllParcelles": {
      action.parcelles.forEach((p, i) => {
        if (action.isActive) {
          const initialValue = state.parcellesOrigine.find(
            (parcelle) => parcelle.properties.id === p.properties.id
          ).properties[state.property];
          // remettre valeur initiale
          p.properties[state.property] = initialValue;
          p.properties.isActive = false;
        } else {
          // affecter nouvelle valeur
          p.properties[state.property] = state.value;
          p.properties.isActive = true;
        }
      });
      return {
        ...state,
        parcelles: action.parcelles,
      };
    }
    default: {
      return state;
    }
  }
};

const typesAssolement = [
  {
    id: 1,
    typeAssolement: "cultures",
    inputType: "select",
    property: "culture",
    listName: "cultures",
    icon: "GiCorn",
  },
  {
    id: 2,
    typeAssolement: "variétés",
    inputType: "select",
    property: "varietes",
    listName: "varietes",
    icon: "GiFertilizerBag",
  },
  {
    id: 3,
    typeAssolement: "types de culture",
    inputType: "select",
    property: "typeCulture",
    listName: "typeCulture",
    icon: "GiCalendar",
  },
  {
    id: 4,
    typeAssolement: "destinations",
    inputType: "select",
    property: "destination",
    listName: "destination",
    icon: "GiRoundSilo",
  },
  {
    id: 5,
    typeAssolement: "rendements",
    inputType: "number",
    unit: "T/ha",
    property: "rendement",
    icon: "../../assets/moissonneuse.svg",
  },
  {
    id: 6,
    typeAssolement: "prix de vente",
    inputType: "number",
    unit: "€/T",
    property: "prixDeVente",
    icon: "GiMoneyStack",
  },
  {
    id: 7,
    typeAssolement: "types de sol",
    inputType: "select",
    property: "typeDeSol",
    listName: "typeDeSol",
    icon: "GiHills",
  },
  {
    id: 8,
    typeAssolement: "zones vulnérables",
    inputType: "select",
    property: "zoneVulnerable",
    icon: "GiRiver",
  },
];
