import React from "react";
import { Badge } from "react-bootstrap";
import { getVarieteLabel } from "utils/parcelle";

const ModalParcelleTemplate = ({ parcelle }) => {
  const typeCulture = parcelle.typeCulture[0] || {};
  const culture = parcelle.culture[0] || {};
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row flex-wrap">
          <h6 className="mr-1">{`${parcelle.label} - ${parcelle.surface}ha`}</h6>
        </div>
        <div className="d-flex flex-row flex-wrap">
          <h6 className="mb-0 mr-1">
            <Badge variant="warning">{`${culture.label ? culture.label : ""} ${
              typeCulture.label ? `(${typeCulture.label})` : ""
            }`}</Badge>
          </h6>
          {parcelle.varietes.length > 0 && (
            <h6 className="mb-0 mr-1">
              <Badge pill variant="info">
                {getVarieteLabel(parcelle.varietes)}
              </Badge>
            </h6>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalParcelleTemplate;
