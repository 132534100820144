import React from "react";
import Alert from "react-bootstrap/Alert";
// import Button from "react-bootstrap/Button";

class AlertBig extends React.Component {
  render() {
    return (
      <Alert dismissible={this.props.dismissible} variant={this.props.variant}>
        <Alert.Heading>{this.props.title}</Alert.Heading>
        <p>{this.props.message}</p>
      </Alert>
    );
  }
}

export default AlertBig;
