export default class buttonRenderer {
  // gets called once before the renderer is used
  init(params) {
    // create the cell
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = `
            <button class="btn-simple reporter">Reporter</button>
            <button class="btn-simple solder">Solder</button>
         `;

    // get references to the elements we want
    this.reporterButton = this.eGui.querySelector(".btn-simple.reporter");
    this.solderButton = this.eGui.querySelector(".btn-simple.solder");

    // add event listener to buttons
    this.reporterButtoneventListener = () => {
      const quantite = params.data.quantite;
      const stockActuel = params.data.stockActuel;
      params.node.setDataValue("stockActuel", Number(stockActuel - quantite));
      // params.node.setDataValue("stockFutur", Number(quantite));
      params.node.setDataValue("quantite", Number(quantite));
      params.node.setDataValue("action", "reporter");
      this.eGui.innerHTML = "";
    };
    this.solderButtoneventListener = () => {
      const quantite = params.data.quantite;
      const stockActuel = params.data.stockActuel;
      params.node.setDataValue("stockActuel", Number(stockActuel - quantite));
      // params.node.setDataValue("quantite", Number(quantite));
      params.node.setDataValue("quantite", 0);
      params.node.setDataValue("action", "solder");
      this.eGui.innerHTML = "";
    };
    this.reporterButton.addEventListener(
      "click",
      this.reporterButtoneventListener
    );
    this.solderButton.addEventListener("click", this.solderButtoneventListener);
  }

  getGui() {
    return this.eGui;
  }

  // gets called whenever the cell refreshes
  refresh(params) {
    // return true to tell the grid we refreshed successfully
    return true;
  }
}
